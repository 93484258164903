import React, { useState, useContext, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { AdminContext } from '../../contexts/AdminContextContainer';
import moment from 'moment';

const TimeFilters = (startDate, endDate) => {
  const [newStartDate, setNewStartDate] = useState(moment(startDate["startDate"]).toDate());
  const [newEndDate, setNewEndDate] = useState(moment(endDate["endDate"]).toDate());

  const { handleTimeFilter, handleSetEndDate } = useContext(AdminContext);

  const handleStartDateChange = (date) => {
    setNewStartDate(date);
    handleTimeFilter('custom', {start_date: date, end_date: newEndDate});
  }

  const handleEndDateChange = (date) => {
    setNewEndDate(date);
    handleTimeFilter('custom', {start_date: newStartDate, end_date: date});
  }

  return (
    <ul id="timeFilters" className="nav nav-pills  nav_tb mb-3 time-filters">
      <li className="_nav_link nav-item box_shadow_shdo" >
        <span className="nav-link  nav_lilnk nav_lilnk_ calendar-wrap">
          <span class="title-text">Dates: </span>
          <DatePicker
            dateFormat="MMMM d, yyyy"
            selected={newStartDate}
            isClearable
            onChange={handleStartDateChange}
          />
          <span className="date-separator">-</span>
          <DatePicker
            dateFormat="MMMM d, yyyy"
            selected={newEndDate}
            isClearable
            onChange={handleEndDateChange}
          />
        </span>
      </li>

    </ul>
  )
}

export default TimeFilters;
