import React, { useContext } from 'react';
import { EventContext } from '../../contexts/EventContextContainer';
import { convertToBudgetRange } from '../../utils/helperFunctions';

const FilterTagsV2 = ({
  eventBudgets,
  eventIndustries,
  eventCities
}) => {
  const { eventFilters, setEventFilters } = useContext(EventContext);
  const { event_industry_id, city, estimated_budget } = eventFilters

  const filterName = (filter, id) => {
    switch (filter){
      case "event_industry_id":
        let industry = eventIndustries.find(industry => industry[0][0] === id)
        if (industry)
          return industry[0][1];

      case "city":
        let city = eventCities.find(city => city[0][0] === id)
        if (city)
          return city[0][0] + ", " + (city[0][1] ? city[0][1] : "");

      case "estimated_budget":
        let budget = eventBudgets.find(budget => budget[0] === id)
        if (budget)
          return convertToBudgetRange(budget[0]);
    }
    return null;
  }

  const removeFilter = (id, filter) => {
    let newFilters = {...eventFilters}
    index = newFilters[filter].indexOf(id)
    if (index > -1)
      newFilters[filter].splice(index, 1)

    setEventFilters(newFilters)
  }

  const renderTag = (id, filter) => {
    let name = filterName(filter, id);

    if (name)
      return (
        <p className="no-margin tags_p" key={id + filter}>
          <span className="tag-1 tag_ bggray">{ name }&nbsp;
          <i className="fas fa-times" onClick={() => removeFilter(id, filter)}></i>
          </span>
        </p>
      );
    else
      return null;
  }

  return (
    <div className="filter-tags-wrap">
      { event_industry_id && event_industry_id.map(id => renderTag(id, "event_industry_id"))}
      { city && city.map(id => renderTag(id, "city"))}
      { estimated_budget && estimated_budget.map(id => renderTag(id, "estimated_budget"))}
    </div>
  )
}

export default FilterTagsV2;
