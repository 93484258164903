import React, { useContext, useState } from 'react';
import { convertToBudgetRange } from '../../utils/helperFunctions';
import { EventContext } from '../../contexts/EventContextContainer';
import { Button, Modal } from 'react-bootstrap';
import axios from "axios";

const BudgetFiltersV2 = ({eventBudgets, userRole}) => {
  const [showModal, setShowModal] = useState(false);
  const { eventFilters, handleFilterClick } = useContext(EventContext);

  const isChecked = value =>
    eventFilters != undefined && 
      eventFilters.estimated_budget && eventFilters.estimated_budget.includes(value)

  const toggleActive = () => {
    setShowModal(!showModal)
  }

  const checkHandleFilterClick = (eventBudget, estimated_budget, event) => {
    if (userRole == "user_starter" && eventBudget != "0") {
      setShowModal(true)
    }
    else if (userRole == "user_explorer" && !(eventBudget == "0" || eventBudget == "2500")) {
      setShowModal(true)
    }
    else if (userRole == "user_veteran" && eventBudget == "20000+") {
      setShowModal(true)
    }
    else {
      handleFilterClick(eventBudget, 'estimated_budget', event)
    }
  } 

  const getCheckoutUrl = () => {
    axios.get(`/profiles/subscription_session_js.json`, {
      params: {}
    }).then((response) => {
      location.href = response.data;
    })
  }
    
  return (
    <>
      <div id="budget_filter" className="ml-4">
        {
          eventBudgets.map((eventBudget, index) => (
            <label className="lbl_" key={index}>
              <span>
                <input
                  value={eventBudget[0]}
                  id={eventBudget[0]}
                  type="checkbox"
                  checked={isChecked(eventBudget[0])}
                  onClick={(event) => checkHandleFilterClick(eventBudget[0], 'estimated_budget', event)}
                />
                <span className="spp">
                  {convertToBudgetRange(eventBudget[0])}
                </span>
              </span>
              { eventBudget[1] }
            </label>
          ))
        }
      </div>
      {
        (showModal) && 
        <>
            <Modal dialogClassName="mobile-filter-modal" show={showModal} onHide={toggleActive} backdrop="static">
            <Modal.Header className="text-center d-block border-bottom-0" closeButton/>
            <Modal.Body className="d-flex mr-3 ml-3" style={{flexWrap: 'wrap'}}>
                <div className="text-center w-100 font-size-l mb-4 font-weight-bold">
                  Upgrade Your Account
                </div>
                <div className="budget-filter-modal-body font-size-m" >
                    In order to view these events, you need to upgrade your account by <span className="font-weight-bold">editing your subscription.</span> 
                </div>
                <div style={{color:'white'}} className="mt-4 d-flex justify-content-center w-100 mb-4">
                  <a className="green-button" onClick={getCheckoutUrl}>Edit Subscription</a>
                </div>                 
            </Modal.Body>
            </Modal>
        </>
      }
    </>
  )
}

export default BudgetFiltersV2;
