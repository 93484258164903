import React, { useContext } from 'react';
import { EventContext } from '../../contexts/EventContextContainer';
import InfiniteCircle from '../../../../assets/images/blue_infinite_circle.png'
import ProfileIcon from '../../../../assets/images/profileicon.png'
import SquareSVG from "../../../../assets/images/square.svg";

const Footer = ({avatar_url}) => {
  const { sideBarOpen, tokensCount, wide } = useContext(EventContext);

  return (
    <>
      <div className="mobile-footer w-100 p-1 mt-1 pb-2">
        <div className="text-center mt-1">
            <a href="/interested_events">
              <img src={SquareSVG} width={27}/>
              <div className="font-size-xxs color-bright-green text-center font-weight-bold" style={{marginTop: "6px"}}>Ordered Events</div>  
            </a>
            <div className="position-absolute rounded-circle" style={{left:"10px", top: "7px"}}>
              <a href="/profile">
                <img className="rounded-circle" style={{height: "30px", width: "30px"}}  src={avatar_url ? avatar_url : ProfileIcon} alt="profile icon"/>
              </a>
              <div style={{marginTop: "2px"}} className="font-size-xxs color-bright-green text-center font-weight-bold">Profile</div>
            </div>
        </div>
      </div>
    </>
  )
}

export default Footer;