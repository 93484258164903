import React, { useContext, useEffect, useState } from 'react';
import $ from 'jquery';
import { withStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import Tooltip from '@material-ui/core/Tooltip';
import InfiniteCircle from '../../../../assets/images/infinite_circle.svg';
import AvatarProfileIcon from '../../../../assets/images/contact_name_icon.svg';
import star from "../../../../assets/images/star.svg";
import copyIcon from '../../../../assets/images/copy_icon.svg';
import ShowEventDetails from '../EventCardV2/ShowEventDetails';
import { EventContext } from '../../contexts/EventContextContainer';
import { EmailContext } from '../../contexts/EmailContextContainer';
import EmailModal from "../../Shared/EmailModal";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9"
  }
}))(Tooltip);

const FreeEventsCard = ({event, handleInterestedClick, index, hasNewsletter}) => {
  let speaker_plural = (event.speaker_count == 1) ? "Speaker" : "Speakers"

  const { width, claimedAll, showEventDetailModal } = useContext(EventContext);
  const { handleSendEmail } = useContext(EmailContext)

  const [claimed, setClaimed] = useState((event.interested || event.ordered) || claimedAll);
  const [copied, setCopied] = useState(false);

  const isMobile = window.innerWidth < 992;

  const claimEvent = (id, interested, ordered) => {
    if (!claimed) 
        handleInterestedClick(id, interested, ordered, true);
    else {
      if (!isMobile) {
        handleSendEmail();
      } else {
        window.open("mailto:" + event.event_contact_email);
      }
    }
    setClaimed(true);
  } 

  const copyClick = () => {
    navigator.clipboard.writeText(event.event_contact_name + " " +  event.event_contact_email_unmasked + " " + event.event_contact_phone_unmasked + " " + event.event_contact_title);
    setCopied(true);
  }


  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied])

  let cardRef = React.createRef();

  $(document).on("ready", function() {
    document.getElementById('card-wrapper')
    if (document.getElementById('card-wrapper').clientHeight > 250) {
      $("#card-number-wrapper").css({"left" : "-77px"});
    }
  });

  useEffect(() => {
    if (document.getElementById('card-wrapper') != null) {
      if (document.getElementById('card-wrapper').clientHeight > 250) {
        $("#card-number-wrapper").css({"left" : "-77px"});
      }
    }
  }, [cardRef])

  if (claimedAll && !claimed) 
    setClaimed(true)

  return (
     <>
      { 
      !isMobile ?
      <div id="card-wrapper" className="cardV2-wrapper" ref={cardRef}>
        <div id="card-number-wrapper" className="card-number-wrapper newsletter"><span className="number">{index}</span></div>
        <div className="cardV2">
          <div className="card-body row justify-content-start">
            <div className="col-md-1"></div>
            <div className="col-md-7">
                <h2 className="title_date">
                  {event.event_date}
                </h2>
                <div className="event-title-wrapper">
                  <a onClick={() => showEventDetailModal(event.id)} className="title_" style={{textDecorationLine: "underline", textDecorationThickness: "2px"}}> <i class="mr-2 color-dark-green fas fa-info-circle font-size-m"/>{event.name}</a>
                </div>
                <div className="d-flex">
                    <p className="loc_">{event.city}, {event.state}</p>
                    <HtmlTooltip title={
                        <React.Fragment>
                            {"Estimated budgets are determined by past speakers, industry and spending habits. The estimated budget is geared towards paying spots at events like headliners or keynotes."}
                        </React.Fragment>
                        }>
                        <span className="ml-2 estimated-budget">ESTIMATED BUDGET: {event.budget_range}</span>
                    </HtmlTooltip>
                </div>
                <p className="desc_">
                </p>

                <div className="row">
                  <div className="col-md-8" >
                  <p className="tags_p">
                  {
                    event.event_industrys.map((event_industry, index) => (
                      <span key={index} className="tag_">
                        <a className="">
                          {event_industry.name}
                        </a>
                      </span>
                    ))
                  }
                </p>
                  </div>
                  <div onClick={() => showEventDetailModal(event.id)} className="col-md-4 color-dark-blue">
                    <HtmlTooltip title={
                      <React.Fragment>
                        {"This number pertains to the current event and past events that this organization has held and how many professional speakers were found there."}
                      </React.Fragment>
                    }>
                      <div>
                        <img className="star-image" src={star} alt="Professional Speakers" width="35px"/>
                        <span class="font-weight-bold lead star-text">{event.speaker_count}</span><br/><span className="pro-speakers"> PRO SPEAKER(S) </span> 
                      </div>
                    </HtmlTooltip> 
                  </div>
                </div>
              </div>
              
            <div className="col-md-3">
                <div className="contact-info-newsletter color-beige font-montserrat">
                    {
                      (event.interested || event.ordered) && 
                        <>
                          <HtmlTooltip title={
                            <React.Fragment>
                              {"Copy to Clipboard."}
                             </React.Fragment>
                          }>
                            <div>
                              <img
                              onClick={copyClick} 
                              src={copyIcon} 
                              width="20px" 
                              className={`${copied ? "d-none" : ""} float-right`} 
                              style={{cursor: "pointer"}}/>
                              <i className={`fa fa-check float-right ${!copied ? "d-none" : ""}`} style={{fontSize: "20px"}}/>
                            </div>
                          </HtmlTooltip>
                        </>
                    }
                    <p className="contact-info-newsletter color-beige font-montserrat desc_ font-weight-bold">
                        <img src={AvatarProfileIcon} style={{marginLeft: "-5px"}} width="35px"/>
                        {
                            claimed ? event.event_contact_name : "???" 
                        }
                    </p>
                    {
                        claimed && event.event_contact_title && 
                            <>
                            {
                                event.event_contact_title
                            }{"\n"}
                            </>
                    }
                    {
                        claimed && event.event_organization
                    }
                </div>
                <div className="contact-info-newsletter color-beige font-montserrat font-weight-bold">
                    <p className="contact-info-newsletter color-beige desc_"><i className='fas fa-envelope mr-1'></i> { claimed ? event.event_contact_email : "???" }</p>{"\n"}
                </div>
                {
                    event.event_contact_phone && <div className="contact-info-newsletter font-weight-bold font-montserrat color-beige">
                        <p className="contact-info-newsletter color-beige font-montserrat desc_"><i className='fas fa-flip fa-phone mr-1'></i> { claimed ? event.event_contact_phone : "???" }</p>
                    </div>
                }
                <button
                    onClick={() => claimEvent(event.id, event.interested, event.ordered)}
                    id={event.id}
                    className={`outlineBtn text-nowrap pr-4 pl-4 ${(event.interested || claimed)? "interested" : ""} interested-button`}
                >
                {
                    claimed ? "EMAIL CONTACT" : "CLAIM CONTACT"
                }
                </button>
                <div className="cost mt-1 ml-4">
                    <img src={InfiniteCircle} alt={"infinite circle cost"} width="30px"/>
                    Cost:<span className="font-weight-bold color-bright-green">Free!</span>
                </div>
            </div>
        </div>
        </div>
      </div>
      :
      <div className="cardV2-wrapper">
        <div className="cardV2-mobile ml-2 mr-2">
          <div className={hasNewsletter ? "newsletter card-number-wrapper" : "card-number-wrapper"}>
            <span className="number">{index}</span>
          </div>
          <div className="card-body row justify-content-start">
            <div className="col-md-7">
                <h2 className="title_date">
                  {event.event_date}
                </h2>
                <a onClick={() => showEventDetailModal(event.id)} target="_blank" className="title_" style={{textDecorationLine: "underline", textDecorationThickness: "2px"}}> <i class="mr-2 color-dark-green fas fa-info-circle font-size-m"/>{event.name}</a>
                <div className="d-flex">
                  { event.city != "some city" ?
                    <p className="loc_">{event.city}, {event.state}</p>
                  :
                    ""
                  }
                  <span className="ml-2 estimated-budget">ESTIMATED BUDGET: {event.budget_range}</span>
                </div>
                <p className="desc_">
                </p>

                <div onClick={() => showEventDetailModal(event.id)} className="color-dark-blue row">
                  <div className="ml-2">
                      <img className="star-image" width="35px" src={star} alt="Professional Speakers"/>
                      <span class="font-weight-bold lead star-text">{event.speaker_count}</span><span className="pro-speakers"> PRO SPEAKER(S) </span>  
                  </div>
                  <div className="cost font-weight-bold mt-2 ml-4"><img src={InfiniteCircle} alt={"infinite circle cost"} width="35px" />Cost: {hasNewsletter ? <span className="font-weight-bold color-bright-green">Free!</span> : 1 }</div>
                </div>
                {
                  event.trialUser ?
                    <p> Paying Users Can See Unmasked Information </p>
                  :
                    ""
                }
            </div>
          </div>
          <div className="row">
            <div className="col text-right">
              <div className="text-left ml-5">
                <div className="contact-info-newsletter color-beige font-montserrat">
                    <p className="contact-info-newsletter color-beige font-montserrat desc_ font-weight-bold">
                        <img src={AvatarProfileIcon} style={{marginLeft: "-5px"}} width="35px"/>
                        {
                            claimed ? event.event_contact_name : "???" 
                        }
                    </p>
                    {
                        claimed && event.event_contact_title && 
                            <>
                            {
                                event.event_contact_title
                            }{"\n"}
                            </>
                    }
                    {
                        claimed && event.event_organization
                    }
                </div>
                <div className="contact-info-newsletter color-beige font-montserrat font-weight-bold">
                    <p className="contact-info-newsletter color-beige desc_"><i className='fas fa-envelope mr-1'></i> { claimed ? event.event_contact_email : "???" }</p>{"\n"}
                </div>
                {
                    event.event_contact_phone && <div className="contact-info-newsletter font-weight-bold font-montserrat color-beige">
                        <p className="contact-info-newsletter color-beige font-montserrat desc_"><i className='fas fa-flip fa-phone mr-1'></i> { claimed ? event.event_contact_phone : "???" }</p>
                    </div>
                }
              </div>
              {
                <button
                  onClick={() => claimEvent(event.id, event.interested, event.ordered)}
                  id={event.id}
                  className={`text-nowrap outlineBtn pr-4 pl-4 ${(event.interested || claimed) ? "interested" : ""} interested-button`}
                >
                {
                    claimed ? "EMAIL CONTACT" : "CLAIM CONTACT"
                }
                </button>
              }
            </div>
            </div>
          </div>
          <p className="tags_p mt-3 ml-3">
          {
            event.event_industrys.map((event_industry, index) => (
              <span key={index} className="tag_">
                <a className="">
                  {event_industry.name}
                </a>
              </span>
            ))
          }
          </p>
      </div>
      }
    <EmailModal event={event}/>
    <ShowEventDetails event={event} newsletter={true} handleOnClick={() => claimEvent(event.id, event.interested, event.ordered)}/>
    </>
  )
}

export default FreeEventsCard;
