import React, { useContext, useEffect, useState } from 'react';
import $ from 'jquery';
import { withStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import Tooltip from '@material-ui/core/Tooltip';
import InfiniteCircle from '../../../../assets/images/infinite_circle.svg'
import star from "../../../../assets/images/star.svg"
import contactNow from "../../../../assets/images/contact_now.svg";
import { EmailContext } from '../../contexts/EmailContextContainer';
import EmailModal from '../../Shared/EmailModal';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9"
  }
}))(Tooltip);

const InterestedEventCard = ({event, handleInterestedClick, index, showPastEvents, emailFeatureFlag}) => {
  const isMobile = window.innerWidth < 900;
  const [cardNumberPosition, setCardNumberPosition] = useState("0px");
  const { handleSendEmail } = useContext(EmailContext);

  let cardRef = React.createRef();
  
  useEffect(() => {
    if (cardRef.current != null && window.innerWidth > 992) {
      var amount = (cardRef.current.clientHeight / 3.4394) * -1;
      setCardNumberPosition(amount + "px")
    } else if (window.innerWidth < 993) {
      setCardNumberPosition("-55px")
    }
  }, [cardRef])
  
  return (
     <>
      { 
      (!isMobile) ?
      <div id="card-wrapper" className="cardV2-wrapper ml-5 mr-5 mb-4" ref={cardRef}>
        <div id="card-number-wrapper" className="card-number-wrapper" style={{left: cardNumberPosition, background: "#00c89d"}}>
          <span className="number">{index}</span>
        </div>
        <div className="cardV2">
          <div className="card-body row justify-content-start">
            <div className="col-md-1"></div>
            <div className="col-md-7">
                <h2 className="title_date">
                  {event.event_date}
                </h2>
                <div className="event-title-wrapper">
                  <a href={`/events/${event.id}`} target="_blank" className="title_" style={{textDecorationLine: "underline", textDecorationThickness: "2px"}}><i class="mr-2 color-dark-green fas fa-info-circle font-size-m"/>{event.name}</a>
                </div>
                <div className="d-flex">
                    <p className="loc_">{event.city}, {event.state}</p>
                    <span className="ml-2 estimated-budget">ESTIMATED BUDGET: {event.budget_range}</span>
                </div>
                <div className="row">
                  <div className="col-md-8" >
                  <p className="tags_p">
                  {
                    event.event_industrys.map((event_industry, index) => (
                      <span key={index} className="tag_">
                        <a className="">
                          {event_industry.name}
                        </a>
                      </span>
                    ))
                  }
                </p>
                  </div>
                  <div className="col-md-4 color-dark-blue">
                    <img className="star-image" src={star} alt="Professional Speakers" width="35px"/>
                    <span class="font-weight-bold lead star-text">{event.speaker_count}</span>
                    <br/>
                    <span className="pro-speakers"> PRO SPEAKER(S) </span> 
                  </div>
                </div>
              </div>
                <div className="col-md-4 text-center pt-4">
                    {
                      (emailFeatureFlag) 
                      ? 
                        <>
                          <button
                            onClick={() => handleSendEmail(event)}
                            id={event.id}
                            className={`pt-2 pb-2 pl-4 pr-4 green-button-small text-nowrap border-0`}
                          >
                            { showPastEvents && <img style={{marginTop: "-10px", marginBottom: "-8px"}} width="40px" src={contactNow}/> }
                            { !showPastEvents ? "EMAIL CONTACT" : "CONTACT AGAIN" }
                          </button>
                          {
                            showPastEvents && <div style={{letterSpacing: "0.35px"}} className="text-center mt-1 w-100 color-bright-green font-size-s font-montserrat">email sent on: {event.last_emailed_date}</div>
                          }
                        </>
                      :
                        <>
                          <p className="contact-info desc_"><i className='fas fa-envelope mr-1'></i> {event.event_contact_email}</p>{"\n"}
                          {
                            event.event_contact_phone &&
                              <p className="contact-info desc_"><i className='fas fa-flip fa-phone mr-1'></i> {event.event_contact_phone}</p>                  
                          }
                        </>
                    }

                    {
                        (showPastEvents || (emailFeatureFlag && !showPastEvents)) && <button
                            onClick={() => handleInterestedClick(event.id)}
                            id={parseInt("1" + event.id)}
                            className="blue-button mt-3 border-0"
                        >
                            <i class="fas fa-download"/><span className="ml-2">EXPORT INFO</span>
                        </button>
                    }
                    {
                        !showPastEvents && !emailFeatureFlag && <button
                            onClick={() => handleInterestedClick(true, event.id)}
                            id={parseInt("2" + event.id)}
                            className="beige-button mt-5"
                        >
                            REMOVE
                        </button>
                    }
                </div>
            </div>
          </div>
      </div>
      :
      <div className="cardV2-wrapper">
        <div className="cardV2-mobile ml-2 mr-2">
          <div className="card-number-wrapper" style={{borderColor: "transparent transparent transparent #00c89d"}}>
            <span className="number">{index}</span>
          </div>
          <div className="card-body row justify-content-start">
            <div className="col-md-7">
                <h2 className="title_date">
                  {event.event_date}
                </h2>
                <a href={`/events/${event.id}`} target="_blank" className="title_" style={{textDecorationLine: "underline", textDecorationThickness: "2px"}}> <i class="mr-2 color-dark-green fas fa-info-circle font-size-m"/>{event.name}</a>
                <div className="d-flex">
                    <p className="loc_">{event.city}, {event.state}</p>
                    <span className="ml-2 estimated-budget">ESTIMATED BUDGET: {event.budget_range}</span>
                </div>
                <p className="desc_">
                </p>

                <div className="row color-dark-blue">
                  <div className="ml-2">
                      <img className="star-image" width="35px" src={star} alt="Professional Speakers"/>
                      <span class="font-weight-bold lead star-text">{event.speaker_count}</span><span className="pro-speakers"> PRO SPEAKER(S) </span>  
                  </div>
                </div>
            </div>
          </div>
          <div className="row">
            <div className="col text-right">
              <div className="ml-5 float-right">
                {
                    <>
                      <a
                      href={(event.interested || event.ordered) ? "mailto:" + event.event_contact_email : ""}
                      id={event.id}
                      className={`${(event.interested || event.ordered) ? "green-button-mobile" : "outlineBtn text-nowrap "}`}
                    >
                      {(event.interested || event.ordered) ? "EMAIL CONTACT" : "GET CONTACT INFO"}
                    </a>
                      {
                        showPastEvents && <div style={{letterSpacing: "0.35px"}} className="text-center mt-1 w-100 color-bright-green font-size-s font-montserrat">email sent on: {event.last_emailed_date}</div>
                      }
                    </>
                }
              </div>
              {
                (showPastEvents || (emailFeatureFlag && !showPastEvents)) && <button
                  onClick={() => handleInterestedClick(event.id)}
                  id={event.id}
                  className="blue-button border-0 mt-3"
                >
                <i class="fas fa-download"/><span className="ml-2">EXPORT INFO</span>
                </button>
              }
            </div>
            </div>
          </div>
          <p className="tags_p mt-3 ml-3">
          {
            event.event_industrys.map((event_industry, index) => (
              <span key={index} className="tag_">
                <a className="">
                  {event_industry.name}
                </a>
              </span>
            ))
          }
          </p>
      </div>
      }
      <EmailModal event={event}/>
    </>
  )
}

export default InterestedEventCard;
