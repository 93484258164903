import React, { useState, useContext, useEffect } from 'react';
import { AdminContext } from '../../contexts/AdminContextContainer';

const UserCount = () => {

  const { userCount, graphType } = useContext(AdminContext);

  return (  
    <> 
      {
        (graphType != "Industry") &&
          userCount.map((e, index) => 
            <h3 className="title-text">{e[0]}<span>:</span>{e[1]}</h3>
          )
      }
    </>
  )
}

export default UserCount;

