import React, { useContext, useState } from 'react';
import axios from "axios";
import { EventContext } from '../../contexts/EventContextContainer';
import { Button, Modal } from 'react-bootstrap';

const Description = ({userId, currentDescription, setCurrentDescription}) => {
  const [show, setShow] = useState(false);
  const [description, setDescription] = useState("")
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [EditMode, setEditMode] = useState(false);
  
  const toggleEditMode = (val) => {
    if (EditMode) {
      handleOnSubmit(val)
    }
    setEditMode(!EditMode);
  }

  const handleOnSubmit = (val) => {
    if (val.length < 1) {
        alert("Please provide a description for your account.")
    } else {
      var params = {  
        'description' : val, 
        'user_id' : userId
      }
      axios.put(`/profiles/update_user.json`,{
        params: params
      })
      .then((response) => {
        setShow(false);
        setCurrentDescription(val);
      })
    }
  }

  return (
    <div>
        <div style={{cursor: 'pointer'}} className="w-100 color-dark-blue mb-1 mt-1 font-italic" onClick={handleShow}>
            <i className="fas fa-edit"></i>&nbsp; Speaker Description
        </div>
        <textarea
          className="w-100 font-size-s"
          defaultValue={currentDescription}
          onChange={(e) => toggleEditMode(e.target.value)}
          style={{minHeight:"300px", height:"100%", outline:"2px solid #97c6aa"}}
        />
        <div class="textarea-modal"></div>
    </div>
  )
}

export default Description;
