import React, { useState, useContext, useEffect } from 'react';
import { AdminContext } from '../../contexts/AdminContextContainer';

const GraphPercentage = () => {

  const { data, graphType } = useContext(AdminContext);

  const events_percentages = (d) => {
    var total = 0;
    var unpaid = 0
    var paid = 0;
    for (var i = 0; i < d.length; i++) {
      unpaid += d[i]["Pro Bono 0"];
      paid += d[i]["$1-$4,999"] + d[i]["$15,000+"] + d[i]["$5,000-$14,999"];
    }
    total = unpaid + paid;
    return { "paid" : Math.round((paid / total) * 100), "unpaid" : Math.round((unpaid / total) * 100)};
  }

  return (  
    <div> 
      { (data.length > 0 && graphType != "Industry") &&
      <ul id="graphPercentage" className="nav nav-pills  nav_tb mb-3" style={{marginLeft: "40px"}}>
        <li className="_nav_link nav-item box_shadow_shdo" >
            <span class="title-text">Unpaid: {events_percentages(data)["unpaid"]}%</span>
        </li>
        <li> 
          <span class="title-text">Paid: {events_percentages(data)["paid"]}%</span>
        </li>
      </ul>
      }
    </div>
  )
}

export default GraphPercentage;

