import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import dialogBox from "../../../../assets/images/dialog_box.svg";
import mobileLineIntroCard from "../../../../assets/images/interested_events_header_line.svg";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9"
  }
}))(Tooltip);

const IntroCard = ({userName, userQuiz, userQuizId, stripeCheckoutLink}) => {
  function bestOptionSearch() {
    location.href = "/saved_searches/" + userQuizId
  }

const isMobile = window.innerWidth < 992;

  return (
    <>
    {
      !isMobile ? 
      <div class="container event-list-intro-card">
      <div class="row mt-5"> 
        <div class="col-md-2">

        </div>
        <div class="cta-wrapper position-relative">
        <img className="position-absolute" src={dialogBox} alt={"Dialog Box"} width="487px"/>
          <div class="cta pt-5 pr-3 mb-5"> 
            <div class="cta-text"> 
              Hey {userName}! <br/> <div className="cta-wrap-text">Ready for your <br/> next speaking gig?</div>
            </div>
          </div>
          {
            userQuiz && 
            <div className="pt-1">
              <div className="text-right mt-5 pt-5">
                <a onClick={bestOptionSearch} className="green-button">Search my best options</a>
              </div>
              <p className="cta-button-description font-italic font-weight-light font-montserrat-thin font-size-s mt-3 pt-2"> 
                This button will show you personalized results
                based on the most recent <a href={"/forms/events?source=internal"} className="font-weight-bold speaker-quiz">Speaker Quiz</a><br/>
                you've taken.
              </p> 
            </div>
          }
        </div>
        <div class="col-md-3"> 
        </div>
      </div>
    </div>
    :
      <>
        <div className="event-list-intro-card mt-5"> 
          <div class={`cta ${!isMobile ? "mr-5 ml-5" : ""} position-relative`}> 
              <div class="cta-text"> 
                Hey {userName}! <br/> <div className="cta-wrap-text">Ready for your <br/> next speaking gig?</div>
              </div>
              <img src={mobileLineIntroCard} className="mt-2" width={"100%"} />
            </div>
        </div>
        {
          userQuiz ? 
            <div className="text-center mt-4">
                <a onClick={bestOptionSearch} className="green-button-medium pl-3 pr-3 pt-2 pb-2">Search my best options</a>
            </div>
          : 
          <div className="text-center mt-4"> 
            <a className="green-button-medium pl-3 pr-3 pt-2 pb-2" href={"/forms/events?source=internal"}>Take the quiz!</a>
          </div>
        }
        
        <p className="text-center mt-3 color-bright-green font-size-xxs font-weight-bold"> 
          This way you'll see personalized results <br/>
          to get to your next speaking gig!
        </p>
      </>
    }
    </>
  )
}

export default IntroCard;
