export const convertToBudgetRange = (val) => {
  val = val.toString();
  let val_i = val.replace("+", "");
  val_i = parseInt(val_i);

  if ( val_i < 1 )
    return "Pro Bono 0"

  else if ( val_i > 0 && val_i < 5000 )
    return "$1-$4,999";

  else if ( val_i > 4999 && val_i < 15000 )
    return "$5,000-$14,999";

  else if ( val_i > 14999 )
    return "$15,000+";

  else
    return "";
}

export const convertFilters = (filters) => {
  let currentFilters = {}

  Object.keys(filters).forEach((key) => {
    if ( filters[key].length > 0)
      currentFilters[key + "[]"] = filters[key].join(",");
  })

  return currentFilters;
}
