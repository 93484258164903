import React, { useContext } from 'react';
import { EventContext } from '../../contexts/EventContextContainer';
import InfiniteCircle from '../../../../assets/images/blue_infinite_circle.png'
import axios from "axios";

const Tokens = ({stripeCheckoutLink, userTrial}) => {
  const { sideBarOpen, tokensCount } = useContext(EventContext);

  const getStripeCheckout = () => {
    axios.get(`/checkout.json`, {
      params: {}
    }).then((response) => {
      location.href = response.data
    })
  }

  return (
    <>
      <div class={ sideBarOpen ? "navbar-item token-top-nav align-items-center d-flex" : "closed-side-bar align-items-center navbar-item pt-1 token-top-nav d-flex mr-1"} id="js-token-types">
        <img className="mr-1" src={InfiniteCircle} alt={"infinite circle cost"}/>
        <span className="d-flex" id="js-number-interested-event-text mr-1"><span className="d-lg-block d-none">TOKENS: </span>{tokensCount}</span>
      </div>
      
      {
        (userTrial || (tokensCount == 0 && !paidTrial)) && 
        <a onClick={getStripeCheckout} style={{left: "500px"}} className="navbar-item align-items-center buy-token-top-nav pr-3 pl-3 d-none">
          <img src={InfiniteCircle} alt={"infinite circle cost"} style={{width:"45px"}}/> BUY TOKENS
        </a>
      } 
      {
        (paidTrial && tokensCount == 0) && 
          <a onClick={handleShowZeroToTokensModal} style={{left: "500px"}} className="navbar-item align-items-center buy-token-top-nav pr-3 pl-3 d-none">
            <img src={InfiniteCircle} alt={"infinite circle cost"} style={{width:"45px"}}/> UPGRADE SUBSCRIPTION
          </a>
      }
    </>
  )
}

export default Tokens;
