import React, { useContext } from 'react';
import { EventContext } from '../../contexts/EventContextContainer';
import SavedSearchCard from '../SavedSearchCard';

const SavedSearchList = ({userName}) => {
  const { savedSearches } = useContext(EventContext);

  return (
    <div className="saved-search-list">
        {
          (savedSearches.length > 0) && <>
            <div className="text-center"><i className="fas fa-save"/></div>  
            <div className="text-center subheader mb-5">{userName}, here are your saved searches.</div>
          </>
        }
        {
            (savedSearches.length > 0) && 
                savedSearches.map((savedSearch, index) => (
                <React.Fragment key={savedSearch.id}>
                    <SavedSearchCard savedSearch={savedSearch} index={index+ 1} />
                </React.Fragment>
                ))
        }
        {
            (savedSearches.length == 0) &&
              <>
                <div className="text-center mb-2"><i className="fas fa-save"/></div>  
                <h2 className="text-center header-no-saved-search font-weight-bold">Hi {userName}!</h2>
                <h2 className="text-center header-no-saved-search font-weight-bold">Here you will see your saved searches!</h2>
                <h2 className="text-center header-no-saved-search">You haven't saved your first search yet.</h2>
                <p className="mt-5 text-center font-style-italic">
                  To save searches, use the filters<br/>
                  in the left side of the screen <br/>
                  and the hit the "Saved search" button <br/>
                  Add a name and save it!
                </p>
              </>
        }
    </div>
  )
}

export default SavedSearchList;
