import React, { useEffect, useState } from 'react';
import axios from "axios";
import star from "../../../../assets/images/star.svg"
import AvatarProfileIcon from '../../../../assets/images/contact_name_icon.svg';
import InfiniteCircle from '../../../../assets/images/infinite_circle.svg'
import Tokens from "../TokensNonEvents";
import tficon from '../../../../assets/images/speaker_events_database.svg'
import SquareSVG from "../../../../assets/images/square.svg";
import FeelingConfused from '../../Shared/FeelingConfused';
import backButton from "../../../../assets/images/back_button.png";
import FeaturedSpeaker from '../../Shared/FeaturedSpeaker';

const Show = ({ event, event_industry_names, speakers, date, contact, interested, ordered, tokens, organization, stripeCheckoutLink, userTrial, paidTrial,userTier, userName, numOrderedEvents, inNewsletter}) => {
    const isMobile = window.innerWidth < 992;

    const [orderedState, setOrderedState] = useState(ordered)
    const [interestedState, setInterestedState] = useState(interested)
    const [tokensCount, setTokensCount] = useState(tokens)

    const handleInterestedClick = (event_id, interested_, ordered_) => {
        if ( ordered ) {profile
            alert("You previously ordered this event")
            return
        }
        
        let promise;
        let notificationPromise;
    
        if ( interested_ )
          promise = axios.delete(`/events/not-interested_events/${event_id}.json`);
        else {
          promise = axios.post(`/events/interested_events/${event_id}.json`);
          if (tokensCount > 0) {
            notificationPromise = axios.post(`/notifications/create_ordered_event_notification_js.json?event_id=${event_id}`);
          }
        }
          
        promise.then(({data}) => {
          if (data != undefined && data > -1 && ((data + "").length > 0)) {
            setTokensCount(parseInt(data));
            setInterestedState(true)
            setOrderedState(true)
            notificationPromise.then(({d}) => {
              console.log("Notification successfully created", d);
            });
          }
          else {
            alert("No More Credits Available!");
          }
        }) 
      }

  return (
    <>
     <Tokens stripeCheckoutLink={stripeCheckoutLink} userTrial={userTrial} paidTrial={paidTrial} eventsPage={false}/>
     <FeelingConfused userTrial={userTrial}/>
     <div className="profile-col">
          <div className="row text-center d-flex">
              <div className="col">              
                  <a href="/events">
                    <img src={tficon} alt={"profile icon"} width={"250px"}/>
                  </a>
                  <div className="w-100 text-center name-header color-dark-blue mt-5">
                    {userName}
                  </div>
                  <div className="w-100 text-center tier color-dark-blue mt-3">
                    <span className="font-italic">Your Tier:</span><span className="font-weight-bold"> {userTier} speaker</span>
                  </div>
                  <div className="bottom-buttons text-center">
                    <span className="purchased-events font-weight-bold">{numOrderedEvents} purchased events</span>
                    <div className="mb-3 mt-3">
                        <a href="/interested_events">
                            <img src={SquareSVG} width={27}/>
                            <div className="font-size-xxs color-bright-green text-center font-weight-bold" style={{marginTop: "6px"}}>Ordered Events</div>  
                        </a>
                    </div>
                    <div className="mb-3"><a className="go-to-database font-weight-bold" href="/events">GO TO DATABASE</a></div>
                  </div>
                  <div className="mr-4 ml-4">
                    <FeaturedSpeaker size="column"/>
                  </div>
              </div>
         </div>
      </div>
      <div className="modal-wrapper w-100 h-100 positive-relative" style={{marginLeft: isMobile ? "0px" : "420px", marginTop: "100px", height: "100%", maxHeight: "666px" }}>
        {
            !isMobile && <div style={{backgroundColor: "#5cb385", width: "20px", height: "100px", top: "100px"}} className="position-absolute"/>
        }
        <div className="event-modal-wrapper font-montserrat ml-5">
            <div className="font-size-xl color-dark-green">event info2</div>
            <h2 className="title_date font-italic">{date}</h2>
            <div id="test" className="font-weight-bold font-size-l color-light-black">{event.name}</div>
            <div className="d-flex">
                <p className="font-weight-bold font-size-s color-light-black">{event.city}, {event.state}</p>
                <span className="ml-2 font-weight-bold font-size-s color-dark-green">ESTIMATED BUDGET: {event.budget_range}</span>
            </div>
            <div className="row">
                <div className="col-md-8">
                    <p className="tags_p">
                    {
                        event_industry_names.map((n, index) => (
                        <span key={index} style={{backgroundColor: "unset", borderRadius: "15px", border: "1px solid #5cb385", cursor: "default"}} className="tag_event_modal">
                            <span style={{fontSize: "12px"}} className="font-italic text-decoration-none color-dark-green">
                            {n}
                            </span>
                        </span>
                        ))
                    }
                    </p>
                </div>
            </div>
            <div style={{fontSize: "18px"}} className="mt-3 font-weight-bold color-light-black">Official link:</div>
            <div style={{fontSize: "18px"}} className="font-weight-bold color-bright-green">{event.website}</div>
            <div className="mt-3 d-flex">
                <img className="star-image" src={star} alt="Professional Speakers" width="35px"/>
                <span class="font-weight-bold lead font-size-l color-light-black">{speakers.length}</span>
            </div>
            <div style={{marginLeft: "35px", top: "-7px"}} className="position-relative font-size-xxs font-weight-bold color-dark-blue font-size-xxs">PRO SPEAKER(S)</div>
            <div className="mt-3">
                {
                    speakers.map((speaker, index) => (
                        <div style={{letterSpacing: "0.35px"}} className="position-relative mt-2">
                            <img style={{left: "-53px"}}  className="star-image position-absolute" src={star} alt="Professional Speakers" width="45px"/>
                            <div style={{fontSize: "16px"}} className="color-light-black font-weight-bold">{speaker.name} | Year: {speaker.year}</div>
                            <div className="font-size-s">{speaker.tagline}</div>
                            <div className="font-size-s">{speaker.speaker_types}</div>
                        </div>
                    ))
                }
            </div>
            <div className="row mt-5"> 
                <div className="col-md-6 position-relative">
                    <div className="contact-info-newsletter color-beige font-montserrat">
                        <p className="contact-info-newsletter color-beige font-montserrat desc_ font-weight-bold">
                            <img className="position-absolute" src={AvatarProfileIcon} style={{left: "-29px", marginLeft: "-5px", top: "-5px"}} width="35px"/>
                            {
                                (interestedState || orderedState || inNewsletter) ? contact.name : "Contact Name" 
                            }
                        </p>
                        {
                        (interestedState || orderedState || inNewsletter) && contact.title && 
                            <>
                            {
                                contact.title
                            }{"\n"}
                            </>
                        }
                        {
                        (interestedState || orderedState || inNewsletter) && organization
                        }
                    </div>
                    <div className="contact-info-newsletter color-beige font-montserrat font-weight-bold">
                        <p className="contact-info-newsletter color-beige desc_"><i style={{fontSize: "22px", left: "-28px"}} className='fas fa-envelope position-absolute'></i> 
                        { (interestedState || orderedState || inNewsletter) ? contact.email : "???" }
                        </p>{"\n"}
                    </div>
                    {
                        contact.phone && contact.phone.length > 1 && 
                        <div className="contact-info-newsletter font-weight-bold font-montserrat color-beige">
                            <p className="contact-info-newsletter color-beige font-montserrat desc_">
                            <i style={{fontSize: "22px", left: "-28px"}} className='position-absolute fas fa-flip fa-phone'></i>
                                { (interestedState || orderedState || inNewsletter) ? contact.phone : "???" }
                            </p>
                        </div>
                    } 
                </div>
                <div className="col-md-6 mb-5"> 
                    <div style={{borderRadius: "20px", border: "2px solid #00c89d", maxWidth: "277px", width: "100%", letterSpacing: "0.45px"}} className="p-3 text-center mb-4"> 
                        {
                        !(interestedState || orderedState || inNewsletter) &&
                        <>
                            <div style={{fontSize: "18px"}} className="color-beige">Do you like the event?</div>
                            <div style={{fontSize: "18px"}} className="color-beige font-weight-bold mb-2">Reveal contact info</div>
                        </>
                        }
                        <div            
                            onClick={() => handleInterestedClick(event.id, interestedState, orderedState) }
                            id={event.id}
                            className={`text-nowrap mt-2 mb-2 ${(interestedState || orderedState || inNewsletter) ? "green-button-medium" : "beige-button" }`}
                        >
                        {(orderedState || interestedState || inNewsletter) ? "EMAIL CONTACT" : "GET CONTACT INFO"} 
                        </div>
                        <div className="mt-2 d-inline-block">
                            <img className="d-inline" src={InfiniteCircle} alt={"infinite circle cost"} width="30px"/>
                            <div className="d-inline color-beige font-weight-bold font-size-s">COST: 1</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>  
        </div>
        {
            isMobile && 
                <div className="w-100 d-md-block d-lg-none navbar fixed-top navbar-menu">
                    <ul class="navbar-nav pull-left mobile-nav-bar-filter" onClick={() => window.history.back()}>
                        <div><img style={{height: "50px"}} className="close-mobile-filters" src={backButton} alt="filters" /></div>
                    </ul>
                </div>
        }
    </>
  );
}

export default Show;
