import React, { useState, setState, useContext, useEffect } from 'react';
import FeaturedSpeakerBackground from '../../../../assets/images/trinityfix_featured_speaker_background.svg';
import FeaturedSpeakerColumnBackground from '../../../../assets/images/trinityfix_featured_speaker_background_column.svg';

const FeaturedSpeaker = ({ size }) => {
    const [speakerURL, setSpeakerURL] = useState();
    const [eventDate, setEventDate] = useState("");
    const [location, setLocation] = useState("");
    const [speakerName, setSpeakerName] = useState("");
    const [amount, setAmount] = useState("")
    const [responsiveSize, setResponsiveSize] = useState(size)
    const [rowTopPicture, setRowTopPicture] = useState(73);
    const [radius, setRadius] = useState(110);
    const [radiusColumn, setRadiusColumn] = useState(0)

    useEffect(() => {
        let url = '/featured_speakers/most_recent_speaker.json';
        fetch(url)
            .then(response => response.json())
            .then(data => {
                setSpeakerURL(data.avatar_url);
                setSpeakerName(data.speaker_name);
                setLocation(data.location);
                setEventDate(data.date);
                setAmount(data.amount)
            });
      }, [])

      let cardRef = React.createRef();

      useEffect(() => {
        if (cardRef.current != null) {
            if (cardRef.current.clientWidth < 662) {
                setResponsiveSize("column")
            } else {
                setResponsiveSize(size)
            }
            if (cardRef.current.clientWidth < 880 && cardRef.current.clientWidth > 662) {
                setRowTopPicture(cardRef.current.clientWidth / 12)
                setRadius(cardRef.current.clientWidth / 7.333)
            }
            if (cardRef.current.clientWidth < 400) {
                setRowTopPicture(cardRef.current.clientWidth / 3.39)
                setRadius(170)
            }
            else if (cardRef.current.clientWidth < 663) {
                setRowTopPicture(cardRef.current.clientWidth / 3.39)
                setRadius(170)
            }
        }
      }, [cardRef])
      
    return (
        <div>
            {
                (responsiveSize == "row" && speakerURL) && 
                <>
                    <div className="row position-relative" ref={cardRef}> 
                        <div className="pr-2 d-flex justify-content-center w-100 position-absolute" style={{top: `${rowTopPicture}px`}}> 
                            <div style={{backgroundImage: "url(" + speakerURL+ ")", borderRadius: '50%', width: radius + "px", height: radius + "px", backgroundSize: "cover", backgroundPosition: "center center"}}/>
                        </div>
                        <img src={FeaturedSpeakerBackground} width="100%"/>
                        <div className="d-flex justify-content-center w-100"> 
                            <div className="font-montserrat font-size-s color-white" style={{maxWidth: "370px", marginTop: "-127px", letterSpacing: "0.35px"}}>
                                <span>This week, our featured speaker, 
                                    <span className="font-weight-bold">{` ${speakerName} landed a speaking gig in ${location}, ${eventDate}, worth $${amount} `}</span> 
                                    thanks to the outbound emails sent from our database.
                                </span>
                                <br/> 
                                Congrats!
                            </div>
                        </div>
                    </div>
                    
                </>
            }
            {
                (responsiveSize == "column" && speakerURL) &&
                <>
                    <div className="row position-relative" ref={cardRef}> 
                        <div className="pr-2 d-flex justify-content-center w-100 position-absolute" style={{top: `${rowTopPicture}px`}}>
                            <div style={{backgroundImage: "url(" + speakerURL+ ")", borderRadius: '50%', width: radius + "px", height: radius + "px", backgroundSize: "cover", backgroundPosition: "center center"}}/>
                            {/* <img src={speakerURL} width={`${radius}px`} height={`${radius}px`} style={{borderRadius: "50%"}}/> */}
                        </div>
                        <img src={FeaturedSpeakerColumnBackground} width="100%"/>
                        <div className="d-flex justify-content-center w-100"> 
                            <div className="font-montserrat font-size-m font-italic color-white text-left p-2" style={{width: "90%", marginTop: "-250px", letterSpacing: "0.35px"}}>
                                <span>This week, our featured speaker, 
                                    <span className="font-weight-bold">{` ${speakerName} landed a speaking gig in ${location}, ${eventDate}, worth $${amount} `}</span> 
                                    thanks to the outbound emails sent from our database.
                                </span>
                                <br/> 
                                Congrats!
                            </div>
                        </div>
                    </div>
                    <div className="w-100 mt-3 text-center"> 
                        <a
                            href="https://trinityfix.com/2021/11/18/a-speakers-guide-to-finding-professional-speaker-opportunities/"
                            className="ml-1 my-purchase-events font-weight-bold"
                            style={{maxWidth: "250px"}}
                        > TIPS FOR SUCCESS </a>
                    </div>
                    <div className="w-100 mt-3 mb-5 text-center"> 
                        <a
                            href="mailto:support@trinityfix.com"
                            className="ml-1 my-purchase-events font-weight-bold"
                            style={{maxWidth: "250px"}}
                        > COACHING </a>
                    </div>
                </> 
            }
            {
                (responsiveSize != "column" && responsiveSize != "row") && <h1>Please choose a size "row" or "column"</h1>
            }
      </div>
    );
};

export default FeaturedSpeaker;