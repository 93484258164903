import React, { useState, useContext, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { EventContext } from '../../contexts/EventContextContainer';
import moment from 'moment';

const TimeFiltersV2 = () => {
  const [isActive, setIsActive] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState('');
  const { handleTimeFilter, handleSetEndDate, handleFilterClick, handleTopEventsFilterClick, timeFilters }  = useContext(EventContext);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  let weekRef = React.createRef();
  let todayRef = React.createRef();

  const handleStartDateChange = (date) => {
    setStartDate(date);
    handleTimeFilter('custom', {start_date: date, end_date: endDate});
  }

  const handleEndDateChange = (date) => {
    setEndDate(date);
    handleTimeFilter('custom', {start_date: startDate, end_date: date});
  }

  const handleOnClick = (time) => {
    if (time == "week" && weekRef.current.className.indexOf("active") > -1) {
      setSelectedFilter('');
      handleTimeFilter('');
    }
    else if (time == "today" && todayRef.current.className.indexOf("active") > -1) {
      setSelectedFilter('');
      handleTimeFilter('');
    }
    else if (time == selectedFilter) {
      setSelectedFilter('');
    } else {
      setSelectedFilter(time)
      setIsActive(false)
      handleTimeFilter(time)
    }
  }

  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }


  const isSelected = (time) => selectedFilter == time && "active"

  if (timeFilters != null && timeFilters["start_date"] != undefined && timeFilters["start_date"] != "undefined" && isValidDate(timeFilters["start_date"]) && timeFilters["start_date"] != startDate) {
    setStartDate(timeFilters["start_date"])
  }
  if (timeFilters != null && timeFilters["end_date"] != undefined && timeFilters["end_date"] != "undefined" && isValidDate(timeFilters["end_date"]) && timeFilters["end_date"] != endDate) {
    setEndDate(timeFilters["end_date"])
  }

  const isMobile = window.innerWidth < 992;

  return (
    <>
      { 
        !isMobile ?
        <ul id="timeFilters" className="nav nav-pills nav_tb mb-3 time-filters-v2 ml-4">
          <li ref={todayRef} className={`w-100 mt-1 ${isSelected("today")}`} onClick={() => handleOnClick("today")} ><span style={{color:"#61c4c6", left: "20px"}}className={isSelected("today") ? "mr-2 position-absolute" : "hidden"}>✔</span>Today</li>
          <li ref={weekRef} className={`w-100 mt-1 ${isSelected("week")}`} onClick={() => handleOnClick("week")} ><span style={{color:"#61c4c6", left: "20px"}}className={isSelected("week") ? "mr-2 position-absolute" : "hidden"}>✔</span>This Week</li>
          <li class="title-text mt-1">Start Date:
            <DatePicker
              dateFormat="MMMM d, yyyy"
              selected={startDate}
              className="ml-1"
              isClearable
              onChange={handleStartDateChange}
            />
            </li>
            <li className="date-separator mt-1">End Date:
                <DatePicker
                  dateFormat="MMMM d, yyyy"
                  selected={endDate}
                  className="ml-1"
                  isClearable
                  onChange={handleEndDateChange}
                />
          </li>
        </ul>
        :
        <ul id="timeFilters" className="font-weight-normal font-size-s nav nav-pills nav_tb mb-3 time-filters-v2 ml-4">
          <li ref={todayRef} className={`w-100 mt-1 ${isSelected("today")}`} onClick={() => handleOnClick("today")}>
            <span style={{color:"#61c4c6"}}className={isSelected("today") ? "mr-2" : "hidden"}>✔</span>Today

            <div className={`float-left ${isSelected("today") ? "hidden" : "mr-2"}`} style={{height: "15px", width: "15px"}} onClick={() => handleOnClick("today")}>
              <svg style={{paddingBottom:"4px"}} xmlns="http://www.w3.org/2000/svg" viewBox="0.0 0 15 15">
                  <rect width="15" height="15" fill-opacity="0.0" style={{strokeWidth:4,stroke:"#88ddcd"}}></rect>
              </svg>
            </div>

          </li>
          <li ref={weekRef} className={`w-100 mt-1 ${isSelected("week")}`} onClick={() => handleOnClick("week")} >
            <span style={{color:"#61c4c6"}}className={isSelected("week") ? "mr-2" : "hidden"}>✔</span>This Week

            <div className={`float-left ${isSelected("week") ? "hidden" : "mr-2"}`} style={{height: "15px", width: "15px"}} onClick={() => handleOnClick("week")}>
              <svg style={{paddingBottom:"4px"}} xmlns="http://www.w3.org/2000/svg" viewBox="0.0 0 15 15">
                  <rect width="15" height="15" fill-opacity="0.0" style={{strokeWidth:4,stroke:"#88ddcd"}}></rect>
              </svg>
            </div>
          
          </li>
          <li class="title-text mt-1">Start Date:
            <DatePicker
              dateFormat="MMMM d, yyyy"
              selected={startDate}
              isClearable
              onChange={handleStartDateChange}
            />
            </li>
            <li className="date-separator mt-1">End Date:
                <DatePicker
                  dateFormat="MMMM d, yyyy"
                  selected={endDate}
                  isClearable
                  onChange={handleEndDateChange}
                />
          </li>
      </ul>
      }
    </>
  )
}

export default TimeFiltersV2;
