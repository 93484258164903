import React, { useContext } from 'react';
import { EventContext } from '../../contexts/EventContextContainer';
import InfiniteCircle from '../../../../assets/images/blue_infinite_circle.png'
import axios from "axios";

const BuyTokens = ({stripeCheckoutLink, userTrial}) => {
  const { sideBarOpen, tokensCount } = useContext(EventContext);

  const getStripeCheckout = () => {
    axios.get(`/checkout.json`, {
      params: {}
    }).then((response) => {
      location.href = response.data
    })
  }

  return (
    (userTrial || tokensCount == 0) && 
     <div onClick={getStripeCheckout} class={ sideBarOpen ? "navbar-item align-items-center buy-token-top-nav pr-3 pl-3 d-flex d-none" : "closed-side-bar align-items-center navbar-item buy-token-top-nav d-flex d-none"}>
        <img src={InfiniteCircle} alt={"infinite circle cost"}/> BUY TOKENS
     </div>
  )
}

export default BuyTokens;
