import React, { useEffect, useState } from 'react';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';

const SpeakerHistory = ({
  spoken_ats_hash: spoken_ats_hash
}) => {
  let colors = ["#41a8ac", "#9dccaf", "#e7dfb6"]
  let icons = ["fa-briefcase", "fa-microphone", "fa-plane"]
  
  return (
    <VerticalTimeline>
        {
            Object.keys(spoken_ats_hash).map(function (key, index) {
                return (
                    <VerticalTimelineElement
                        id={key + "_" + index} 
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: colors[index % 3], color: '#fff' }}
                        contentArrowStyle={{ borderRight: "7px solid  " + colors[index % 3] }}
                        date={key}
                        dateClassName="text-dark font-weight-bold"
                        iconStyle={{ background: colors[index % 3], color: '#fff', display: 'inline-block', textAlign: 'center' }}
                        icon={<i className={"fas " + icons[index % 3] + " fa-3x pt-1"}></i>}
                    >
                    {
                        spoken_ats_hash[key].map(function (spoken_at_obj, index){
                            return (
                                <div id={key + "_" + index + "_nested"}>
                                    <h3 className="vertical-timeline-element-title">{spoken_at_obj["org"]}</h3>
                                    <p>Current Event: <a className="text-light" style={{color: "white !important"}} href={"/events/" + spoken_at_obj["event_id"]}>{spoken_at_obj["event"]}</a></p>         
                                    <br></br>                         
                                </div>
                            )
                        })
                    }
                   
                </VerticalTimelineElement>
                )
            })
        }
        <VerticalTimelineElement
            id="star"
            iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff', display: 'inline-block', textAlign: 'center' }}
            icon={<i className="fas fa-star fa-3x pt-1"></i>}
        />
    </VerticalTimeline>
    );
}

export default SpeakerHistory;