import React, { useState, useContext } from 'react';
import { AdminContext } from '../../contexts/AdminContextContainer';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const AdminUserSelect = () => {

  const { handleUserListFilter, allAdminUsersList, adminUserList, setAdminUserList } = useContext(AdminContext);

  const handleUserSelectChange = (data) => {
    setAdminUserList(data);
    handleUserListFilter('custom', data);
  }

  return (
    <div style={{marginLeft: "40px"}}>
        <span style={{fontSize: "20px"}}>Admin Users:</span>
        <Select
        closeMenuOnSelect={false}
        components={animatedComponents}
        defaultValue={adminUserList}
        isMulti
        options={allAdminUsersList}
        onChange={handleUserSelectChange}
        />
    </div>
  )
}

export default AdminUserSelect;
