import React, { useContext, useState, useEffect } from 'react';
import Tokens from "../TokensNonEvents";
import InterestedEventCard from '../InterestedEventCard';
import NavBar from "../../Shared/NavBar";
import axios from "axios";
import FeelingConfused from '../../Shared/FeelingConfused';
import InfiniteScroll from "react-infinite-scroll-component";
import square from "../../../../assets/images/square.svg";
import headerLine from "../../../../assets/images/interested_events_header_line.svg";
import contact_now from "../../../../assets/images/email_green_fill.svg";
import contact_now_checked from "../../../../assets/images/email_sent_green_fill.svg";
import tficon from '../../../../assets/images/speaker_events_database.svg'
import EmailContextContainer from '../../contexts/EmailContextContainer';

const InterestedEvents = ({stripeCheckoutLink, userTrial, paidTrial, userName, userTier, numOrderedEvents, emailFeatureFlag}) => {
  const [ showPastEvents, setShowPastEvents] = useState(false);
  const [ interestedEvents, setInterestedEvents ] = useState([]);
  const [ orderedEvents, setOrderedEvents ] = useState([]);
  const [ emailedContacts, setEmailedContacts ] = useState([]);
  const [ page, setPage ] = useState(1)
  const [ interestedEventsCount, setInterestedEventsCount ] = useState(0);
  const [ orderedEventsCount, setOrderedEventsCount ] = useState(0);
  const [ emailedContactsCount, setEmailedContactsCount ] = useState(0);
  const [ initialFetch, setInitialFetch ] = useState(false)
  const [ loading, setLoading ] = useState(false);
  const isMobile = window.innerWidth < 992;
  const toggleShowPastEvents = (val) => {
    if (val != showPastEvents) {
      setShowPastEvents(!showPastEvents)
      setPage(1);
    }
  }

  const getInterestedEvents = () => {
    setLoading(true);
    axios.get(`/interested_events/interested_events_js.json?page=${page}`, {
      params: {}
    }).then((response) => {
      setLoading(false)
      setInterestedEvents(interestedEvents.concat(response.data.events));
      setInterestedEventsCount(response.data.total_count)
    })
  }

  const getOrderedEvents = () => {
    setLoading(true);
    axios.get(`/orders/order_events_js.json?page=${page}`, {
      params: {}
    }).then((response) => {
      setLoading(false)
      setOrderedEvents(orderedEvents.concat(response.data.events));
      setOrderedEventsCount(response.data.total_count);
    })
  }

  const getEmailedContacts = () => {
    setLoading(true);
    axios.get(`/orders/emailed_events_js.json?page=${page}`, {
      params: {}
    }).then((response) => { 
      setLoading(false)
      setEmailedContacts(emailedContacts.concat(response.data.events));
      setEmailedContactsCount(response.data.total_count);
    })
  }

  const handleInterestedClick = (interested, event_id) => {
    let promise = ""; 

    if ( interested ) {
      promise = axios.delete(`/events/not-interested_events/${event_id}.json`);
    }
      
    promise.then(({data}) => {
      getInterestedEvents()
    }) 
  }

  const exportAllContacts = () => {
    location.href = "/interested_events/interested_events_to_csv.csv";
  }

  const exportEventInfo = (event_id) => {
    location.href = "/interested_events/interested_event_to_csv.csv?event_id=" + event_id;
  }

  const orderAllEvents = () => {
    setLoading(true)
    axios.post(`/order_interested_events`, {
      params: {}
    }).then((response) => {
      setLoading(false)
      alert("You have successful ordered your events!");
      setInterestedEvents([]);
    })
  }

  if (!showPastEvents && interestedEvents.length == 0 && !initialFetch) {
    setInitialFetch(true);
    if (emailFeatureFlag) {
      getOrderedEvents();  
    } else {
      getInterestedEvents();
    }
  }

  if (showPastEvents && (orderedEvents.length == 0 || (emailFeatureFlag && emailedContacts.length == 0)) && !initialFetch) {
    setInitialFetch(true);
    if (emailFeatureFlag) {
      getEmailedContacts();
    } else {
      getOrderedEvents();
    }
  }

  const fetchMoreEvents = () => {
    setPage(page + 1)
  }

  const getHasMore = () => {
    if (showPastEvents && emailFeatureFlag) {
      (page * 10) < (showPastEvents ? emailedContactsCount : orderedEventsCount)
    } else if (!showPastEvents && emailFeatureFlag) { 
      (page * 10) < (showPastEvents ? orderedEventsCount : interestedEventsCount)
    }
  }

  useEffect(() => {
    if (emailFeatureFlag) {
      if (showPastEvents && (emailedContactsCount == 0 || (((emailedContactsCount / 10) + 1)>= page))) {
        getEmailedContacts()
      } else if(orderedEventsCount == 0 || (((orderedEventsCount / 10) + 1 >= page))) {
        getOrderedEvents();
      }
    } else {
      showPastEvents ? getOrderedEvents() : getInterestedEvents();
    }
  },[page, showPastEvents])

  return (
    <>
      <div className="profile-spacer">
        <Tokens stripeCheckoutLink={stripeCheckoutLink} userTrial={userTrial} paidTrail={paidTrial} eventsPage={false}/>
        <NavBar />
      </div>
      {
        !isMobile &&  
        <div className="profile-col">
            <div className="row text-center d-flex">
                <div className="col">
                    <a href="/events">
                      <img src={tficon} alt={"profile icon"} width={"250px"}/>
                    </a>
                    <div className="w-100 text-center name-header color-dark-blue mt-5">
                      {userName}
                    </div>
                    <div className="w-100 text-center tier color-dark-blue mt-3">
                      <span className="font-italic">Your Tier:</span><span className="font-weight-bold"> {userTier} speaker</span>
                    </div>
                    <div className="bottom-buttons text-center">
                      <span className="purchased-events font-weight-bold">{numOrderedEvents} purchased events</span>
                      <div className="mb-3 mt-3"><a className="my-purchase-events font-weight-bold" href="/interested_events?purchased_events=true">MY PURCHASED EVENTS</a></div>
                      <div className="mb-3"><a className="go-to-database font-weight-bold" href="/events">GO TO DATABASE</a></div>
                    </div>
                </div>
            </div>
        </div>
      }
      <div className="interested-events" style={{marginLeft: isMobile ? "0px" : "430px"}}>
        <div className="container">
          {
            isMobile ?
              <>
                <div className="row"> 
                  <div className="col-4"> 
                  <div className="w-100 text-center d-flex justify-content-center mt-4"> 
                    <img src={square} width="60px"/>
                  </div>
                  </div>
                  <div className="col-8"> 
                    <div className="color-dark-blue font-size-l font-weight-bold font-montserrat">
                      {`${userName}, here are your purchased events.`} 
                    </div>
                  </div>
                </div>
                <div className="row pl-3 pr-3 mt-3"> 
                  <img className="mb-3" src={headerLine} width={"100%"} />
                  <div className="col-3 d-flex align-items-center">
                    <a className="blue-button" onClick={exportAllContacts}>
                      <i class="fas fa-download font-size-m"/>
                    </a>
                  </div>
                  <div className="col-9">
                    <div className="row mt-1">
                      <div onClick={() => toggleShowPastEvents(false)} className={`${!showPastEvents ? "active" : ""} col-6 text-center font-weight-bold font-size-s d-flex align-items-center justify-content-center`}>
                        <div className="w-100 header">
                          <img src={contact_now} className="mr-2" width="30px"/>
                          <span className="title">New</span> 
                        </div>              
                      </div>
                      <div onClick={() => toggleShowPastEvents(true)} className={`${showPastEvents ? "active" : ""} col-6 text-center font-weight-bold font-size-s d-flex align-items-center justify-content-center`}>
                        <div className="header w-100">
                          <img src={contact_now_checked} className="mt-1 mb-1" width="35px"/>
                          <span className="title">Emailed</span> 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            :
            <div className="w-100 text-center d-flex justify-content-center"> 
              <img src={square} width="90px"/>
            </div>
          }
         
         {
           !isMobile &&
          <div className="color-dark-blue font-size-l font-weight-bold font-montserrat text-center mt-2">
              {`${userName}, here are your ${emailFeatureFlag ? "" : "interested and "} purchased events.`} 
          </div>
          }
          <div className="d-flex justify-content-center mt-3">
            {
              (showPastEvents || emailFeatureFlag) ? 
              <>
              {
                isMobile ?
                  <></>
                :
                <a className="blue-button" onClick={exportAllContacts}>
                  <i class="fas fa-download font-size-m"/><span style={{letterSpacing: "0.35px"}} className="ml-3 font-weight-bold">EXPORT ALL CONTACTS</span>
                </a>
              }
              </>
            : 
              <a className="checkout-button ml-3" onClick={orderAllEvents}>
                <i class="fas fa-shopping-cart"/><span className="ml-3">ORDER ALL</span>
              </a> 
            }
          </div>
          <div className={`row ${isMobile ? "mt-3" : ""}`}>
            <div className="w-100" style={{marginRight: isMobile ? "0px" : "160px", marginLeft: isMobile ? "0px" : "17px"}}>
              {
                !isMobile &&
                  <div className="row">
                    <div onClick={() => toggleShowPastEvents(false)} className={`${!showPastEvents ? "active" : ""} col-6 text-center font-weight-bold font-size-s mt-4  d-flex align-items-center justify-content-center`}>
                      <div className="w-100 header">
                        <img src={contact_now} className="mr-2" width="30px"/>
                        <span className="title">New Contacts</span> 
                      </div>              
                    </div>
                    <div onClick={() => toggleShowPastEvents(true)} className={`${showPastEvents ? "active" : ""} col-6 text-center font-weight-bold font-size-s mt-4  d-flex align-items-center justify-content-center`}>
                      <div className="header w-100">
                        <img src={contact_now_checked} className="mt-1 mb-1" width="35px"/>
                        <span className="title">Emailed Contacts</span> 
                      </div>
                    </div>
                  </div>
              }
            </div>
            <div className="ml-4 mr-4 mt-3 w-100"> 
              <InfiniteScroll
                key={232433451}
                dataLength={showPastEvents ? orderedEvents.length : interestedEvents.length}
                next={fetchMoreEvents}
                hasMore={getHasMore}
                loader={<span></span>}
                style={{overflow:"unset"}}
              >
                {
                  loading &&
                    <div className="loader-wrap">
                      <div className="spinner-border text-light" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                }
                {
                  !showPastEvents && interestedEvents.length == 0 && !emailFeatureFlag &&
                    <div className="w-100 text-center color-dark-blue font-size-l font-weight-bold font-montserrat text-center mt-2">
                      Currently you have no interested events.
                    </div>    
                }
                {
                  ((!showPastEvents && emailFeatureFlag && orderedEvents.length == 0)) &&
                  <div className="w-100 text-center color-dark-blue font-size-l font-weight-bold font-montserrat text-center mt-2">
                    Currently you have no ordered events.
                  </div>
                }
                {
                  (!showPastEvents && !emailFeatureFlag) && interestedEvents.map((event, index) => (
                    <React.Fragment key={event.id}>
                      <EmailContextContainer>
                        <InterestedEventCard key={event.id} event={event} handleInterestedClick={handleInterestedClick} index={(index + 1)} showPastEvents={showPastEvents} emailFeatureFlag={emailFeatureFlag}/>
                      </EmailContextContainer>
                    </React.Fragment>
                  ))
                }
                {
                  ((showPastEvents && !emailFeatureFlag) || (emailFeatureFlag && !showPastEvents)) && orderedEvents.map((event, index) => (
                    <React.Fragment key={event.id}>
                      <EmailContextContainer>
                        <InterestedEventCard key={event.id} event={event} handleInterestedClick={exportEventInfo} index={(index + 1)} showPastEvents={showPastEvents} emailFeatureFlag={emailFeatureFlag}/>
                      </EmailContextContainer>
                    </React.Fragment>
                  ))
                }
                {
                  showPastEvents && emailFeatureFlag && emailedContacts.map((event, index) => (
                    <React.Fragment key={event.id}>
                      <EmailContextContainer>
                        <InterestedEventCard key={event.id} event={event} handleInterestedClick={exportEventInfo} index={(index + 1)} showPastEvents={showPastEvents} emailFeatureFlag={emailFeatureFlag}/>
                      </EmailContextContainer>
                    </React.Fragment>
                  ))
                }
                {
                  showPastEvents && emailFeatureFlag && emailedContacts.length == 0 && 
                    <div className="w-100 text-center color-dark-blue font-size-l font-weight-bold font-montserrat text-center mt-2">
                      Currently you have not emailed any events.
                    </div>    
                }
              </InfiniteScroll>
            </div>
          </div>
        </div> 
      </div>
      <FeelingConfused userTrial={userTrial}/> 
    </>
  )
}

export default InterestedEvents;