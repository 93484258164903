import React, { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown'
import ProfileIcon from '../../../assets/images/profileicon.png'

const ProfileDropDown = ({
  username,
  user_email,
  account_type,
  subscription_type,
  days_left, 
  days_plural, 
  stripe_connection, 
  annual_subscription_ends, 
  avatar_url, 
  paidTrial
}) => {
  return (
    <Dropdown className="btn-account font-weight-bold">
        <Dropdown.Toggle 
            className="" 
            style={{fontWeight: 600, backgroundColor: "#f9fcf5", color: "#194f57", border: "none"}} 
            variant="secondary" 
            id="dropdown-basic">
            <span href="/profile">{username}</span> &nbsp; 
            <div className="dropdown-wrapper" style={{ height: '50px', width: '50px' }}> 
                <img style={{height: "50px", width: "50px"}} className="rounded-circle" src={avatar_url ? avatar_url : ProfileIcon} alt={"profile icon"}/>
            </div> 
        </Dropdown.Toggle>

        <Dropdown.Menu variant="dark" style={{backgroundColor: '#f5f5f5'}}>
            <span className="dropdown-item" >
                <a style={{textDecoration: 'none'}} className="" href="/profile">{"User: " + user_email}</a>
            </span>
            <Dropdown.Item href="/profile" data-method="get">Edit Profile</Dropdown.Item>
            <Dropdown.Item href="/events" data-method="get">Events Database</Dropdown.Item>
            <Dropdown.Divider />
            { 
                (subscription_type && account_type != "user_trial") && 
                <span>
                    <Dropdown.Item><span className="font-weight-bold">{days_left + " " + days_plural}</span>{" Left Until Credit Renewal"}</Dropdown.Item>
                    <span className="dropdown-item">
                        {"Subscription Type: " + subscription_type}
                    </span>
                </span>
            }
            { 
                (subscription_type && account_type != "user_trial" && subscription_type == "Annual") && 
                <span>
                    <Dropdown.Item><span>{"Subscription Ends: " + annual_subscription_ends}</span></Dropdown.Item>
                </span>
            }
            {
                paidTrial &&  
                <span>
                    <Dropdown.Item><span>{"Trial Days Left: " + days_left + " " + days_plural}</span></Dropdown.Item>
                </span>
            }
            {
                account_type && 
                <span id="test2" className="dropdown-item">
                    {"Account Type: " + account_type + " Version"}
                </span>
            }
            <Dropdown.Divider />
            <Dropdown.Item href="/auth/password/new" data-method="get">Reset Password</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item href="/auth/sign_out" data-method="delete">Sign Out</Dropdown.Item>
            
        </Dropdown.Menu>
    </Dropdown>
    );
}

export default ProfileDropDown;
