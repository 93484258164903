import React, { useState, useEffect } from 'react';
import { convertFilters } from "../utils/helperFunctions";
import axios from "axios";

const OrganizationContext = React.createContext({});
export { OrganizationContext };

const OrganizationContextContainer = (props) => {
  const [organizations, setOrganizations] = useState([]);
  const [page, setPage] = useState(1);
  const [organiztionFilters, setOrganiztionFilters] = useState({});

  useEffect(() => {
    axios.get(`/organizations/organizations_list?page=${page}`, {
      params: convertFilters(organiztionFilters)
    })
    .then((response) =>
      setOrganizations(response.data.organizations))
  }, [page, organiztionFilters]);

  const handleFilterClick = (value, filter, event) => {
    let filters = {...organiztionFilters}
    filters[filter] = filters[filter] || []

    if(event.target.checked)
      filters[filter].push(value);
    else{
      let index = filters[filter].indexOf(value);
      filters[filter].splice(index, 1);
    }

    setPage(1)
    setOrganiztionFilters(filters)
  }

  return (
    <OrganizationContext.Provider
      value={{
        organizations,
        page,
        setPage,
        handleFilterClick,
        organiztionFilters
      }}
    >
      { props.children }
    </OrganizationContext.Provider>
  )
}

export default OrganizationContextContainer;
