import React, { useState, useContext } from 'react';
import axios from "axios";
import { Modal } from 'react-bootstrap';
import 'react-image-crop/dist/ReactCrop.css';
import { EventContext } from '../../contexts/EventContextContainer';


const ZeroTokensLeft = ({validUser, paidTrial, userTrial}) => {
  const [show, setShow] = useState(false)
  const {onboardingFlow, changeOnboardingFlow, showZeroTokensModal, hideZeroTokensModal} = useContext(EventContext)
  const isMobile = window.innerWidth < 922;

  const handleClose = () => {
    setShow(false);
  }

  const getCheckoutUrl = () => {
    axios.get(`/profiles/subscription_session_js.json`, {
      params: {}
    }).then((response) => {
      location.href = response.data;
    })
  }

  const getStripeCheckout = (type) => {
    axios.get(`/checkout.json?type=${type}`, {
      params: {}
    }).then((response) => {
      location.href = response.data
    })
  }

  return ( 
      <Modal size={isMobile ? "sm" : "m"} show={showZeroTokensModal} onHide={hideZeroTokensModal} keyboard={false}>
        <Modal.Header closeButton>
        </Modal.Header>
          <Modal.Body>
            <div>
              <div className="font-montserrat text-center font-weight-bold color-light-black font-size-l mb-3 text-center">
                  {
                      !userTrial &&
                        <>Looks like you are all out of tokens.</>
                  }
                  {
                      userTrial && 
                      <> Looks like you haven't started your trial yet</>
                  }
             </div>
              <div className="font-montserrat p-3 text-center">
                  {
                      paidTrial && 
                      <> Upgrade your accout to get 50 tokens a month. You can order additional tokens at a discounted price!</>
                  }
                  {
                      userTrial && 
                      <>Get event contact info instantly by starting your $7 trial today!</>
                  }
                  {
                      !paidTrial && !userTrial && 
                      <>Order 10 more tokens for $7 before the month ends</>
                  } 
              </div>
            </div>
          </Modal.Body>
            
          <Modal.Footer className="border-top-0 justify-content-center">
            {
                userTrial && 
                    <a onClick={getStripeCheckout} className="green-button pl-4 pr-4">
                        Start Trial
                    </a> 
            }
            {
                paidTrial &&
                    <div className="row d-flex justify-content-center"> 
                        <a onClick={() => getStripeCheckout("user_starter")} className="green-button pl-4 pr-4 mt-2">
                            Pro Bono Events
                        </a> 
                        <a onClick={() => getStripeCheckout("user_explorer")} className="green-button pl-4 pr-4 mt-2">
                            $5,000 and Under Events
                        </a> 
                        <a onClick={() => getStripeCheckout("user_veteran")} className="green-button pl-4 pr-4 mt-2">
                            $15,000 and Under Events
                        </a> 
                        <a onClick={() => getStripeCheckout("user_pro")} className="green-button pl-4 pr-4 mt-2">
                            $15,000 and Beyond Events
                        </a> 
                    </div>
                    
            }
            {
                !userTrial && !paidTrial &&
                    <a onClick={getStripeCheckout} className="green-button pl-4 pr-4">
                        Order 10 Tokens
                    </a> 
            }
          </Modal.Footer>
      </Modal>  
  )
}

export default ZeroTokensLeft;
