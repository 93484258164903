import React, { useState } from 'react';
import axios from "axios";
import { Modal } from 'react-bootstrap';
import 'react-image-crop/dist/ReactCrop.css';


const ValidUserModal = ({validUser, failedPayment, paidTrial}) => {
  const [show, setShow] = useState(false)

  const isMobile = window.innerWidth < 922;

  const handleClose = () => {
    setShow(false);
  }

  const getStripeCheckout = (type) => {
    axios.get(`/checkout.json?type=${type}`, {
      params: {}
    }).then((response) => {
      location.href = response.data
    })
  }

  return ( 
      <Modal size={isMobile ? "sm" : "m"} show={!validUser} backdrop="static" onHide={handleClose} keyboard={false}>
        <Modal.Header>
        </Modal.Header>
          <Modal.Body>
            <div>
              <div className="text-center font-weight-bold color-light-black font-size-l mb-3">
                {
                  failedPayment && 
                  <>Our Records Show That Your Account Has Not Paid.</>
                }
                {
                  paidTrial && !validUser &&
                  <>We Hope You Enjoyed Your Trial With Us!</>
                }
                
              
              </div>
              <div className="p-3">
                {
                  failedPayment && <>If you believe this is an error, please email us at support@trinityfix.com.</>
                }
                     
              </div>
            </div>
          </Modal.Body>
            
          <Modal.Footer className="border-top-0 justify-content-center">
          
          {
              
              paidTrial ?
                  <>
                    <div className="text-center font-weight-bold color-light-black font-size-m mb-3">Upgrade Your Subscription By Choosing One Of the Following Options.</div>
                    <div className="row d-flex justify-content-center"> 
                        <a onClick={() => getStripeCheckout("user_starter")} className="green-button pl-4 pr-4 mt-2">
                            Pro Bono Events
                        </a> 
                        <a onClick={() => getStripeCheckout("user_explorer")} className="green-button pl-4 pr-4 mt-2">
                            $5,000 and Under Events
                        </a> 
                        <a onClick={() => getStripeCheckout("user_veteran")} className="green-button pl-4 pr-4 mt-2">
                            $15,000 and Under Events
                        </a> 
                        <a onClick={() => getStripeCheckout("user_pro")} className="green-button pl-4 pr-4 mt-2">
                            $15,000 and Beyond Events
                        </a> 
                    </div>
                  </>
                  
          :
            <a onClick={() => getCheckoutUrl()} className="green-button pl-4 pr-4" href="">
              View Account
            </a>
            
          }
          <div className="mt-4">
            <div className="text-center font-weight-bold color-light-black font-size-m mb-3">Note, you can still download your event information by going to the Ordered Events page and view free events.</div>
            <div className={isMobile ? "" : "d-flex justify-content-center"}>
              <div className={isMobile ? "w-100 text-center mb-3" : ""}>
                <a href="/interested_events" className="green-button-medium pl-4 pr-4">
                      Ordered Events
                </a> 
              </div>
              <div className={isMobile ? "w-100 text-center" : ""}>
                <a href="/?newsletter_events=true" className="ml-2 green-button-medium pl-4 pr-4">
                      Free Events
                </a> 
              </div>
            </div>
          </div>
          </Modal.Footer>
      </Modal>  
  )
}

export default ValidUserModal;
