import React, { useState, useEffect, useContext } from 'react';
import { Modal } from "react-bootstrap";
import { EventContext } from '../../../contexts/EventContextContainer';
import star from "../../../../../assets/images/star.svg"
import AvatarProfileIcon from '../../../../../assets/images/contact_name_icon.svg';
import InfiniteCircle from '../../../../../assets/images/infinite_circle.svg'

const ShowEventDetails = ({event, handleOnClick, newsletter}) => {
    const { handleSendEmail, showEventDetail, showEventDetailId, toggleShowEventDetailsModal } = useContext(EventContext);

    const isMobile = window.innerWidth < 992;
    return (
        <>
            <Modal backdropClassName="show-events-backdrop" size={isMobile ? "sm" : "xl"} className="" show={event.id == showEventDetailId && showEventDetail} onHide={toggleShowEventDetailsModal}>
              <Modal.Header style={{padding: "0px", zIndex: 2000}} closeButton/>
                <Modal.Body style={{paddingRight: isMobile ? "0px" : "100px", paddingLeft: isMobile ? "0px" : "100px"}}>
                  <div className={`modal-wrapper w-100 h-100 positive-relative ${isMobile ? "p-2" : ""}`} style={{overflowY: "scroll", overflowX: isMobile ? "visible" : "hidden", height: "100%", maxHeight: "666px", scrollbarColor: "#5cb385 white"}}>
                    {
                        !isMobile && <div style={{backgroundColor: "#5cb385", width: "20px", height: "100px", top: "0px"}} className="position-absolute"/>
                    }
                    <div className="event-modal-wrapper font-montserrat ml-5">
                        <div className="font-size-xl color-dark-green">event info</div>
                        <h2 className="title_date font-italic">{event.event_date}</h2>
                        <div className="font-weight-bold font-size-l color-light-black">{event.name}</div>
                        <div className="d-flex">
                            <p className="font-weight-bold font-size-s color-light-black">{event.city}, {event.state}</p>
                            <span className="ml-2 font-weight-bold font-size-s color-dark-green">ESTIMATED BUDGET: {event.budget_range}</span>
                        </div>
                        <div className="row">
                            <div className="col-md-8">
                                <p className="tags_p">
                                {
                                    event.event_industrys.map((event_industry, index) => (
                                    <span key={index} style={{backgroundColor: "unset", borderRadius: "15px", border: "1px solid #5cb385", cursor: "default"}} className="tag_event_modal">
                                        <span style={{fontSize: "12px"}} className="font-italic text-decoration-none color-dark-green">
                                        {event_industry.name}
                                        </span>
                                    </span>
                                    ))
                                }
                                </p>
                            </div>
                        </div>
                        <div style={{fontSize: "18px"}} className="mt-3 font-weight-bold color-light-black">Official link:</div>
                        <a href={event.website} target="_blank" style={{fontSize: "18px", textDecorationLine: "underline", textDecorationThickness: "2px"}} className="font-weight-bold color-bright-green">{event.website}</a>
                        <div className="mt-3 d-flex">
                            <img className="star-image" src={star} alt="Professional Speakers" width="35px"/>
                            <span class="font-weight-bold lead font-size-l color-dark-blue">{event.speaker_count}</span>
                        </div>
                        <div style={{marginLeft: "35px", top: "-7px"}} className="position-relative font-size-xxs font-weight-bold color-light-black font-size-xxs">PRO SPEAKER(S)</div>
                        <div className="mt-3">
                            {
                                event.speakers && event.speakers.map((speaker, index) => (
                                    <div style={{letterSpacing: "0.35px"}} className="position-relative mt-2">
                                        <img style={{left: "-53px"}}  className="star-image position-absolute" src={star} alt="Professional Speakers" width="45px"/>
                                        <div style={{fontSize: "16px"}} className="color-light-black font-weight-bold event_speaker">{speaker.name} | Year: {speaker.year}</div>
                                        <div className="font-size-s">{speaker.tagline}</div>
                                        <div id="test" className="font-size-s">{speaker.speaker_types}</div>
                                        <div className="font-size-s">Hired Around {speaker.fee}</div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="row mt-5"> 
                            <div className="col-md-6 position-relative">
                                <div className="contact-info-newsletter color-beige font-montserrat">
                                    <p className="contact-info-newsletter color-beige font-montserrat desc_ font-weight-bold">
                                        <img className="position-absolute" src={AvatarProfileIcon} style={{left: "-29px", marginLeft: "-5px", top: "-5px"}} width="35px"/>
                                        {
                                            (event.interested || event.ordered) ? event.event_contact_name : "Contact Name" 
                                        }
                                    </p>
                                    {
                                    (event.interested || event.ordered) && event.event_contact_title && 
                                        <>
                                        {
                                        event.event_contact_title
                                        }{"\n"}
                                        </>
                                    }
                                    {
                                    (event.interested || event.ordered) && event.event_organization
                                    }
                                </div>
                                <div className="contact-info-newsletter color-beige font-montserrat font-weight-bold">
                                    <p className="contact-info-newsletter color-beige desc_"><i style={{fontSize: "22px", left: "-28px"}} className='fas fa-envelope position-absolute'></i> 
                                    { (event.interested || event.ordered) ? event.event_contact_email_unmasked : event.event_contact_email }
                                    </p>{"\n"}
                                </div>
                                {
                                    event.event_contact_phone_unmasked && event.event_contact_phone_unmasked.length > 1 && 
                                    <div className="contact-info-newsletter font-weight-bold font-montserrat color-beige">
                                        <p className="contact-info-newsletter color-beige font-montserrat desc_">
                                        <i style={{fontSize: "22px", left: "-28px"}} className='position-absolute fas fa-flip fa-phone'></i>
                                            { (event.interested || event.ordered) ? event.event_contact_phone_unmasked : event.event_contact_phone }
                                        </p>
                                    </div>
                                } 
                            </div>
                            <div className="col-md-6"> 
                                <div style={{borderRadius: "20px", border: "2px solid #00c89d", maxWidth: "277px", width: "100%", letterSpacing: "0.45px"}} className="p-3 text-center mb-4"> 
                                    <div style={{fontSize: "18px"}} className="color-beige">{(event.interested || event.ordered) ? "Contact the Event!" : "Do you like the event?"}</div>
                                    <div style={{fontSize: "18px"}} className="color-beige font-weight-bold mb-2">{(event.interested || event.ordered) ? "" : "Reveal contact info"}</div>
                                    <div
                                        onClick={() => handleOnClick() }
                                        id={event.id}
                                        className={`text-nowrap mt-2 mb-2 ${(event.interested || event.ordered) ? "green-button-medium" : "beige-button" }`}
                                    >
                                    {(event.ordered || event.interested) ? "EMAIL CONTACT" : "GET CONTACT INFO"} 
                                    </div>
                                    <div className="mt-2 d-inline-block">
                                        <img className="d-inline" src={InfiniteCircle} alt={"infinite circle cost"} width="30px"/>
                                        <div className="d-inline color-beige font-weight-bold font-size-s">{(event.interested || event.ordered) ? 'Email Event Planner' : `COST: ${newsletter ? 0 : 1}`}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>  
                  </div> 
                </Modal.Body>
                  
                <Modal.Footer className="border-top-0 justify-content-center">
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ShowEventDetails;