import React from 'react';
import { convertToBudgetRange } from '../../utils/helperFunctions';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import Tooltip from '@material-ui/core/Tooltip';
import Typography from "@material-ui/core/Typography";
import moment from 'moment';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9"
  }
}))(Tooltip);

const EventCard = ({event, handleInterestedClick}) => {
  let speaker_plural = (event.speaker_count == 1) ? "Speaker" : "Speakers"
  return (
    <div className="card">
      <div className="card-body row">
        <div className="col-md-9">
            <h2 className="title_date">
              {event.event_date}
            </h2>
            <a href={`/events/${event.id}`} className="title_">{event.name}</a>
            { event.city != "some city" ?
              <p className="loc_">{event.city}, {event.state}</p>
            :
              ""
            }

            <p className="desc_">
            </p>


            <p className="no-margin tags_p">
              {
                event.event_industrys.map((event_industry, index) => (
                  <span className="tag_">
                    <a className="text-secondary">
                      {event_industry.name}
                    </a>
                  </span>
                ))
              }
            </p>
            {
              event.trialUser ?
                <p> Paying Users Can See Unmasked Information </p>
              :
                ""
            }
          </div>
          
            <div className="col-md-3">
              { event.in_newsletter ?

                <HtmlTooltip title={
                  <React.Fragment>
                    {event.event_contact_email}
                  </React.Fragment>
                    }>
                  <Button className="contact-info">
                    <p className="contact-info desc_ mt-1"><i className='fas fa-envelope mr-1'></i> {event.event_contact_email}</p>{"\n"}
                  </Button>
                </HtmlTooltip>

              : 
              <HtmlTooltip title={
                <React.Fragment>
                  {"To get contact information, click on the "}<b>{"interested"}</b>{" button and it will be included on your next order"}
                </React.Fragment>
                  }>
                <Button className="contact-info">
                  <p className="contact-info desc_ mt-1"><i className='fas fa-envelope mr-1'></i> {event.event_contact_email}</p>{"\n"}
                </Button>
              </HtmlTooltip>
              }
              { 
                !event.in_newsletter ?
                <HtmlTooltip title={
                      <React.Fragment>
                        {"To get contact information, click on the "}<b>{"interested"}</b>{" button and it will be included on your next order"}
                      </React.Fragment>
                    }>
                  <Button>
                    <p className="contact-info desc_"><i className='fas fa-phone mr-1'></i> {event.event_contact_phone}</p>
                  </Button>
                </HtmlTooltip>
              :
              <HtmlTooltip title={
                <React.Fragment>
                  {event.event_contact_phone}
                </React.Fragment>
              }>
                <Button>
                  <p className="contact-info desc_"><i className='fas fa-phone mr-1'></i> {event.event_contact_phone}</p>
                </Button>
             </HtmlTooltip>
            }
            </div>
        </div>
        <div className="card-footer cf_ ">
          <span>
            <button
              onClick={() => handleInterestedClick(event.id, event.interested, event.ordered)}
              id={event.id}
              className={`outlineBtn ${event.interested ? "interested" : ""}`}
            >
            <i className="fas fa-star"></i> &nbsp;interested
            </button> &nbsp; &nbsp;
            <HtmlTooltip title={
                  <React.Fragment>
                    {"Estimated budgets are determined by past speakers, industry and spending habits. The estimated budget is geared towards paying spots at events like headliners or keynotes."}
                  </React.Fragment>
                }>
              <Button className="estimated-budget">
                <span className="nowrap">
                <i className="fas fa-bolt _bolt"></i>
                {` Estimated Budget: ${event.budget_range} `} &nbsp; &nbsp;
                </span>
              </Button>
            </HtmlTooltip>
          <HtmlTooltip title={
                <React.Fragment>
                  {"This number pertains to the current event and past events that this organization has held and how many professional speakers were found there."}
                </React.Fragment>
              }>
            <Button>
              <button className="greenBtn">
                &nbsp; <span class="font-weight-bold lead">{event.speaker_count}</span> Professional {speaker_plural} 
              </button>
            </Button>
          </HtmlTooltip>
        </span>
      </div>
    </div>
  )
}

export default EventCard;
