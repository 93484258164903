import React, { useContext, useState } from 'react';
import { EventContext } from '../../contexts/EventContextContainer';
import { Button, Modal } from 'react-bootstrap';
import blueCheckMark from "../../../../assets/images/blue_checkmark.png";

const SpeakerTypeFilter = ({active, setActive, speakerTypes, handleFilterClick, currentFilters, toggleSpeakerFilter}) => {
  const handleClose = () => {
    toggleSpeakerFilter();
    setActive(false);
  }

  const handleOnSubmit = () => {
    toggleSpeakerFilter();
    setActive(false);
  }

  const isChecked = value =>
    currentFilters.speaker_type_id && currentFilters.speaker_type_id.includes(value)

  const isMobile = window.innerWidth < 992;
  
  return (
    <div>
        {
            active && 
            <>
                <div className="industry-filter">
                    <Modal dialogClassName="industry-filter-modal" show={active} onHide={handleClose} backdrop="static">
                    <Modal.Header className="text-center d-block border-bottom-0" closeButton>
                        <Modal.Title className="industry-modal-header text-center font-weight-bold d-block">Speaker Types</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={`d-flex ${isMobile ? "" : "mr-3 ml-3"}`} style={{flexWrap: 'wrap'}}>
                        <div className="row">
                        {
                            speakerTypes.map((speakerType, index) => (
                                <div className={`industry-filter-box ${isMobile ? "col-6" : "col-4"} mb-1 pr-1 pl-1`} key={index}>
                                    <span>
                                        {
                                            isMobile ? 
                                                <>
                                                    {
                                                        (!isChecked(speakerType[0])) ? 
                                                        <>
                                                            <div className="float-left" style={{height: "15px", width: "15px"}} onClick={(event) => handleFilterClick(speakerType[0], 'speaker_type_id', event)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0.0 0 15 15">
                                                                    <rect width="15" height="15" fill-opacity="0.0" style={{strokeWidth:4,stroke:"#88ddcd"}}></rect>
                                                                </svg>
                                                            </div>
                                                        </>
                                                        :
                                                        <span style={{color:"#61c4c6"}} onClick={(event) => handleFilterClick(speakerType[0][0], 'speaker_type_id', event)} className={isChecked(speakerType[0]) ? "float-left" : "hidden"}><img src={blueCheckMark} alt="blue check mark"/></span>
                                                    }
                                                </>
                                            :
                                            <span style={{color:"#61c4c6"}} className={isChecked(speakerType[0]) ? "float-left position-absolute" : "hidden"}><img src={blueCheckMark} alt="blue check mark"/></span>
                                        }
                                        <span
                                            id={speakerType[0][0]}
                                            onClick={(event) => handleFilterClick(speakerType[0], 'speaker_type_id', event)}
                                            className={isChecked(speakerType[0]) ? `${isMobile ? "ml-1" : "ml-4 text-nowrap"} checkmark-text` : `${isMobile ? "ml-1" : "ml-4 text-nowrap"}`}
                                        >{speakerType[1]}
                                        </span>
                                    </span>                            
                                </div>
                            ))
                        }
                        </div>
                    </Modal.Body>
                    
                    <Modal.Footer className="border-top-0 justify-content-center">
                        <a className="checkout-button pl-4 pr-4" onClick={handleOnSubmit}>
                            Filter
                        </a>
                    </Modal.Footer>
                    </Modal>
                </div>
            </>
        }
    </div>
  )
}

export default SpeakerTypeFilter;
