import React, { useEffect, useState } from 'react';

import SideBar from "../Filters/SideBar";
import TimeFilters from "../Filters/TimeFilters";
import FilterTags from "../Filters/FilterTags";
import EventList from "./EventList";
import TrialEventPrompt from "./TrialEventPrompt";
import SelectAll from "./SelectAll";
import SaveSearch from "./SaveSearch";
import EventContextContainer from '../contexts/EventContextContainer';

const Events = ({
  eventBudgets,
  eventIndustries,
  eventCities,
  query, 
  trialUser,
  saved_search, 
  userName
}) => {
  return (
    <>
      <EventContextContainer>
        <FilterTags
          eventBudgets={eventBudgets}
          eventCities={eventCities}
          eventIndustries={eventIndustries}
        />
        <TimeFilters/>

        <div className="d-flex">
          <SelectAll/>
          <SaveSearch/>
        </div>

        <div className="row">
          <div className="col-md-3">
            <SideBar
              eventBudgets={eventBudgets}
              eventCities={eventCities}
              eventIndustries={eventIndustries}
            />
          </div>

          <div className="col-md-9">
            {
              trialUser && saved_search && 
              <TrialEventPrompt trial={trialUser} userName={userName} savedSearch={saved_search} /> 
            }
            <EventList query={query}/>
          </div>
        </div>
      </EventContextContainer>
    </>
  );
}

export default Events;
