import React from 'react';
import axios from "axios";

const TrialEventPrompt = ({trial, userName, savedSearch, stripeCheckoutLink}) => {
  
  const getStripeCheckout = () => {
    axios.get(`/checkout.json`, {
      params: {}
    }).then((response) => {
      location.href = response.data
    })
  }

  return (
    <>
    { 
      trial && savedSearch && (
        <>
          <div className="trial-header mb-2"> 
            {userName}, here are your results.
            <br/> 
            We've found the right events for you.
          </div>
          <div className="trial-subheader mb-2"> 
            Unlock the contact info 
            <br/> 
            for your first 10 events for only 
            <span className="trial-header">&nbsp;$7</span>
          </div>
          <div className="text-center mb-5 mt-3 d-none"> 
            <a class="greenBtn trial-offer-button mb-5" onClick={getStripeCheckout}><i class='fas fa-coins'></i>&nbsp;Buy Tokens</a>
          </div>
        </>
      )
    }
    </>
  )
}

export default TrialEventPrompt;
