import React, { useEffect, useState } from 'react';
import Background from "../../../../assets/images/trinityfix_background_stage.jpg";
import axios from "axios";

const isMobile = window.innerWidth < 992;

const FreeAnswerQuestion = ({questionNum, text, setFunction, _handleSubmit, errorMessage, answer, wheel, email}) => {
    return (
        <div id={`question-${questionNum}`} className={`question-wrapper d-inline-block"`} style={{paddingBottom: `${innerHeight/3}px`}} onWheel = {(e) => wheel(e)}>
            <div className="font-size-s color-white d-inline" style={{marginTop: "8px"}}>
                <label className="mb-1">
                    {questionNum}
                    <i className="fas fa-arrow-alt-right ml-1"/>
                </label>
            </div> 
            <div className="ml-3 font-size-l font-montserrat color-white d-inline">{text}</div>
            <input 
                style={{backgroundColor: "transparent" }} 
                className={`${isMobile ? "" : "ml-4"} color-white font-size-xl w-100 border-top-0 border-left-0 border-right-0 font-montserrat`} 
                type={email ? "email" : "text"} 
                placeholder={email ? "Type your email here..." : "Type your answer here..."}
                value={answer}
                onChange={(event)=>{
                    setFunction(email ? event.target.value.trim() : event.target.value);
                }}>
            </input>
            <div className="mt-3" style={{marginLeft: "40px"}}> 
                <button 
                    style={{backgroundColor: "rgb(251, 251, 251)", fontSize: "16px", padding: "6px 14px", borderRadius: "4px", color: "rgb(124, 124, 124)", minHeight: "40px", boxShadow: "rgba(0, 0, 0, 0.1) 0px 3px 12px 0px"}}
                    className="font-weight-bold d-inline"
                    onClick={() => _handleSubmit()}
                    >
                    <span>OK <i class="fas fa-check"></i></span>
                </button>
                <div className="ml-2 color-white font-size-xs font-montserrat d-inline question-2"> 
                    press <strong>Enter ↵</strong>
                </div>
                <div id={`error-${questionNum}`} className="p-2 hidden" style={{backgroundColor: "#f7e6e6", color: "red", borderRadius: "2px", width: "200px"}}>
                    <i className="far fa-exclamation-triangle"></i>
                    <div className="ml-2 d-inline">{errorMessage}</div>
                </div>
            </div>
        </div>
    )
}

const MultipleChoice = ({questionNum, wheel, options, text, _handleQuestion, _handleSubmit, errorMessage, setAnswer, handleMultipleQuestion}) => {
    return (
        <div id={`question-${questionNum}`} onWheel = {(e) => wheel(e)} className={`question-wrapper`} style={{paddingBottom: `${innerHeight/3}px`, color: "white"}}> 
                    <div className="font-size-s color-white d-inline" style={{marginTop: "8px"}}>
                        <label className="mb-1">
                            {questionNum}
                            <i className="fas fa-arrow-alt-right ml-1"/>
                        </label>
                    </div> 
                    <div className="ml-3 font-size-l font-montserrat color-white d-inline">{text}</div>
                    <div className={`${!isMobile && options.length > 6 && 'd-flex flex-wrap '} ${isMobile ? "" : "ml-5"} multiple-choice-wrapper w-100 ${(isMobile && options.length > 6) ? "mt-4" : "mt-3"}`}
                        style={{height: `${(options.length > 6 && isMobile)} ? options.length * 50 + "px" : "unset"}`}}>
                        {
                            options.map((element, index) => ( 
                                <div style={{width: options.length > 6 ? "350px" : "270px", borderRadius: "3px", padding: "10px", outline: "1px solid white", cursor: "pointer", "&:hover": "rgba(255, 255, 255, 0.3)"}} 
                                    className={`question-${questionNum} multiple-choice-option cursor-pointer mb-2 d-flex align-items-center ${options.length > 6 ? "mr-2" : ""}`}
                                    onClick={() => handleMultipleQuestion ? handleMultipleQuestion(index, element) : _handleQuestion(index, questionNum, options, setAnswer)}
                                    id={`question-${questionNum}-${index}`}>
                                    <div 
                                        style={{outline: "1px solid white", width: "26px", height: "26px", borderRadius: "3px", cursor: "pointer"}} 
                                        className="font-montserrat text-center p-1 font-size-xs font-weight-bold d-inline">{index + 1}</div>
                                        <div className="d-inline ml-2 font-montserrat font-size-m">{element}</div>
                                        <div id={`question-${questionNum}-${index}-icon`} className={`ml-2 question-${questionNum}-icon hidden`}><i class="fas fa-check float-right"></i></div>
                                </div>
                            ))
                        }
                    </div>
                    <div 
                        className={`${(isMobile && options.length > 6) ? "" : "mt-3"} ${!isMobile && "inline-block"}`} 
                        style={{marginTop: (isMobile && options.length > 6) ? "unset" : "", marginLeft: "40px"}}> 
                        <button 
                            style={{backgroundColor: "rgb(251, 251, 251)", fontSize: "16px", padding: "6px 14px", borderRadius: "4px", color: "rgb(124, 124, 124)", minHeight: "40px", boxShadow: "rgba(0, 0, 0, 0.1) 0px 3px 12px 0px"}}
                            className="font-weight-bold d-inline"
                            onClick={() => _handleSubmit()}
                            >
                            <span>OK <i class="fas fa-check"></i></span>
                        </button>
                        <div className={`ml-2 color-white font-size-xs font-montserrat d-inline question-${questionNum}`}> 
                            press <strong>Enter ↵</strong>
                        </div>
                        <div id={`error-${questionNum}`} className="p-2 hidden" style={{backgroundColor: "#f7e6e6", color: "red", borderRadius: "2px", width: "200px"}}>
                            <i className="far fa-exclamation-triangle"></i>
                            <div className="ml-2 d-inline">{errorMessage}</div>
                        </div>
                    </div>
                </div>
    )
}

const SpeakerApplication = ({
    
}) => {
    const [focusIndex, setFocusIndex] = useState(1)
    const [question1Answer, setQuestion1Answer] = useState("");
    const question2_options = ["Just Starting", "1-2 years", "3-7 years", "7+ years"]
    const [question2Answer, setQuestion2Answer] = useState("")
    const question3_options = ["I don't charge currently", "Under $5,000", "$5,000-$10,000", "$15,000 Upwards"]
    const [question3Answer, setQuestion3Answer] = useState("")
    const [question4Answer, setQuestion4Answer] = useState([]);
    const question4_options = ["Addiction/Recovery", "Adventurer", "Arts/Culture/Music", "Body Language", "Business", "Corporate Culture", "Customer Service", 
                                "Diversity/Inclusion", "Education", "Entertainment", "Entrepreneurship", "Family Dynamics", "Health", "Human Resources", 
                                "Marketing/Branding/Sales", "Motivational/Inspirational", "Leadership", "Overcoming Adversity", "Real Estate", "Religion", 
                                "Technology", "Women", "Youth/Children"];
    const [question5Answer, setQuestion5Answer] = useState([]);
    const question5_options = ["College", "Corporate", "International", "Youth"]
    const [question6Answer, setQuestion6Answer] = useState("");
    const question6_options = ["0-10", "10-30", "30-60", "60 Upwards"]
    const [question7Answer, setQuestion7Answer] = useState("");
    const question8_options = ["0-10", "10-30", "30-60", "60 Upwards"]
    const [question8Answer, setQuestion8Answer] = useState("");
    const [question9Answer, setQuestion9Answer] = useState("");
    const [question10Answer, setQuestion10Answer] = useState("");
    const [question11Answer, setQuestion11Answer] = useState("");
    const [initialRequest, setInitialRequest] = useState(true);
    const [loading, setLoading] = useState(false);

    const moveDown = () => {
        if (focusIndex < 11) {
           $(`#question-${focusIndex + 1}`)[0].scrollIntoView({block: isMobile ? "start" : "center", behavior: "smooth"})
           setTimeout(function() {
                setFocusIndex(focusIndex + 1);
           }, 250);
        }
    }

    const focusOnQueston = () => {
        $(`#error-${focusIndex}`).removeClass("hidden");
        $(`#question-${focusIndex}`)[0].scrollIntoView({block: isMobile ? "start" : "center", behavior: "smooth"});
    }

    const moveUp = () => {
        if (focusIndex > 1) {
            $(`#question-${focusIndex - 1}`)[0].scrollIntoView({block: isMobile ? "start" : "center", behavior: "smooth"})
            setTimeout(function() {
                setFocusIndex(focusIndex - 1);
            }, 250);
        }
    }

    const wheel = (e) => {
        if(e.deltaY > 0) {
            moveDown()
        } else {
            moveUp()
        }
    }

    useEffect(() => {
        if (!initialRequest && !loading) {
            handleSubmit();
        } else {
            setInitialRequest(false);
        }
        
    }, [question2Answer, question3Answer, question5Answer, question6Answer, question8Answer]);

    useEffect(() => {
        if (loading) {
            axios.get(`/speaker_application_form.json`, {
                params: {
                    "form_response" : {
                        "answers" : [
                            question1Answer, 
                            question2Answer, 
                            question3Answer, 
                            question4Answer, 
                            question5Answer, 
                            question6Answer, 
                            question7Answer, 
                            question8Answer, 
                            question9Answer, 
                            question10Answer, 
                            question11Answer
                        ]
                    }, 
                    "send_emails" : window.location.search.indexOf("send_emails") != 1
                }
            }).then((response) => {
                location.href = "https://trinityfix.com/thank-you";
                // setLoading(false);
                gtag_report_conversion();
                $("#results-found").removeClass("d-none");
            })
        }
      }, [loading])

    const handleSingleMultipleChoiceClick = (index, questionNum, options, setAnswer) => {
        $(".question-" + questionNum).each(function(i) {
            $($(".question-" + questionNum)[i]).removeClass("active")
            $($(".question-" + questionNum + "-icon")[i]).addClass("hidden").removeClass("d-inline")
        });
        $(`#question-${questionNum}-${index}`).addClass("active");
        $(`#question-${questionNum}-${index}-icon`).removeClass("hidden").addClass("d-inline");
        setAnswer(options[index])
    }

    const handleQuestion4Click = (index, val) => {
        if ($(`#question-4-${index}`).hasClass("active")) {
            $(`#question-4-${index}`).removeClass("active");
            $($(".question-4-icon")[index]).addClass("hidden").removeClass("d-inline");
            removedAnswer = question4Answer.splice(question4Answer.indexOf(val), 1)
            setQuestion4Answer(question4Answer)
        } else {
            $(`#question-4-${index}`).addClass("active");
            $($(".question-4-icon")[index]).removeClass("hidden").addClass("d-inline");
            var newQuestion = question4Answer
            newQuestion.push(val)
            setQuestion4Answer(newQuestion)
        }
    }

    const handleSubmit = () => {
        if (focusIndex == 1 && question1Answer.length == 0) {
            focusOnQueston();
        } else if (focusIndex == 1) {
            moveDown();
            $(`#error-${focusIndex}`).addClass("hidden");
        } else if (focusIndex == 2 && question2Answer.length == 0) {
            focusOnQueston();
        } else if (focusIndex == 2 && question2Answer.length > 0) {
            $(`#error-${focusIndex}`).addClass("hidden");
            moveDown();
        } else if (focusIndex == 3 && question3Answer.length == 0) {
            focusOnQueston();
        } else if (focusIndex == 3 && question3Answer.length > 0) {
            $(`#error-${focusIndex}`).addClass("hidden");
            moveDown();
        } else if (focusIndex == 4 && question4Answer.length < 3) {
            focusOnQueston();
        } else if (focusIndex == 4 && question4Answer.length > 2) {
            moveDown();
            $(`#error-${focusIndex}`).addClass("hidden");
        } else if (focusIndex == 5 && question5Answer.length == 0) {
            focusOnQueston();
        } else if (focusIndex == 5 && question5Answer.length > 0) {
            $(`#error-${focusIndex}`).addClass("hidden");
            moveDown();
        } else if (focusIndex == 6 && question6Answer.length == 0) {
            focusOnQueston();
        } else if (focusIndex == 6 && question6Answer.length > 0) {
            moveDown();
            $(`#error-${focusIndex}`).addClass("hidden");
        } else if (focusIndex == 7) {
            moveDown();
        } else if (focusIndex == 8 && question8Answer.length == 0) {
            focusOnQueston();
        } else if (focusIndex == 8 && question8Answer.length > 0) {
            moveDown();
            $(`#error-${focusIndex}`).addClass("hidden");
        } else if (focusIndex == 9) {
            moveDown();
        } 
        else if (focusIndex == 10 && question10Answer.length == 0) {
            focusOnQueston();
        } else if (focusIndex == 10) {
            checkEmailQuestion(question10Answer, 10);
        } else if (focusIndex == 11) {
            moveDown();
            checkNextStep();
        }
    }

    const checkNextStep = () => {
        if (question1Answer.length == 0) {
            $(`#error-1`).removeClass("hidden");
            document.getElementById(`question-1`).scrollIntoView({block: isMobile ? "start" : "center", behavior: "smooth"})
        } else if (question2Answer.length == 0) {
            $(`#error-2`).removeClass("hidden");
            document.getElementById(`question-2`).scrollIntoView({block: isMobile ? "start" : "center", behavior: "smooth"})
        } else if (question3Answer.length == 0) {
            $(`#error-3`).removeClass("hidden");
            document.getElementById(`question-3`).scrollIntoView({block: isMobile ? "start" : "center", behavior: "smooth"})
        } else if (question4Answer.length < 3) {
            $(`#error-4`).removeClass("hidden");
            document.getElementById(`question-4`).scrollIntoView({block: isMobile ? "start" : "center", behavior: "smooth"})
        } else if (question5Answer.length == 0) {
            $(`#error-5`).removeClass("hidden");
            document.getElementById(`question-5`).scrollIntoView({block: isMobile ? "start" : "center", behavior: "smooth"})
        } else if (question6Answer.length == 0) {
            $(`#error-6`).removeClass("hidden");
            document.getElementById(`question-6`).scrollIntoView({block: isMobile ? "start" : "center", behavior: "smooth"})
        } else if (question8Answer.length == 0) {
            $(`#error-8`).removeClass("hidden");
            document.getElementById(`question-8`).scrollIntoView({block: isMobile ? "start" : "center", behavior: "smooth"})
        } else if (question10Answer.length == 0) {
            $(`#error-10`).removeClass("hidden");
            document.getElementById(`question-10`).scrollIntoView({block: isMobile ? "start" : "center", behavior: "smooth"})
        } else {
            $("#question-container").addClass("hidden")
            setLoading(true);
        }
    }

    const handleKeypress = e => {     
        if (e.keyCode === 13) {
            handleSubmit();    
        }  
    };

    const checkEmailQuestion = (email, num) => {
        let re = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/i;
        if ( re.test(email) ) {
            var tempEmail = email.trim()
            setQuestion10Answer(tempEmail)
            $(`#error-${num}`).addClass("hidden");
            moveDown();
        }
        else {
            $(`#error-${num}`).removeClass("hidden");
        }
    }

    $(document).ready(function() {
        document.addEventListener("keydown", handleKeypress);
    });

    const innerHeight = window.innerHeight

  return (
    <>
        <div id="background-image" style={{backgroundImage: `url(${Background})`, overflow: "scroll"}} className="troll position-fixed w-100 h-100"/>
        <div style={{overflow: "scroll"}} className="form-wrapper position-fixed w-100 h-100">
            <div id="question-container" className="container">
                <div className="mt-5 font-montserrat color-white text-center font-size-xl">
                    <span className="mb-2">It's easy to apply!</span>{!isMobile && <br/>}
                    <span className="mb-2"> Simply fill out this application below!</span>{!isMobile && <br/>}
                </div>
                {/* QUESTION 1 */}
                <FreeAnswerQuestion 
                questionNum={1} 
                text={"Hello, what's your name? * "} 
                setFunction={setQuestion1Answer} 
                _handleSubmit={handleSubmit} 
                errorMessage={"Please fill this in"}
                answer={question1Answer}
                wheel={wheel}/>
                {/* QUESTION 2 */}
                <MultipleChoice 
                questionNum={2}
                wheel={wheel}
                options={question2_options}
                text={"How long have you been speaking professionally? * "}
                _handleQuestion={handleSingleMultipleChoiceClick}
                _handleSubmit={handleSubmit}
                setAnswer={setQuestion2Answer}
                errorMessage={"Please pick 1"}/>
                {/* QUESTION 3 */}
                <MultipleChoice 
                questionNum={3}
                wheel={wheel}
                options={question3_options}
                text={"How much do you charge for your speeches? * "}
                _handleQuestion={handleSingleMultipleChoiceClick}
                _handleSubmit={handleSubmit}
                errorMessage={"Please pick 1"}
                setAnswer={setQuestion3Answer}/>
                {/* QUESTION 4 */}
                <MultipleChoice 
                questionNum={4}
                wheel={wheel}
                options={question4_options}
                text={"What type of speaker would you classify yourself as? Please select at least 3 options. * "}
                _handleQuestion={handleSingleMultipleChoiceClick}
                _handleSubmit={handleSubmit}
                setAnswer={setQuestion4Answer}
                errorMessage={"Please pick at least 3"}
                handleMultipleQuestion={handleQuestion4Click}/>
                 {/* QUESTION 5 */}
                 <MultipleChoice 
                questionNum={5}
                wheel={wheel}
                options={question5_options}
                text={"What types of audiences would you like to (or already do) speak to? * "}
                _handleQuestion={handleSingleMultipleChoiceClick}
                _handleSubmit={handleSubmit}
                errorMessage={"Please pick 1"}
                setAnswer={setQuestion5Answer}/>
                {/* QUESTION 6 */}
                <MultipleChoice 
                questionNum={6}
                wheel={wheel}
                options={question6_options}
                text={"How many speeches do you do a year? * "}
                _handleQuestion={handleSingleMultipleChoiceClick}
                _handleSubmit={handleSubmit}
                setAnswer={setQuestion6Answer}
                errorMessage={"Please pick 1"}/>
                {/* QUESTION 7 */}
                <FreeAnswerQuestion
                questionNum={7} 
                text={"Please include your speaker website or any social media handles that you use for speaking."}
                setFunction={setQuestion7Answer} 
                _handleSubmit={handleSubmit} 
                errorMessage={"Please fill this in"}
                answer={question7Answer}
                wheel={wheel}/>
                {/* QUESTION 8 */}
                <MultipleChoice 
                questionNum={8}
                wheel={wheel}
                options={question8_options}
                text={"How many speeches would you like to do? * "}
                _handleQuestion={handleSingleMultipleChoiceClick}
                _handleSubmit={handleSubmit}
                setAnswer={setQuestion8Answer}
                errorMessage={"Please pick 1"}/>
                {/* QUESTION 9 */}
                <FreeAnswerQuestion
                questionNum={9} 
                text={"Is there anything else you would like us to know about you?"}
                setFunction={setQuestion9Answer} 
                _handleSubmit={handleSubmit} 
                errorMessage={"Please fill this in"}
                answer={question9Answer}
                wheel={wheel}/>
                {/* QUESTION 10 */}
                <FreeAnswerQuestion 
                questionNum={10} 
                text={"What is your email so we can send you the results? *"} 
                setFunction={setQuestion10Answer} 
                _handleSubmit={handleSubmit} 
                errorMessage={"Invalid email"}
                answer={question10Answer}
                wheel={wheel}
                email={true}/>
                {/* QUESTION 11 */}
                <FreeAnswerQuestion 
                questionNum={11} 
                text={"Some people also prefer text messages. Provide your number below"} 
                setFunction={setQuestion11Answer} 
                _handleSubmit={handleSubmit} 
                errorMessage={"Please fill this in"}
                answer={question11Answer}
                wheel={wheel}/>
            </div>
            <div id="question-submission" className="h-100"> 
                <div className="container font-size-l font-montserrat color-white d-flex justify-content-center align-items-center h-100"> 
                    <div className="pr-3 pl-3 color-white">
                    {
                        loading &&
                            <div className="loader-wrap">
                                <div className="w-100 text-center"><div>Submitting Your Application</div>
                                    <svg width="50px" height="50px" viewBox="-4 -1 38 28">
                                        <polygon fill="transparent" stroke="#FFFF" stroke-width="2" points="15,0 30,30 0,30"></polygon>
                                    </svg>
                                </div>
                            </div>
                    }
                    {
                        !loading && 
                            <div id="results-found" className="text-center d-none">
                                <div className=""> 
                                    We Will Reach Back To About Your Speaker Application!
                                </div>
                                <div className="font-italic font-size-s mt-2"> 
                                    Thanks for applying!
                                </div>
                                <div className="mt-2">
                                    <a className="green-button-medium" href="https://trinityfix.com">
                                        Back  
                                    </a>
                                </div>
                            </div> 
                    }
                    </div>
                </div>
            </div>
            <div  className="position-fixed" style={{bottom: "20px", right: "50px", fontSize: "20px"}}> 
                <button onClick={moveUp} style={{backgroundColor: "rgb(251, 251, 251)", color: "rgb(124, 124, 124)", borderRadius: "0px 4px 4px 0px", width: "36px", height: "32px"}}> 
                    <i className="fa fa-angle-up"></i>
                </button>
                <button onClick={moveDown} style={{backgroundColor: "rgb(251, 251, 251)", color: "rgb(124, 124, 124)", borderRadius: "0px 4px 4px 0px", width: "36px", height: "32px"}}>
                    <i className="fa fa-angle-down"></i>
                </button>
            </div>
            <div className="position-fixed color-white font-montserrat font-size-l" style={{bottom: "20px", left: "30px"}}> 
                {focusIndex}/11 Questions
            </div>

        </div>
    </>
  );
}

export default SpeakerApplication;
