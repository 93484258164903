import React, { useContext, useEffect, useState } from 'react';
import feelingConfusedSVG from "../../../../assets/images/feeling_confused.svg"
import ReactTooltip from 'react-tooltip';
import { Button, Modal } from 'react-bootstrap';
import { EventContext } from '../../contexts/EventContextContainer';
import axios from "axios";

const FeelingConfused = ({userTrial}) => {
    const [active, setActive] = useState(false); 
    const [tutorialModal, setTutorialModal] = useState(false);
    const {onboardingFlow, changeOnboardingFlow} = useContext(EventContext)

    const toggleActive = () => {
        if (onboardingFlow == 0 && userTrial) {
            changeOnboardingFlow(onboardingFlow + 1)
        }
        setActive(!active)
    }

    const toggleTutorialModal = () => {
        setTutorialModal(!tutorialModal);
    }

    const width = window.innerWidth;
    const isMobile = window.innerWidth < 992;

  return (
    <>
        <div style={{backgroundColor: active ? "#f9fcf5" : isMobile ? "unset" : "#FFFFFF"}} data-tip="hello world" data-event='click focus' data-for='happyFace' className={`${isMobile ? "" : "text-center"} feeling-confused ${(onboardingFlow % 2 == 0 && userTrial) ? "onboarding-glow" : ""}`} onClick={toggleActive}> 
            <img style={{height: isMobile ? "32px" : "83px"}} src={feelingConfusedSVG} alt={"feeling confused"} />
            {
                (width < 992) && <div className="font-size-xxs color-bright-green text-center font-weight-bold ml-4">Q's?</div>
            }
            <div className={`text-center subheader ${isMobile ? "" : "pb-2"}`}>{ (width > 992) ? "PUZZLED?" : "" }</div>
        </div>
         <ReactTooltip className="tooltip-react-confused" globalEventOff='click' id='happyFace' afterShow={toggleActive} afterHide={toggleActive} place={'top'}  effect='solid' clickable={true} overridePosition={ (
                { left, top },
                currentEvent, currentTarget, node) => {
                    const d = document.documentElement;
                    left = Math.min(d.clientWidth - node.clientWidth, left);
                    top = Math.min(d.clientHeight - node.clientHeight, top);
                    left = Math.max(0, left);
                    top = Math.max(0, top);
                    return { top, left }
                } }>
            <div className="tooltip-confused">
                <h3 className="font-weight-bold">What is the TrinityFix Database?</h3>
                <p> TrinityFix is a speaking engagmeent database that has over 20,000 events. A tool to help you scale your Speaking Business by finding the right events for you.
                 </p>   
                 <a className="checkout-button mb-5" href={"/forms/events?source=internal"}>RETAKE THE QUIZ</a>
                 <h3 className="font-weight-bold mt-5">How To Use TrinityFix</h3>
                 <div className="mt-3">
                    <a onClick={toggleTutorialModal} className="request-a-session text-center mb-2">WATCH</a>
                 </div>
                 <h4 className="font-weight-bold mt-5">Frequent Asked Questions</h4>
                 <div className="mt-3">
                    <a href="https://trinityfix.com/frequent-asked-questions" target="_blank" className="request-a-session text-center mb-2">READ</a>
                 </div>
            </div>
        </ReactTooltip>
        {
            active && <div class="feeling-confused-modal"/>
        }
        {
            tutorialModal && 
            <>
                <Modal size="xl" show={tutorialModal} onHide={toggleTutorialModal}>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-center font-weight-bold color-medium-green font-italic font-size-xl mb-3">How To Use TrinityFix</div> 
                        <div className="d-flex justify-content-center">
                            <iframe 
                                width={isMobile ? "300" : "100%"} 
                                height={isMobile ? "300" : window.innerHeight}  
                                src="https://www.youtube.com/embed/PSKByAIiBAA" 
                                title="YouTube video player" 
                                frameborder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen="allowfullscreen"
                                mozallowfullscreen="mozallowfullscreen" 
                                msallowfullscreen="msallowfullscreen" 
                                oallowfullscreen="oallowfullscreen" 
                                webkitallowfullscreen="webkitallowfullscreen">
                            </iframe>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        }
    </>
  )
}

export default FeelingConfused;
