import React, { useContext } from 'react';
import { EventContext } from '../../contexts/EventContextContainer';
import { convertToBudgetRange } from '../../utils/helperFunctions';

const FilterTags = ({
  eventBudgets,
  eventIndustries,
  speakerTypes,
  eventCities
}) => {
  const { speakerFilters, eventFilters, setEventFilters, setSpeakerFilters, timeFilters, setTimeFilters, query, handleQueryChange, newsletterEvents, toggleEventNewsletter, clearFilters } = useContext(EventContext);
  const { event_industry_id, city, estimated_budget, speaker_type_id } = eventFilters

  const isMobile = window.innerWidth < 992;

  const filterName = (filter, id) => {
    let industry;
    let speakerType;
    
    switch (filter){
      case "query": 
        if (id != "undefined" && id != undefined) {
          return "General Search: " + id
        }

      case "event_industry_id":
        if (eventIndustries != undefined && eventIndustries.length > 0)
          industry = eventIndustries.find(industry => industry[0][0] === id)
        
        if (industry)
          return industry[0][1];

      case "speaker_type_id": 
        if (speakerTypes != undefined && speakerTypes.length > 0)
          speakerType = speakerTypes.find(speakerType => speakerType[0] === id)
        
        if (speakerType)
          return "Hired: " + speakerType[1] + " Speakers"

      case "city":
        let city = eventCities.find(city => city[0][0] === id)
        if (city)
          return city[0][0] + ", " + (city[0][1] ? city[0][1] : "");

      case "estimated_budget":
        let budget = eventBudgets.find(budget => budget[0] === id)
        if (budget)
          return convertToBudgetRange(budget[0]);

      case "start_date": 
        return id
      
      case "end_date":
        return id

      case "speaker_name":
        return id

      case "newsletter_events": 
        return "Free Events"
    }
    return null;
  }

  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  const removeFilter = (id, filter) => {
    let newFilters = {...eventFilters}
    if (filter == "query") {
      handleQueryChange();
    } else if (filter == "speaker_name") {
      speakerFilters["speaker_name"].delete
      setSpeakerFilters({})
    } else if (filter == "start_date") {
      timeFilters["end_date"] != undefined ? setTimeFilters({"end_date" : timeFilters["end_date"]}) : setTimeFilters({})
    } else if (filter == "end_date") {
      timeFilters["start_date"] != undefined ? setTimeFilters({"start_date" : timeFilters["start_date"]}) : setTimeFilters({})
    } else if (filter == "newsletter_events") {
      toggleEventNewsletter()
    } else {  
      index = newFilters[filter].indexOf(id)
      if (index > -1)
        newFilters[filter].splice(index, 1)
      setEventFilters(newFilters)
    }
  }

  const renderTag = (id, filter) => {
    let name = filterName(filter, id);
    if (name)
      return (
        <div className="mb-2 tags_p" key={id + filter} onClick={() => removeFilter(id, filter)}>
          <span className="tag-1 tag_">{ name }&nbsp;
          <i className="fas fa-times"></i>
          </span>
        </div>
      );
    else
      return null;
  }
  return (
    
    <div className="row filter-tags-wrapv2 mt-5 pl-2">
       <div id="test" className="mb-2 tags_p float-right position-absolute" onClick={() => clearFilters() } style={{right: "20px", fontStyle: "normal", top: (isMobile ? "10px" : "75px")}}>
          { 
            (
                Object.keys(speakerFilters).length > 0 || 
                Object.keys(timeFilters).length > 0 || 
                (JSON.stringify(city) != JSON.stringify([]) && city != undefined) || 
                (JSON.stringify(estimated_budget) != JSON.stringify([]) && estimated_budget != undefined) || 
                (JSON.stringify(speaker_type_id) != JSON.stringify([]) && speaker_type_id != undefined) || 
                query != undefined || 
                (JSON.stringify(event_industry_id) != JSON.stringify([]) && event_industry_id != undefined)
            ) && 
                  <span className="tag-1 tag_ pt-2 pb-2 clear-all-filter">clear all
              <i className="fas fa-times ml-2"></i>
            </span>
          }
      </div>
      {
        (query != null && query != undefined && query != "undefined") && 
          [query].map(id => renderTag(id, "query"))
      }
      {
        (speakerFilters["speaker_name"] != undefined) &&
          [speakerFilters["speaker_name"]].map(id => renderTag(id, "speaker_name"))
      }
      {
        (timeFilters["start_date"] != undefined && isValidDate(timeFilters["start_date"]) && 
          [timeFilters["start_date"]].map(id => renderTag("Start Date: " + id.toDateString(), "start_date")))
      }
      {
        (timeFilters["end_date"] != undefined && isValidDate(timeFilters["end_date"]) && 
          [timeFilters["end_date"]].map(id => renderTag("End Date: " + id.toDateString(), "end_date")))
      }
      { event_industry_id && event_industry_id.map(id => renderTag(id, "event_industry_id"))}
      { city && city.map(id => renderTag(id, "city"))}
      { estimated_budget && estimated_budget.map(id => renderTag(id, "estimated_budget")) }
      { newsletterEvents && renderTag("newsletter_events", "newsletter_events")}
      { speaker_type_id && speaker_type_id.map(id => renderTag(id, "speaker_type_id")) }
    </div>
  )
}

export default FilterTags;
