import React, { useState, useContext } from 'react';
import DatePicker from 'react-datepicker';
import { EventContext } from '../../contexts/EventContextContainer';

const TimeFilters = () => {
  const [isActive, setIsActive] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState('');
  const { handleTimeFilter, handleFilterClick, handleTopEventsFilterClick, timeFilters } = useContext(EventContext);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const handleStartDateChange = (date) => {
    setStartDate(date)
    handleTimeFilter('custom', {start_date: date, end_date: endDate})
  }

  const handleEndDateChange = (date) => {
    setEndDate(date)
    handleTimeFilter('custom', {start_date: startDate, end_date: date})
  }

  const handleOnClick = (time) => {
    setSelectedFilter(time)
    if (isActive) {
      handleTopEventsFilterClick("20000+", 'estimated_budget', false)
      setIsActive(false)
    }
    handleTimeFilter(time)
  }

  const handleTopEvents = () => {
    setSelectedFilter("topEvents")
    setIsActive(true)
    handleTopEventsFilterClick("20000+", 'estimated_budget', true)
  }

  const isSelected = (time) => selectedFilter == time && "selected"
  
  if (timeFilters != null && timeFilters["start_date"] != undefined && timeFilters["start_date"] != startDate) {
    setStartDate(timeFilters["start_date"])
  }
  if (timeFilters != null && timeFilters["end_date"] != undefined && timeFilters["end_date"] != endDate) {
    setEndDate(timeFilters["end_date"])
  }
  return (
    <ul id="timeFilters" className="nav nav-pills  nav_tb mb-3 time-filters">

      <li className={`_nav_link nav-item box_shadow_shdo ${isSelected("all")}`} onClick={() => handleOnClick("all")} >
        <span className="nav-link nav_lilnk ">ALL</span>
      </li>

      <li className={`_nav_link nav-item box_shadow_shdo ${isSelected("today")}`} onClick={() => handleOnClick("today")} >
         <span className="nav-link nav_lilnk ">TODAY</span>
      </li>

      <li className={`_nav_link nav-item box_shadow_shdo ${isSelected("week")}`} onClick={() => handleOnClick("week")} >
        <span className="nav-link nav_lilnk ">THIS WEEK</span>
      </li>

      <li className={`_nav_link nav-item box_shadow_shdo ${isSelected("topEvents")}`} checked={true} onClick={handleTopEvents} >
        <span className="nav-link nav_lilnk " >TOP EVENTS</span>
      </li>

      <li className="_nav_link nav-item box_shadow_shdo" >
        <span className="nav-link  nav_lilnk nav_lilnk_ calendar-wrap">
          start-end
          <DatePicker
            dateFormat="MMMM d, yyyy"
            selected={startDate}
            isClearable
            onChange={handleStartDateChange}
          />

          <span className="date-separator">-</span>

          <DatePicker
            dateFormat="MMMM d, yyyy"
            selected={endDate}
            isClearable
            onChange={handleEndDateChange}
          />
        </span>
      </li>

      <a href="\events" className={`btn btn-outline-info btn-sm ml-2 pt-2`}>Clear Filter</a>
      <a href="\events?newsletter_events=true" className={`btn btn-outline-info btn-sm ml-2 pt-2`}><i class="fas fa-envelope"></i> &#160;Newsletter Events</a>

    </ul>
  )
}

export default TimeFilters;
