import React, {useContext} from 'react';
import {OrganizationContext} from "../../contexts/OrganizationContextContainer"

const ChapterFilters = ({level_orgs, chapterChapterTypes}) => {
  const {handleFilterClick} = useContext(OrganizationContext);

  return (
    <div id="chapter_filter">
      {
        level_orgs.length > 0 && chapterChapterTypes.length > 0 &&
          level_orgs.map((l_org, index) => (
            <label className="lbl_" key={index}>
              <span>
                <input
                  type="checkbox"
                  onClick={()=> handleFilterClick(l_org[0], 'c_type', event)}
                />
                <span className="spp">
                  {chapterChapterTypes[l_org[0]][0]}
                </span>
              </span>
              { l_org[1] }
            </label>
          ))
      }
    </div>
  )
}

export default ChapterFilters;
