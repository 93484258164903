import React, { useState, useEffect } from 'react';
import axios from "axios";

const EmailContext = React.createContext({});
export { EmailContext };

const EmailContextContainer = (props) => {
  const [show, setShow] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [googleRedirectUri, setGoogleRedirectUri] = useState("");
  const [googleClientId, setGoogleClientId] = useState("")
  const [emailText, setEmailText] = useState("Write the body for your pitch email");
  const [emailSubjectText, setEmailSubjectText] = useState("Title for your pitch email");
  const [attachmentUrl, setAttachmentUrl] = useState(null);
  const [attachmentName, setAttachmentName] = useState(null);
  const [selectedFile, setSelectedFile] = useState();
  const [emailModalConfirmation, setEmailModalConfirmation] = useState(false);
  const [boughtTokens, setBoughtTokens] = useState(false);
  const [validGoogleToken, setValidGoogleToken] = useState(false);
  const [spentEmailAutoFill, setSpentEmailAutoFill] = useState(false);
  const [senderEmail, setSenderEmail] = useState(null);

  const handleAutoFill = (event_id) => {
    axios.get(`/user_auto_fill_email.json`, {
      params: {"event_id" : event_id}
    }).then((response) => {
      setEmailText(response.data.emailText.replace('<br/>', '\n'));
      setEmailSubjectText(response.data.emailSubjectText);
      setBoughtTokens(true);
    })
  }

  const handleAutoFillFree = (event_id) => {
    axios.get(`/user_auto_fill_email.json`, {
      params: {"event_id" : event_id, "free" : true}
    }).then((response) => {
      setEmailText(response.data.emailText.replace('<br/>', '\n'));
      setEmailSubjectText(response.data.emailSubjectText);
      setBoughtTokens(true);
    })
  }

  const handlEmailModalConfirmationeClose = () => {
    setEmailModalConfirmation(false);
  }

  const handleSendEmail = (e) => {
    axios.get(`/user_auth_set.json`,{
      params: []
    })
    .then((response) => {
      if (response["data"]) {
        axios.get(`/user_auth_needed.json`,{
          params: []
        }).then((res) => {
          setShowEmailModal(true)
        })
      } else {
        if (e.target !== undefined)
          localStorage.setItem( 'element', e.target.id );
        setShow(true)
      }
    })
  }

  const SendCustomerEmail = (e_id) => {
    let foo = emailText.replace(/\n/g,'<br />')
                .replace(/\r/g,'<br />')
                .replace(/\r\n/g,'<br />');
    foo = foo.replace(/\n/g,"<br />").replace("%", "%25");

    const formData = new FormData();
    formData.append("file", selectedFile);
    axios.put(`/send_contact_email.json?event_id=${e_id}&email_body=${foo}&email_subject=${emailSubjectText}&attachment_url=${attachmentUrl}&attachment_name=${attachmentName}`, formData)
      .then(response => {
        if (response.data) {
          setShow(false);
          setShowEmailModal(false);
          setEmailModalConfirmation(true);
        } else {
          alert("Email was not sent successfully, please try reauthenticating");
        }
      }).catch(error => {
       console.log("*****  "+error)
    });
  }

  const handleOnSubmit = () => {
    axios.get(`/add_user_auth.json`,{
      params: []
    })
    .then((response) => {
      setShow(false)
      setShowEmailModal(true)
    });
  }

  useEffect(() => {
    axios.get(`/google_creds.json`, {
      params: { }
    }).then((response) => {
      setGoogleRedirectUri(response.data.google_redirect_uri)
      setGoogleClientId(response.data.client_id)
    });
    axios.get(`/users/spent_email_autofill_js.json`, {
      params: { }
    }).then((response) => {
      setSpentEmailAutoFill(response.data);
    });
    axios.get('users/check_google_auth.json',{})
      .then((response) => {
        setValidGoogleToken(response.data)
      });
    axios.get('users/get_email_sender_js.json',{})
      .then((response) => {
        setSenderEmail(response.data)
      });
  }, []);

  return (
    <EmailContext.Provider
      value={{ 
        handleSendEmail, 
        handleOnSubmit, 
        show, 
        showEmailModal, 
        setShowEmailModal, 
        setShow, 
        googleRedirectUri, 
        googleClientId, 
        handleAutoFill, 
        emailText, 
        emailSubjectText, 
        setEmailSubjectText,
        setEmailText, 
        SendCustomerEmail, 
        setAttachmentUrl, 
        setAttachmentName, 
        selectedFile, 
        setSelectedFile, 
        emailModalConfirmation, 
        handlEmailModalConfirmationeClose, 
        boughtTokens, 
        validGoogleToken, 
        setValidGoogleToken,
        spentEmailAutoFill, 
        handleAutoFillFree, 
        senderEmail
      }}
    >
      { props.children }
    </EmailContext.Provider>
  )
}

export default EmailContextContainer;
