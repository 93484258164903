import React, { useState } from 'react';
import axios from "axios";
import ImagePicker from 'react-image-picker';
import { Modal } from 'react-bootstrap';

import img1 from '../../../../assets/images/stage1.png';
import img2 from '../../../../assets/images/stage2.png';
import img3 from '../../../../assets/images/stage3.png';
import img4 from '../../../../assets/images/stage4.png';
import img5 from '../../../../assets/images/stage5.png';
import img6 from '../../../../assets/images/stage6.png';

const ImagePickerComponent = ({setCoverUrl, userId}) => {
  const [show, setShow] = useState(false)
  const imageList = [img1, img2, img3, img4, img5, img6]
  const [image, setImage] = useState(undefined);

  const onPick = (e) => {
    setImage(e.src)
  }
  
  const togglePic = () => {
    setShow(!show)
  }

  const handleOnSubmit = (event) => {
    if (image == undefined) {
      alert("Please select an image.")
    } else {
        var params = {  
          'cover_url' : image, 
          'user_id' : userId
        }
        axios.put(`/profiles/update_user.json`,{
          params: params
        })
        .then((response) => {
          setShow(false);
          setCoverUrl(image);
          togglePic();
        })
      }
  }

  const handleClose = () => {
    togglePic();
  }

  return (
    <>
      {
        show ? 
          <div>
            <Modal className="image-picker-modal" show={show} onHide={handleClose}>
              <Modal.Header closeButton>
              </Modal.Header>
                <Modal.Body>
                    <ImagePicker
                      images={imageList.map((image, i) => ({src: image, value: i}))}
                      onPick={onPick}
                    />
                </Modal.Body>
                  
                <Modal.Footer className="border-top-0 justify-content-center">
                  <a className="green-button pl-4 pr-4" onClick={handleOnSubmit}>
                    Select
                  </a>
                </Modal.Footer>
            </Modal>
          </div>
          :
          <div onClick={togglePic} className="text-right w-100 mt-2 mr-2 mb-2">
            <a className="float-right font-italic">
              <i className="fas fa-edit"></i> &nbsp; Click to choose a cover picture
            </a>
          </div> 
      }
    </>
  )
}

export default ImagePickerComponent;