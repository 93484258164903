import React, { useContext, useEffect } from 'react';
import { EventContext } from '../../contexts/EventContextContainer';

const LocationFilters = ({eventCities}) => {
  const { handleFilterClick, setQuery, eventFilters } = useContext(EventContext);

  const handelInputChange = (e) => {
    if (e.key === 'Enter')
      setQuery(event.target.value);
  }

  const isChecked = value =>
    eventFilters.city && eventFilters.city.includes(value)

  return (
    <div>
      <h2 className="sdbr_head">
        Location
      </h2>
      <div className="relative">
        <input
          className="inpt"
          placeholder="Place / City / Country"
          onKeyDown={handelInputChange}
        />
        <i className="fas fa-search icocio"></i>
      </div>
      <div id="cities_filter">
        {
          eventCities.map((eventCity, index) => (
            <label className="lbl_" key={index}>
              <span>
                <input
                  value={eventCity[0][0]}
                  type="checkbox"
                  checked={isChecked(eventCity[0][0])}
                  onClick={(event) => handleFilterClick(eventCity[0][0], 'city', event)}
                />
                <span className="spp">
                  {eventCity[0][0]}, {eventCity[0][1]}
                </span>
              </span>
              {eventCity[1]}
            </label>
          ))
        }
      </div>
    </div>
  )
}

export default LocationFilters;
