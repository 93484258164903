import React, { useContext, useState } from 'react';
import axios from "axios";
import { EventContext } from '../../contexts/EventContextContainer';
import { Button, Modal } from 'react-bootstrap';

const SaveSearch = ({mobile}) => {
  const [show, setShow] = useState(false);
  const [description, setDescription] = useState("")
  const {eventFilters, timeFilters, query, handleSavedSearchesRequest} = useContext(EventContext);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleOnSubmit = () => {
    if (description.length < 1) {
        alert("Please provide a description for your saved search.")
    } else {
      var params = {
        'query' : query, 
        'start_time' : timeFilters["start_date"], 
        'end_time' : timeFilters["end_date"], 
        'budget' : eventFilters["estimated_budget"], 
        'location' : eventFilters["city"], 
        'event_industry_ids' : eventFilters["event_industry_id"], 
        'description' : description
      }
      axios.post(`/saved_search.json`,{
        params: params
      })
      .then((response) => {
        handleSavedSearchesRequest();
        alert("Saved Search: " + description + " was successfully created!");
        setShow(false);     
      })
    }
  }

  return (
    <div>
        {
            show && 
            <>
              <div className="saved-search-modal">
                <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                   <div className="text-center mb-2"><i className="fas fa-save"/></div>  
                   <div className="text-center font-weight-bold saved-search-header mb-3">What do you want to name your search?</div>
                    <div className="d-flex justify-content-center">
                      <textarea 
                          className="w-75" 
                          placeholder="Helpful description..." 
                          onChange={(event)=>{
                              setDescription(event.target.value);
                          }}
                      >
                      </textarea>
                      <i class="far fa-triangle fa-rotate-90 ml-2" onClick={handleOnSubmit}/>
                    </div>
                </Modal.Body>
                
                  <Modal.Footer className="border-top-0 justify-content-center">
                    <a className="checkout-button pl-4 pr-4" onClick={handleOnSubmit}>
                        Save
                    </a>
                  </Modal.Footer>
                </Modal>
                </div>
            </>
        }
        <div className="save-search mr-1 text-left mb-2 text-center w-100 justify-content-center d-flex" onClick={handleShow}><div className="wrapper"><i class="fas fa-save mr-1" style={{fontSize:'50px'}}></i><br/><span>Save<br/>{ !mobile ? "search" : "" }</span></div></div>
    </div>
  )
}

export default SaveSearch;
