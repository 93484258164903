import React, { } from 'react';
import EventsV2 from "../../EventsV2";
import EventContextContainer from '../../contexts/EventContextContainer';

const EventsWrapper = ({
  eventBudgets,
  eventIndustries,
  speakerTypes,
  eventCities,
  speakerFilters,
  query, 
  userName, 
  userQuiz,
  userRole, 
  userQuizId, 
  userTrial, 
  stripeCheckoutLink, 
  validUser,
  paidTrial, 
  numberOfNewsletterEvents, 
  emailFeatureFlag, 
  googleClientId, 
  googleSecretId, 
  googleRedirectUri, 
  failedPayment
}) => {
  // Loads quiz search results when user has filled out quiz and landed on events page
  if (userQuiz && userTrial && location.href.indexOf("saved_search") == -1 && location.href.indexOf("newsletter_events") == -1) {
    location.href = "/saved_searches/" + userQuizId
  }
  return (
      <EventContextContainer>
          <EventsV2 speakerTypes={speakerTypes} eventBudgets={eventBudgets} eventIndustries={eventIndustries} eventCities={eventCities} speakerFilters={speakerFilters}
          query={query} userName={userName} userQuiz={userQuiz} userQuizId={userQuizId} userTrial={userTrial} stripeCheckoutLink={stripeCheckoutLink}
          paidTrial={paidTrial} userRole={userRole} numberOfNewsletterEvents={numberOfNewsletterEvents} emailFeatureFlag={emailFeatureFlag}
          googleClientId={googleClientId} googleSecretId={googleSecretId} googleRedirectUri={googleRedirectUri} validUser={validUser} failedPayment={failedPayment}
          />
      </EventContextContainer>
  );
}

export default EventsWrapper;
