import React, { useContext } from 'react';
import { EventContext } from '../../contexts/EventContextContainer';
import Toggle from 'react-toggle'

const OrderedFilters = ({}) => {
    const { includeOrderedEvents, toggleOrderedFilters }  = useContext(EventContext);

    const isMobile = window.innerWidth < 992;

    return (
      <div className="ordered-filters d-inline-block d-flex">
        <Toggle
          id='biscuit-status'
          defaultChecked={includeOrderedEvents}
          aria-labelledby='biscuit-label'
          onChange={toggleOrderedFilters} 
          className={ isMobile ? "" : "ml-5"}/>
          <div className={`font-montserrat font-size-m color-light-black ml-2 d-inline ${includeOrderedEvents ? "color-dark-green" : ""}`} id='biscuit-label'>Include Ordered Events</div>
      </div>
    );
};

export default OrderedFilters;