import React, {useContext} from 'react';
import IndustryFilters from "../../Filters/IndustryFilters";
import ChapterFilters from "../../Filters/ChapterFilters";
import {OrganizationContext} from "../../contexts/OrganizationContextContainer"

const Sidebar = ({
  level_orgs,
  eventIndustries,
  chapterChapterTypes
}) => {
  const {handleFilterClick, organiztionFilters} = useContext(OrganizationContext);

  return (
    <div className="card">
      <div className="card-body cb">

        <h2 className="sdbr_head">
          Chapter Level
        </h2>

        <ChapterFilters
          level_orgs={level_orgs}
          chapterChapterTypes={chapterChapterTypes}
        />

        <hr />

        <IndustryFilters
          eventIndustries={eventIndustries}
          handleFilterClick={handleFilterClick}
          currentFilters={organiztionFilters}
        />
      </div>
    </div>
  );
}

export default Sidebar;
