import React, { useState } from 'react';

const IndustryFilters = ({eventIndustries, handleFilterClick, currentFilters}) => {
  const [loadSize, setLoadSize] = useState(10);

  const isChecked = value =>
    currentFilters.event_industry_id && currentFilters.event_industry_id.includes(value)

  return (
    <div id="event_industry_filter">
      <h2 className="sdbr_head">
        Industry
      </h2>
      {
        eventIndustries.slice(0, loadSize).map((eventIndustry, index) => (
          <label className="lbl_" key={index}>
            <span>
              <input
                value={eventIndustry[0][0]}
                id={eventIndustry[0][0]}
                type="checkbox"
                checked={isChecked(eventIndustry[0][0])}
                onClick={(event) => handleFilterClick(eventIndustry[0][0], 'event_industry_id', event)}
              />
              <span className="spp">
                {eventIndustry[0][1]}
              </span>
            </span>
            {
              eventIndustry[1]
            }
          </label>
        ))
      }
      <a className="link_color see_more_industries" onClick={() => setLoadSize(loadSize + 10)}>see more</a>
    </div>
  )
}

export default IndustryFilters;
