import React, { useState, setState, useContext } from 'react';
import 'react-select-search/style.css';
import { EventContext } from '../../contexts/EventContextContainer';

const LocationSearch = ({ options }) => {
    const [query, setQueryInputFields] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [menuShowCss, setMenuShowCss] = useState("");
    const { setQuery }  = useContext(EventContext);
    let locationSearch = React.createRef();
    let updateSearchForm = (name) => {
      locationSearch.current.value = name
      setMenuShowCss("")
      handleLocationSearch()
    }

    const handleLocationSearch = event => {
      setQuery(locationSearch.current.value)
    }

    let getData = (newQuery) => {
      let url = '/events/location_search.json?q=' + newQuery
      let newFilteredData = []
      fetch(url)
        .then(response => response.json())
        .then(data => {
          Object.keys(data).map((key,i) => {
            newFilteredData.push({name: key, id: data[key]})
          })
          if (newFilteredData.length == 0) {
           
          } else {
            setFilteredData(newFilteredData)
          }
          
        });
    };

      let handelInputChangeEnter = event => {
        console.log("Starting", event.key);
        if (event.key === 'Enter') {
          if (Object.keys(filteredData).length > 0) {
            setQuery(filteredData[0][0])
          }
        }
      }

      let handleInputChange = event => {
        const newQuery = event.target.value;
        setQueryInputFields(query)
        if (event.key === 'Enter') {
          if (Object.keys(filteredData).length > 0) {
            setQueryInputFields(filteredData[0][0])
          }
        }
        if (newQuery.length > 0) {
          getData(newQuery)
          setMenuShowCss("show")
        } else {
          setMenuShowCss("")
        } 
      };

    return (
      <div>
        <div className="searchForm speaker-search">
        <div className="dropdown w-100">
          <label className="font-weight-bold"><i className="fa fa-search" onClick={handleLocationSearch}/>
            <input
              placeholder="Location"
              onChange={handleInputChange}
              onKeyDown={handelInputChangeEnter}
              className="ml-1"
              ref={locationSearch}
              style={{border: 0, outline: 0, background: "transparent", borderBottom: "1px solid rgba(0, 0, 0, 0.15)", width: "240px"}}
            />
            <i style={{position: 'absolute', top: '8px'}} onClick={handleLocationSearch} className="far fa-triangle fa-rotate-90 ml-2"/>
            <div className={"dropdown-menu " + menuShowCss}style={{marginLeft: '24px', marginTop: '-8px'}}>
              {filteredData.map(i => <span onClick={function(){updateSearchForm(i.name)}}className="dropdown-item background-color-dark-green color-white font-size-xs font-montserrat">
                {i.name}<span style={{maxWidth: '275px', overflow: 'hidden', textOverflow: 'ellipsis'}} className="float-right font-size-xs font-montserrat">{`(${i.id})`}</span>
              </span>)}
            </div>
          </label>
        </div>
      </div>
      </div>
    );
};

export default LocationSearch;