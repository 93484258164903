import React from 'react';
import InfiniteCircle from '../../../../assets/images/infinite_circle.svg'
import axios from "axios";

const TrialEventPrompt = ({trial, userName, savedSearch, top, stripeCheckoutLink}) => {

  let savedSearchText = ""
  if (savedSearch) {
    savedSearchText = "We've found the right events for you."
  } 

  const getStripeCheckout = () => {
    axios.get(`/checkout.json`, {
      params: {}
    }).then((response) => {
      location.href = response.data
    })
  }
  
  return (
    <>
    { 
      trial && (
        <>
        {
          top ? 
          <>
            <div className="trial-header mb-2 mt-4"> 
              {userName}, here are your results.
              <br/> 
              {savedSearchText}
            </div>
            <div className="trial-subheader mb-2"> 
              Unlock the contact info 
              <br/> 
              for your first 10 events for only 
              <span className="trial-header">&nbsp;$7</span>
            </div>
          </> 
          : 
          <>
            <div className="trial-header mb-2"> 
              So, what do you say {userName}?
              <br/> 
              Ready to get booked?
            </div>
            <div className="trial-subheader mb-2"> 
              Unlock the contact info 
              <br/> 
              for your first 10 events for only 
              <span className="trial-header">&nbsp;$7</span>
            </div>
          </> 
        }
          <div className="text-center mb-5 mt-5">
            <a class="trial-offer-button mb-5" onClick={getStripeCheckout}><img className="mb-1" width="40" src={InfiniteCircle} alt={"infinite circle cost"}/>&nbsp;Buy 10 Tokens</a>
          </div>
        </>
      )
    }
    </>
  )
}

export default TrialEventPrompt;
