import React, { useContext, useState, useEffect } from 'react';
import { EmailContext } from '../../contexts/EmailContextContainer';
import { Modal } from 'react-bootstrap';
import Gmail from "../../../../assets/images/gmail_logo.png";
import axios from "axios";
import InfiniteCircle from '../../../../assets/images/infinite_circle.svg';
import EmailConfirmed from '../../../../assets/images/email_confirmed.svg';
import gmailLogo from '../../../../assets/images/gmail_icon.svg';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import copyIcon from '../../../../assets/images/copy_icon_dark.svg';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde8", 
    zIndex: 5000
  }
}))(Tooltip);

const EmailModal = ({event}) => {
  const { 
    setShow, 
    setShowEmailModal, 
    show, 
    showEmailModal, 
    googleRedirectUri, 
    googleClientId, 
    handleAutoFill, 
    handleAutoFillFree,
    emailText, 
    emailSubjectText, 
    setEmailText, 
    setEmailSubjectText, 
    SendCustomerEmail, 
    setAttachmentUrl,
    attachmentUrl, 
    setAttachmentName, 
    selectedFile, 
    setSelectedFile, 
    emailModalConfirmation, 
    handlEmailModalConfirmationeClose, 
    boughtTokens, 
    validGoogleToken, 
    setValidGoogleToken,
    spentEmailAutoFill,
    senderEmail
   } = useContext(EmailContext);

    const [copyToClipboard, setCopyToClipboard] = useState(false); 
    const [copied, setCopied] = useState(false);

    const handleTrinityFixEmail = () => {

      axios.get('users/check_google_auth.json',{})
      .then((response) => {
        if (response.data) {
          setShow(false);
          setShowEmailModal(true);
        } else {
          localStorage.setItem( 'isGoogleAuth', 1 );
          location.href = "/auth/auth/google_oauth2";
        }
      })
    }

    useEffect(() => {
      let isGoogleAuth = localStorage.getItem('isGoogleAuth');
      let element = localStorage.getItem('element')
      if( undefined != isGoogleAuth && isGoogleAuth == 1 ){
        if(element != undefined ){
          var timer = setInterval( function() {
            if (document.getElementById(element) !== null) {
              document.getElementById(element).click()
              clearInterval( timer );
            }
          }, 500 );
        }        
        localStorage.setItem( 'isGoogleAuth', 0 );
        localStorage.setItem( 'element', null );
      }
    }, [])
    
    const handleClose = () => {
        setShow(false);
    }

    const handleSubjectText = (e) => {
      setEmailSubjectText(e)
    }
    const handleEmailModalClose = () => {
      setShowEmailModal(false);
    }

    const handleCopyToClipboard = () => {
      setCopyToClipboard(true);
    }

    const closeCopyToClipboard = () => {
      setCopyToClipboard(false);
    }

    const changeHandleSubmission = (event) => {
      setSelectedFile(event.target.files[0]);
      const formData = new FormData();
      formData.append("file", event.target.files[0]);
      axios.put(`/emails/attach_file.json`, formData)
        .then(response => {
          setAttachmentUrl(response.data.attachment_url);
          setAttachmentName(response.data.attachment_name)
        }).catch(error => {
         console.log("*****  "+error)
      });
    }

    const copyClick = () => {
      navigator.clipboard.writeText(event.event_contact_name + " " +  event.event_contact_email_unmasked + " " + event.event_contact_phone_unmasked + " " + event.event_contact_title);
      setCopied(true);
    }

  return (
    <>
       {
        show && 
          <div>
            <Modal backdropClassName="show-events-backdrop" size="lg" show={show} onHide={handleClose}>
              <Modal.Header style={{padding: "0px", zIndex: 2000}} closeButton>
              </Modal.Header>
                <Modal.Body>
                  <div className="font-size-l color-dark-blue mt-3 font-weight-bold text-center w-100 font-montserrat">Before contacting the event planners,</div>
                  <div className="font-size-l color-dark-blue font-weight-bold text-center w-100 font-montserrat">Choose your email provider</div>
                  <div className="font-size-l color-dark-blue text-center w-100 font-montserrat">to authenticate and start sending emails!</div>
                  <div className="row mt-5"> 
                    <div className="col d-flex justify-content-center"> 
                      <div className="text-center" style={{width: "250px"}}> 
                        <div className="row d-flex justify-content-center">
                          <div className="" onClick={() => handleTrinityFixEmail()} style={{width:"105px", cursor: 'pointer', outline:"3px solid #0cbd9d", borderRadius:"50px", paddingBottom: "33px", paddingTop: "33px"}}>
                            <img src={gmailLogo} width="50"/>
                          </div>
                        </div>
                        <div className="row">
                          <div className="w-100 font-montserrat font-weight-bold font-size-m color-dark-blue mt-2"> 
                            Gmail
                          </div>
                        </div>
                        <div id="test" className="row d-flex justify-content-center mt-3"> 
                            <a id="test2" style={{width: "250px"}} onClick={() => handleTrinityFixEmail()} className="green-button-medium">Email Contact</a>
                        </div>
                      </div>
                    </div>
                    <div className="col d-flex justify-content-center"> 
                      <div className="text-center" style={{width: "250px"}}> 
                        <div className="row d-flex justify-content-center">
                          <div className="" style={{width:"105px", cursor: 'pointer', outline:"3px solid #0cbd9d", borderRadius:"50px", paddingBottom: "28px", paddingTop: "28px"}} onClick={() => handleCopyToClipboard()}>
                            <i style={{fontSize: "50px"}} className="font-size-xl fa color-bright-green fa-envelope"/>
                          </div>
                        </div>
                        <div className="row">
                          <div className="w-100 font-montserrat font-weight-bold font-size-m color-dark-blue mt-2"> 
                            Other Email
                          </div>
                        </div>
                        <div className="row d-flex justify-content-center mt-3"> 
                            <a style={{width: "250px"}} onClick={() => handleCopyToClipboard()} className="green-button-medium">Copy Contact Info</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                  
                <Modal.Footer className="border-top-0 justify-content-center">
                </Modal.Footer>
            </Modal>
          </div>
      }
      <Modal size="m" show={copyToClipboard} onHide={closeCopyToClipboard}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <div className="font-size-l color-dark-blue mt-3 font-weight-bold text-center w-100 font-montserrat">{event.event_contact_name}</div>
          <div className="font-size-l color-dark-blue mt-3 font-weight-bold text-center w-100 font-montserrat">{event.event_contact_title}</div>
          <div className="font-size-l color-dark-blue mt-3 font-weight-bold text-center w-100 font-montserrat">{event.event_contact_email_unmasked || event.event_contact_email}</div>
          <div className="font-size-l color-dark-blue mt-3 font-weight-bold text-center w-100 font-montserrat">{event.event_contact_phone_unmasked || event.event_contact_phone}</div>
          <div className="text-center color-dark-blue w-100 mt-2 mb-2">
            <img
              onClick={copyClick} 
              src={copyIcon} 
              width="40px" 
              className={`${copied ? "d-none" : "color-dark-blue"}`} 
              style={{cursor: "pointer"}}/>
            <i className={`fa fa-check ${!copied ? "d-none" : ""}`} style={{fontSize: "40px"}}/>
            <div className="font-italic font-size-m w-100 font-weight-bold mb-1">Copy to Clipboard</div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-top-0 justify-content-center">
        </Modal.Footer>
      </Modal>
      {
        (validGoogleToken && (show || showEmailModal)) && 
          <div>
            <Modal backdropClassName="show-events-backdrop" show={showEmailModal} onHide={handleEmailModalClose} size="lg">
              <Modal.Header style={{padding: "0px", zIndex: 2000}} closeButton>
              </Modal.Header>
                <Modal.Body className="pr-5 pl-5">
                  <div class="font-size-xl font-italic color-bright-green font-weight-bold text-center w-100" > 
                    Contact the event planners!
                  </div>
                  <div class="text-left w-100 mt-3 font-size-m font-montserrat font-weight-bold color-light-black">
                    Event Name: {event.name}
                    <div class="font-size-s w-100"> 
                      Event Planner Name: <span className="font-weight-normal">{event.event_contact_name}</span><br/>
                      {
                        event.event_contact_title && 
                        <>
                          <span className="mr-2">Position: </span>
                          <span className="font-weight-normal">{event.event_contact_title}</span>
                        </>
                      }
                      <br/>
                      Website:<span className="ml-2"><a className="text-underline-medium-green-solid color-bright-green a-no-hover" href={event.website} target="_blank">{event.website}</a></span>
                    </div>
                  </div>
                  
                  {
                    spentEmailAutoFill ? 
                      <div className={`mt-4 w-100 text-center mb-4 ${boughtTokens ? "d-none" : ""}`}>
                        <a onClick={() => handleAutoFillFree(event.id)} className="beige-button">AUTO-FILL TEMPLATE</a> 
                      </div>
                    :
                      <>
                        <div className={`mt-2 font-size-s color-beige font-montserrat font-weight-bold text-center ${boughtTokens ? "d-none" : ""}`}>
                          <span className="">Don't know what to write? Feat of embarrassing yourself?</span><br/>
                          <span className="mt-2">No worries! Buy our auto-fill template for <img style={{marginRight:"-5px", marginLeft:"-3px"}} width="30px" src={InfiniteCircle}/>5</span>
                        </div>
                        <div className={`mt-4 w-100 text-center mb-4 ${boughtTokens ? "d-none" : ""}`}>
                          <a onClick={() => handleAutoFill(event.id)} className="beige-button">BUY AUTO-FILL TEMPLATE</a> 
                        </div>
                      </>
                  }
                 
                  <div className="container">
                    <div className="row mt-2 text-center font-italic color-dark-blue font-weight-bold font-montserrat"> 
                      Sending Emails From: {senderEmail}
                    </div>
                    <div className="row mt-3">
                      <div className="col-3 pr-0 pl-0 text-left">
                        <span className="font-size-m font-montserrat font-weight-bold color-bright-green">E-mail subject:</span>
                      </div>
                      <div className="col-9"> 
                        <input className="w-100 pr-3 pl-3 font-size-m font-montserrat font-weight-bold color-light-black" 
                               style={{borderRadius: "25px", border: "3px solid #0cbd9d"}} 
                               type="text" 
                               value={emailSubjectText.length > 0 && emailSubjectText}
                               onChange={event => handleSubjectText(event.target.value)}
                               placeholder="Write the title for your pitch email">
                        </input> 
                      </div>
                    </div>
                    <div className="row mt-1">
                      <div className="col-3 pr-0 pl-0 text-left">
                        <span className="font-size-m font-montserrat font-weight-bold color-bright-green">E-mail text:</span>
                      </div>
                      <div className="col-9"> 
                        <textarea className="w-100 font-size-s font-montserrat font-weight-bold color-light-black p-3" 
                                  style={{borderRadius: "25px", border: "3px solid #0cbd9d", height: "200px"}} 
                                  type="text"
                                  value={emailText}
                                  onChange={(event)=>{
                                    setEmailText(event.target.value);
                                  }}>
                        </textarea>
                      </div>
                    </div>
                    <div className="row mt-1">
                      <div className="col-3">
                      </div>
                      <div className="col-9 mt-3">  
                        { false && 
                          <label className="checkout-button text-left" for="upload-file"><i class="fas fa-paperclip"/> ATTACH FILE?</label>
                        }
                        <input className="d-none" type="file" name="file" onChange={changeHandleSubmission} id="upload-file" />
                        {
                          selectedFile && 
                            <span className="ml-4 color-light-black font-weight-bold">
                              <i class="fas fa-paperclip"/>&nbsp;<span className="font-montserrat font-size-s">{selectedFile.name}</span>
                            </span>
                        }
                        {
                          false && <div style={{maxWidth:"225px"}} className="mt-2 font-italic font-montserrat color-light-black font-size-s">You can attach a speaker trial or any important info as you see fit for your pitch.</div>
                        }
                        <div class="blue-button float-right" onClick={() => SendCustomerEmail(event.id)} href=""><i class="fas fa-rotate-90 fa-triangle"></i>&nbsp;SEND E-MAIL PITCH!</div>
                      </div>
                    </div>  
                  </div>         
                </Modal.Body>
                  
                <Modal.Footer className="border-top-0 justify-content-center">
                </Modal.Footer>
            </Modal>
          </div>
      }
      {
        emailModalConfirmation && 
          <Modal className="" show={emailModalConfirmation} onHide={handlEmailModalConfirmationeClose}>
          <Modal.Header closeButton>
          </Modal.Header>
            <Modal.Body>
              <div className="w-100 text-center">
                <img src={EmailConfirmed} width="100px" />
              </div>
              <div className="font-size-l color-bright-green mt-3 font-weight-bold text-center w-100 font-montserrat">Congratulations</div>
              <div className="text-center w-100 font-size-m font-montserrat color-bright-green font-weight-bold" > 
                Your email has been sent to
              </div>          
              <div className="text-center w-100 font-size-m font-montserrat color-bright-green font-weight-bold">
                {event.name} event planner!
              </div>
            </Modal.Body>
            <Modal.Footer className="border-top-0 justify-content-center">
            </Modal.Footer>
        </Modal>
      }
    </>
  )
}

export default EmailModal;
