import React, { useContext } from 'react';
import { convertToBudgetRange } from '../../utils/helperFunctions';
import { EventContext } from '../../contexts/EventContextContainer';

const BudgetFilters = ({eventBudgets}) => {
  const { handleFilterClick, eventFilters } = useContext(EventContext);

  const isChecked = value =>
    eventFilters.estimated_budget && eventFilters.estimated_budget.includes(value)

  return (
    <div id="budget_filter">
      <h2 className="sdbr_head">
        Speaker Budget
      </h2>

      {
        eventBudgets.map((eventBudget, index) => (
          <label className="lbl_" key={index}>
            <span>
              <input
                value={eventBudget[0]}
                id={eventBudget[0]}
                type="checkbox"
                checked={isChecked(eventBudget[0])}
                onClick={(event) => handleFilterClick(eventBudget[0], 'estimated_budget', event)}
              />
              <span className="spp">
                {convertToBudgetRange(eventBudget[0])}
              </span>
            </span>
            { eventBudget[1] }
          </label>
        ))
      }
    </div>
  )
}

export default BudgetFilters;
