import React, { useContext } from 'react';
import SideBarV2 from "../Filters/SideBarV2";
import EventListV2 from "../EventsV2/EventListV2";
import ValidUser from "../Shared/ValidUserModal";
import EventListTrial from "../EventsV2/EventListTrial";
import { EventContext } from '../contexts/EventContextContainer';
import SavedSearchList from './SavedSearchList';
import Tokens from "./TokensNonEvents";
import Triangle from "../../../assets/images/logo_triangle.png";
import InfiniteCircle from '../../../assets/images/infinite_circle.svg'
import FeelingConfused from '../Shared/FeelingConfused';
import ZeroTokensLeft from '../Shared/ZeroTokensLeft';
import FeaturedSpeaker from '../Shared/FeaturedSpeaker';
import NavBar from "../Shared/NavBar";

const EventsV2 = ({
  eventBudgets,
  eventIndustries,
  eventCities,
  speakerFilters,
  query, 
  speakerTypes,
  userName, 
  userQuiz, 
  userQuizId, 
  userTrial,
  userRole, 
  stripeCheckoutLink, 
  paidTrial, 
  numberOfNewsletterEvents, 
  emailFeatureFlag, 
  googleClientId, 
  googleSecretId, 
  googleRedirectUri, 
  validUser, 
  failedPayment
}) => {
  // Loads quiz search results when user has filled out quiz and landed on events page
  if (userQuiz && userTrial && location.href.indexOf("saved_search") == -1 && location.href.indexOf("newsletter_events") == -1) {
    location.href = "/saved_searches/" + userQuizId
  }

  const isMobile = window.innerWidth < 992;
  const hasNewsletter = location.href.indexOf("newsletter_events") > -1;
  const { savedSearchesOpen, savedSearches, sideBarOpen, initialSearch } = useContext(EventContext);
  return (
    <>
      <div className="row justify-content-md-center">
        <div className={`${(!sideBarOpen || userTrial) ? "" : "event-spacer-div d-lg-block"}`}/>
        <Tokens stripeCheckoutLink={stripeCheckoutLink} userTrial={userTrial} paidTrial={paidTrial} eventsPage={true}/>
        <div className={isMobile ? "col event-list-container" : `col event-list-container mt-4 ${sideBarOpen && "ml-8"}`}>
          <NavBar />
          <div className="mobile-triangle-logo"><img src={Triangle} alt="triangle logo"/></div>
          {
            (savedSearchesOpen) && 
              <SavedSearchList userName={userName}/>
          }
          {
            ((userTrial && initialSearch) && !savedSearchesOpen && !hasNewsletter) &&
            <>
              <EventListTrial 
                userName={userName} 
                userQuiz={userQuiz} 
                userQuizId={userQuizId} 
                userTrial={userTrial} 
                stripeCheckoutLink={stripeCheckoutLink}
              />
            </>
          }
          {
            (userTrial && !initialSearch) && 
              <>
                <div className="font-italic font-weight-bold font-size-l mb-2 color-dark-blue ml-2 mr-2 text-center">
                  <span>To View Unmasked Contact Info, purchase your first 10 tokens for $7</span>
                  {
                    isMobile &&
                      <div>
                        <a href={stripeCheckoutLink} style={{left: "500px"}} className="navbar-item align-items-center font-normal pr-3 pl-3 d-none blue-button">
                          <img src={InfiniteCircle} alt={"infinite circle cost"} style={{width:"45px"}}/> BUY TOKENS
                        </a>
                      </div>
                  }
                </div>
              </>
          }
          {
            ((userTrial && !initialSearch) || ((!userTrial && !savedSearchesOpen) || hasNewsletter)) &&
            <>
            <EventListV2 
              userName={userName} 
              userQuiz={userQuiz} 
              userQuizId={userQuizId} 
              userTrial={userTrial}
              num_newsletter_events={numberOfNewsletterEvents}
              emailFeatureFlag={emailFeatureFlag}
              googleClientId={googleClientId}
              googleSecretId={googleSecretId}
              googleRedirectUri={googleRedirectUri}
            />
            </>
          }
        </div>
      </div>
      <SideBarV2
        eventBudgets={eventBudgets}
        eventCities={eventCities}
        eventIndustries={eventIndustries}
        speakerTypes={speakerTypes}
        speakers={speakerFilters} 
        show={!userTrial}
        userRole={userRole}
        userTrial={userTrial}
      /> 
      <ValidUser validUser={validUser} failedPayment={failedPayment} paidTrial={paidTrial} />
      <ZeroTokensLeft validUser={validUser} paidTrial={paidTrial} userTrial={userTrial}/>
      <FeelingConfused userTrial={userTrial} />  
    </>
  );
}

export default EventsV2;
