import React, { useContext } from "react";
import { AdminContext } from "../../../contexts/AdminContextContainer";
import TimeFilter from "../../TimeFilter";
import AdminUserSelect from "../../AdminUserSelect";
import GraphTypeSelect from "../../GraphTypeSelector";
import GraphPercentage from "../../GraphPercentage";
import UserCount from "../../UserCount";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";


export default function Graph() {
  const { data, startDate, endDate, allAdminUsersList, graphType } = useContext(AdminContext);

  const getIntroOfPage = (label, payload) => {
    for (var i = 0; i < payload.length; i++) {
      if (payload[i].payload.date == label) {
        var correctValues = []
        for (const [key, value] of Object.entries(payload[i].payload)) {
          if (key !== "date" && value !== 0) {
            const key_ = key
            var fill;
            for (var h = 0; h < payload.length; h++) {
              if (key_ == payload[h].dataKey) {
                fill = payload[h].fill
              }
            }
            correctValues.push({'fill' : fill, 'value' : key_ + ": " + value});
          }
        }
        return correctValues;
      }
    }
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${label}`}</p>
          {
            getIntroOfPage(label, payload).map((key) => 
            <p className="intro">
              <svg class="recharts-surface" width="14" height="14" viewBox="0 0 32 32" version="1.1" style={{display: 'inline-block', verticalAlign: 'middle', marginRight: '5px'}}>
                <path stroke="none" fill={key.fill} d="M0,4h32v24h-32z" class="recharts-legend-icon"></path>
              </svg>
              <span style={{paddingBottom: "5px", marginBottom: '5px', color: key.fill}}>{key.value}</span>
            </p>
            )
          }
        </div>
      );
    }
    return null;
  };

  function getRandomColor() {
    return "#" + Math.floor(Math.random()*16777215).toString(16);
  }

  function getIndustryKeys(data_) {
    var keys = [];
    Object.keys(data_[0]).forEach(function(k) {
      if (k != "date") {
        keys.push(k)
      }      
    });
    return keys 
  }
  
  return (
    <div className="container">
      <h1>{graphType} Graph</h1>
      <div className="row" style={{display: 'flex'}}>
        <div className="col">
          {
            graphType != "Industry"
            ?
              <BarChart
              width={700}
              height={500}
              data={data}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 15
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip/>
              <Legend wrapperStyle={{ bottom: 0 }}/>
              <Bar dataKey="Pro Bono 0" stackId="a" fill="#808080" />
              <Bar dataKey="$1-$4,999" stackId="a" fill="#008080" />
              <Bar dataKey="$5,000-$14,999" stackId="a" fill="#96DED1" />
              <Bar dataKey="$15,000+" stackId="a" fill="#50C878" />
            </BarChart>
          : 
          <BarChart
            width={600}
            height={400}
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 15
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            <Legend wrapperStyle={{ bottom: 0 }}/>
            {
              getIndustryKeys(data).map((key) => 
                <Bar dataKey={key} stackId="a" fill={getRandomColor()} />
              )
            }
            
          </BarChart>
          }
          
        </div>
        <div className="col"> 
          <GraphTypeSelect/>
          <TimeFilter startDate={startDate} endDate={endDate}></TimeFilter>
          <AdminUserSelect options={allAdminUsersList}></AdminUserSelect>
          <GraphPercentage/>
        </div>
      </div>
      <div className="row">
        <UserCount/>
      </div>
    </div>
  );
}