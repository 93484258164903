import React, { useState, useEffect } from 'react';
import axios from "axios";

const AdminContext = React.createContext({ });
export { AdminContext };

const AdminContextContainer = (props) => {
  const [activities, setActivities] = useState([]);
  const [data, setData] = useState([]);
  const [userCount, setUserCount] = useState([]);
  const [userFilters, setUserFilters] = useState({});
  const [startDate, setStartDate] = useState(new Date(Date.now() - 604800000)); 
  const [endDate, setEndDate] = useState(new Date());
  const [query, setQuery] = useState();
  const [loading, setLoading] = useState(false)
  const [adminUserList, setAdminUserList] = useState([{value: 1, label: 'Jeff Butler'}, {value: 5, label: 'Jade'}, {value: 13, label: 'Joanna'},{value: 10, label: 'April Nacu'},{value: 7, label: 'Jose Capistrano'}]);
  const [allAdminUsersList, setAllAdminUsersList] = useState([]);
  const [userTotals, setUserTotals] = useState({});
  const [graphType, setGraphType] = useState(["Updated Events"])

  useEffect(() => {
    let userFilterData = localStorage.getItem('userFilters');
   
    if (userFilterData != null) {
      setUserFilters(JSON.parse(userFilterData));
    }
    axios.get(`/adminuseractivities/activities_admin_user_list.json`,{
      params: []
    })
    .then((response) => {
      setAllAdminUsersList(response["data"]);
      setLoading(false)
    })

  }, [])

  useEffect(() => {
    function getFormattedAdminUserList(adminUsers) {
      var ids = []
      adminUsers.forEach(function(element) {
        ids.push(element["value"]);
      });
      return ids
    }

    setLoading(true)
    let params = {...userFilters};
    localStorage.setItem('userFilters', JSON.stringify(userFilters));

    if (query)
      params.query = query;

    if (graphType == "unset") {

    }
    else if (graphType == "Updated Events") {
      axios.get(`/adminuseractivities/activities_list_estimated_budget.json?start_date=${startDate}&end_date=${endDate}&admin_user_ids=${getFormattedAdminUserList(adminUserList)}&admin_actions[]=['event_bitrix_publish']`,{
        params: []
      })
      .then((response) => {
        setData(response["data"]["chart_data"]);
        setUserCount(response["data"]["user_count"]);
        setLoading(false)
      })
    }
    else if (graphType == "Industry") {
      axios.get(`/adminuseractivities/activities_list_industry.json?start_date=${startDate}&end_date=${endDate}&admin_user_ids=${getFormattedAdminUserList(adminUserList)}`,{
        params: []
      })
      .then((response) => {
        setData(response["data"]);
        setLoading(false)
      })
    } 
    else if (graphType == "Published Partial Leads") {
      axios.get(`/adminuseractivities/activities_list_estimated_budget.json?start_date=${startDate}&end_date=${endDate}&admin_user_ids=${getFormattedAdminUserList(adminUserList)}&admin_actions[]=['partial_lead_publish']`,{
        params: []
      })
      .then((response) => {
        setData(response["data"]["chart_data"]);
        setUserCount(response["data"]["user_count"]);
        setLoading(false)
      })
    }
    else if (graphType == "Speakers") {
      axios.get(`/adminuseractivities/activities_list_estimated_budget.json?start_date=${startDate}&end_date=${endDate}&admin_user_ids=${getFormattedAdminUserList(adminUserList)}&admin_actions[]=['speaker_edit']`,{
        params: []
      })
      .then((response) => {
        setData(response["data"]["chart_data"]);
        setUserCount(response["data"]["user_count"]);
        setLoading(false)
      })
    } 
    else if (graphType == "Orders") {
      axios.get(`/orders/orders_js.json?start_date=${startDate}&end_date=${endDate}`,{
        params: []
      })
      .then((response) => {
        setData(response["data"]);
        setUserTotals(response["data"][0]["user_totals"]);
        setLoading(false);
      })
    } 
    else if (graphType == "Interested Events") {
      axios.get(`/interested_events/interested_events_chart_js.json?start_date=${startDate}&end_date=${endDate}`,{
        params: []
      })
      .then((response) => {
        setData(response["data"]);
        setUserTotals(response["data"][0]["user_totals"]);
        setLoading(false)
      })
    } 
  }, [userFilters, startDate, endDate, graphType, query]);

  const getUserFilters = (time, newFilter) => {
    switch (time) {
      case "custom":
        return newFilter

      default:
        return {}
    }
  }

  const handleUserListFilter = (users, newFilter={}) => {
    setUserFilters(getUserFilters(users, newFilter));
  }

  const handleTimeFilter = (time, newFilter={}) => {
    setEndDate(newFilter.end_date)
    setStartDate(newFilter.start_date)
  }

  return (
    <AdminContext.Provider
      value={{
        activities,
        handleTimeFilter,
        setQuery,
        setActivities,
        loading, 
        setStartDate, 
        setEndDate,
        startDate, 
        endDate,
        data, 
        graphType,
        setAdminUserList, 
        handleUserListFilter, 
        userCount,
        adminUserList, 
        allAdminUsersList,
        setGraphType, 
        userTotals
      }}
    >
      { props.children }
    </AdminContext.Provider>
  )
}

export default AdminContextContainer;
