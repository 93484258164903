import React, { useContext, useState, useEffect } from 'react';
import { EventContext } from '../contexts/EventContextContainer';
import tficon from '../../../assets/images/speaker_events_database.svg'
// import InfiniteCircle from '../../../assets/images/blue_infinite_circle.png'
import NavBar from "../Shared/NavBar";
import Tokens from "../EventsV2/TokensNonEvents";
import FeelingConfused from '../Shared/FeelingConfused';
import ImagePicker from '../Shared/ImagePicker';
import ImageUpload from '../Shared/ImageUploadComponent';
import Triangle from "../../../assets/images/logo_triangle.png";
import SquareSVG from "../../../assets/images/square.svg";
import Description from "./Description";
import axios from "axios";
import { Modal } from 'react-bootstrap';
import FeaturedSpeaker from '../Shared/FeaturedSpeaker';

const Profile = ({stripeCheckoutLink, userTrial, userName, userEmail, userTier, numOrderedEvents, subscription_link, industry_names, paidTrial, userId, userDescription, currentCoverURL, avatar_url, subscriptionOptOut}) => {
  const placeholder_description = " This is where you can describe what you do as a speaker. In the future, we will have an area where event planners can search for your information to contact you directly."
  const [width, setWidth] = useState(window.innerWidth);
  const [description, setDescription] = useState(userDescription ? userDescription : placeholder_description);
  const [coverUrl, setCoverUrl] = useState(currentCoverURL);
  const [avatarUrl, setAvatarUrl] = useState(avatar_url);
  const [optOut, setOptOut] = useState(subscriptionOptOut);
  const [editEmail, setEditEmail] = useState(false)
  const [newEmail, setNewEmail] = useState("");
  const [userEmail_, setUserEmail] = useState(userEmail)
  const [userRole, setUserRole] = useState(userTier);
  const [changeTier, setChangeTier] = useState(false);
  const [showOptOutModal, setShowOptOutModal] = useState(false);
  const [question1Radio, setQuestion1Radio] = useState(false)
  const [question1Description, setQuestion1Description] = useState("");
  const [question2Radio, setQuestion2Radio] = useState(false)
  const [question2Description, setQuestion2Description] = useState("");
  const [question3Radio, setQuestion3Radio] = useState(false)
  const [question3Description, setQuestion3Description] = useState("");
  const [question4Description, setQuestion4Description] = useState("");

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  const toggleEditEmail = () => {
    setEditEmail(!editEmail)
  }

  const handleEmailChange = e => {
    setNewEmail(e)
  }

  const toggleChangeTier = () => {
    setChangeTier(!changeTier)
  }

  const submitEmailChange = () => {
    let newTempEmail = newEmail == "" ? userEmail_ : newEmail
    if (newTempEmail == "") {
      alert("Email cannot be blank");
    }
    axios.put(`/change_user_email.json`, {
      params: {"email" : newTempEmail, "id" : userId}
    }).then((response) => {
      setUserEmail(newTempEmail)
      toggleEditEmail();
    })
  }

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });

  let coverUrlStyle = coverUrl ? { backgroundImage : "url(" + coverUrl + ")", backgroundSize : 'cover' } : {} 

  useEffect(() => {
    coverUrlStyle = coverUrl ? { backgroundImage : "url(" + coverUrl + ")", backgroundSize : 'cover' } : {} 
  }, [coverUrl]);

  const handleSubscriptionOptOut = (e) => {
    toggleSubscriptionOptOutSubmit();
    if (e) {
      toggleSubscriptionOptOut(e);
    } 
  }

  const toggleSubscriptionOptOut = (e) => {
      setShowOptOutModal(e)
  }

  const handleOptOutModalClose = () => {
    setShowOptOutModal(false);
  }

  const toggleSubscriptionOptOutSubmit = () => {
    axios.get(`/users/toggle_optout.json`, {
      params: {"optOut" : !optOut}
    }).then((response) => {
      setOptOut(response.data)
    })
  }

  const handleQuestion1Radio = (event) => {
    setQuestion1Radio(event.target.value);
  }

  const handleQuestion2Radio = (event) => {
    setQuestion2Radio(event.target.value);
  }

  const handleQuestion3Radio = (event) => {
    setQuestion3Radio(event.target.value);
  }

  const changeTrialTier = (tier, properTier) => {
    axios.put(`/change_user_tier.json`, {
      params: {"tier" : tier, "id" : userId}
    }).then((response) => {
      setUserRole(properTier)
      toggleChangeTier();
    })
  }

  const submitSurveyQuestions = () => {
    axios.get(`/users/submit_survey_questions.json`, {
      params: {
        "question1Radio" : question1Radio,
        "question1Description" : question1Description, 
        "question2Radio" : question2Radio, 
        "question2Description" : question2Description, 
        "question3Radio" : question3Radio, 
        "question3Description" : question3Description, 
        "question4Description" : question4Description 
      }
    }).then((response) => {
      setShowOptOutModal(!showOptOutModal)
    })
  }
  return (
    <>
      {
        (width > 992) ?
        <>
        <div className="profile-col">
          <div className="row text-center d-flex">
              <div className="col">              
                  <a href="/events">
                    <img src={tficon} alt={"profile icon"} width={"250px"}/>
                  </a>
                  <div className="w-100 text-center name-header color-dark-blue mt-5">
                    {userName}
                  </div>
                  <div className="w-100 text-center tier color-dark-blue mt-3">
                    <span className="font-italic">Your Tier:</span><span className="font-weight-bold"> {userRole} speaker</span>
                  </div>
                  <div className="bottom-buttons text-center">
                    <span className="purchased-events font-weight-bold">{numOrderedEvents} purchased events</span>
                    <div className="mb-4 mt-3">
                        <a class="checkout-button mb-5" href="/interested_events">
                          <img className="mr-1" src={SquareSVG} width={20}/>
                          ORDERED EVENTS  
                        </a>
                    </div>
                    <div className="mb-3 mt-2"><a className="go-to-database font-weight-bold" href="/events">GO TO DATABASE</a></div>
                    <div className="mr-4 ml-4">
                      <FeaturedSpeaker size="column"/>
                    </div>
                  </div>
              </div>
          </div>
        </div>
        <div className="profile-wrapper">
          <div style={coverUrlStyle} className="row background-chooser">
            <ImagePicker setCoverUrl={setCoverUrl} userId={userId} avatarUrl={avatarUrl} />
            <ImageUpload userId={userId} set_avatar_url={setAvatarUrl} avatarUrl={avatarUrl}/>
          </div>
          <div className="row"> 
            <div className="col-8 profile-header-description">
              <div className="user-name mt-2">
                {userName}
              </div>
              <div className="tier mt-2">
                <span className="font-italic">Your Tier:</span><span className="font-weight-bold"> {userRole} speaker</span>
              </div>
              <div className="manage-subscription mt-4">
                {
                  (paidTrial || userTrial) ?
                    <>
                      <div className="toggle-opt-out d-flex justify-content-center">
                        <input style={{marginBottom: "7px"}} type="checkbox" defaultChecked={optOut} onChange={(event) => handleSubscriptionOptOut(event.target.checked)} />
                        <div className="font-montserrat font-size-m color-light-black ml-2 d-inline position-relative" style={{bottom: "4px"}} id='biscuit-label'>Opt Out</div> 
                      </div>
                      {/*<div className="w-100 font-italic font-size-xs">By clicking the opt out checkbox, you will not be added into the monthly subscription service.</div> */}
                      <div className="mt-3"><a className="checkout-button pr-4 pl-4" onClick={() => toggleChangeTier()}>CHANGE TIER</a></div>
                   </>
                  :
                  <a className="checkout-button pr-4 pl-4" href={subscription_link}>MANAGE SUBSCRIPTION</a>
                }
              </div>
              <div className="user-email mt-5 ml-2">
                <i className='fas fa-envelope color-beige font-size-m'/>
                <span className="ml-4 font-weight-bold color-dark-blue font-size-l">email:</span>
                <span onClick={() => toggleEditEmail()} className="ml-4 font-italic color-medium-green text-underline-light-black font-size-l"><i className="fas fa-edit"/>{userEmail_}</span> 
              </div>
              <div className="user-industries mt-3">
                <p className="tags_p">
                  {
                    industry_names && 
                    <span className="font-weight-bold color-dark-blue font-size-l">industries: </span>
                  }
                  {
                    industry_names && industry_names.map((event_industry, index) => (
                      <span key={index} className="tag-1 tag_ background-color-unset border-green-tag">
                        <a className="color-medium-green2 background-color-unset ">
                          {event_industry}
                        </a>
                      </span>
                    ))
                  }
                </p>
              </div>
              <div className="mt-2">
                <a className="beige-button" href="/auth/password/new">Reset Password?</a>
              </div>
            </div>
            <div className="profile-user-description col-4 border-green p-3 font-size-l">
              <Description userId={userId} currentDescription={description} setCurrentDescription={setDescription}/>
            </div> 
          </div>
        </div>
        </> 
        : 
        <>
        <div className="profile-wrapper">
          <div style={{top: "31px"}} className="mobile-triangle-logo"><img src={Triangle} alt="triangle logo"/></div>
          <div style={coverUrlStyle} className="row background-chooser justify-content-center">
            <ImagePicker setCoverUrl={setCoverUrl} userId={userId}/>
            <ImageUpload userId={userId} set_avatar_url={setAvatarUrl} avatarUrl={avatarUrl}/>
          </div>
          <div className="row mt-5"> 
            <div className="profile-header-description w-100 text-center">
              <div className="user-name mt-4">
                {userName}
              </div> 
              <div className="tier mt-2">
                <span className="font-italic">Your Tier:</span><span className="font-weight-bold"> {userRole} speaker</span>
              </div>
              <div className="manage-subscription mt-4">
                {
                  (paidTrial || userTrial) ?
                    <>
                      <div className="toggle-opt-out d-flex justify-content-center">
                        <input style={{marginBottom: "7px"}} type="checkbox" defaultChecked={optOut} onChange={(event) => handleSubscriptionOptOut(event.target.checked)} />
                        <div className="font-montserrat font-size-m color-light-black ml-2 d-inline position-relative" style={{bottom: "4px"}} id='biscuit-label'>Opt Out</div> 
                      </div>
                      {/* <div className="w-100 font-italic font-size-xs">By opting out, your subscription will stop at the end of 7 days. Your current subscription level is Explorer which is $119/month.</div> */}
                      <div className="mt-4"><a className="checkout-button pr-4 pl-4" onClick={() => toggleChangeTier()}>CHANGE TIER</a></div>
                   </>
                  :
                  <a className="checkout-button pr-4 pl-4" href={subscription_link}>MANAGE SUBSCRIPTION</a>
                }
              </div>
              <div className="mb-5 mt-4">
                <a className="beige-button font-weight-bold" href="/interested_events?purchased-events=true">MY PURCHASED EVENTS</a>
              </div>
              <div className="user-email mt-3 ml-2">
                <i className='fas fa-envelope color-beige font-size-s'/>
                <span className="ml-4 font-weight-bold color-dark-blue font-size-s">email:</span>
                <span onClick={() => toggleEditEmail()} className="ml-4 font-italic color-medium-green text-underline-light-black font-size-s"><i className="fas fa-edit"/>{userEmail_}</span> 
              </div>
              <div className="user-industries mt-2">
                <p className="tags_p">
                  {
                    industry_names && 
                    <span className="font-weight-bold color-dark-blue font-size-s">industries: </span>
                  }
                  {
                    industry_names && industry_names.map((event_industry, index) => (
                      <span key={index} className="tag-1 tag_ background-color-unset border-green-tag">
                        <a className="color-medium-green2 background-color-unset ">
                          {event_industry}
                        </a>
                      </span>
                    ))
                  }
                </p>
              </div>
              <div className="mt-2 mb-4">
                <a className="beige-button" href="/auth/password/new">Reset Password?</a>
              </div>
              <div className="mt-2 mb-2">
                <a className="beige-button" href="/auth/logout">Sign Out?</a>
              </div>
            </div>
          </div>
          <div className="profile-user-description border-green p-3 font-size-l mr-5 mt-2">
              <Description userId={userId} currentDescription={description} setCurrentDescription={setDescription}/>
              <p className="font-size-s">
              {
                  !description ?
                  <>
                    This is where you can describe what you do as a speaker. In the future, we will have an area where event planners can search
                    for your information to contact you directly.
                    <br/>
                    <br/>
                    Max 100 words
                  </>
                  : 
                  description
              }
              </p>
          </div>
          <div className="mb-4 pb-3 mr-4 ml-4">
            <FeaturedSpeaker size="column"/>
          </div> 
        </div>  
        </>
      }
      {
            editEmail && 
            <>
                <Modal size="md" show={editEmail} onHide={toggleEditEmail}>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-center font-weight-bold color-medium-green font-italic font-size-xl mb-3">Edit Your Email</div> 
                        <div className="w-100 d-flex justify-content-center">
                            <input type="text" style={{borderRadius: "12px"}} className="pr-1 pl-1 font-montserrat font-size-m" onChange={(event) => handleEmailChange(event.target.value)} defaultValue={userEmail_}/>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="border-top-0 justify-content-center">
                    {
                      <a className="green-button pl-4 pr-4 mb-4" onClick={submitEmailChange}>
                        Save
                      </a> 
                    }
                </Modal.Footer>
                </Modal>
            </>
        }
      <div className="profile-spacer">
        <Tokens stripeCheckoutLink={stripeCheckoutLink} userTrial={userTrial} paidTrial={paidTrial} eventsPage={false}/>
        <NavBar />
      </div>
      <FeelingConfused userTrial={userTrial}/> 
      {
        showOptOutModal && 
          <div>
            <Modal className="p-3" show={showOptOutModal} onHide={handleOptOutModalClose}>
              <Modal.Header closeButton>
              </Modal.Header>
                <Modal.Body>
                  <div className="p-3">
                    <div className="font-size-xl font-italic color-bright-green font-weight-bold text-center w-100">We Are Sad To See You Go<br/>
                      <div className="font-size-m">Here are a Few Questions To Help Us Improve The App</div>
                    </div>
                    <div className="text-left font-montserrat font-weight-bold font-size-s mt-2">1) Was the monthly subscription price too high?</div>
                    <div onChange={handleQuestion1Radio.bind(this)}>
                      <input type="radio" value="Yes" name="question1" className="font-montserrat"/> Yes
                      <input type="radio" value="No" name="question1" className="ml-1 font-montserrat"/> No
                    </div>
                    {
                      question1Radio == "Yes" && <textarea 
                        className="w-75" 
                        placeholder="What price would you continue the service at?" 
                        onChange={(event)=>{
                            setQuestion1Description(event.target.value);
                        }}
                      > 
                      </textarea>
                    }

                    <div className="text-left font-montserrat font-weight-bold font-size-s mt-2">2) Was the app missing a feature that you were looking for?</div>
                    <div onChange={handleQuestion2Radio.bind(this)}>
                      <input type="radio" value="Yes" name="question2" className="font-montserrat"/> Yes
                      <input type="radio" value="No" name="question2" className="ml-1 font-montserrat"/> No
                    </div>
                    {
                      question2Radio == "Yes" && <textarea 
                        className="w-75" 
                        placeholder="What were you looking for specificially?" 
                        onChange={(event)=>{
                            setQuestion2Description(event.target.value);
                        }}
                      > 
                      </textarea>
                    }

                    <div className="text-left font-montserrat font-weight-bold font-size-s mt-2">3) Didn't find the events you were looking for? (If Yes, please describe the events you were looking for and our team will see what we can find). </div>
                    <div onChange={handleQuestion3Radio.bind(this)}>
                      <input type="radio" value="Yes" name="question3" className="font-montserrat"/> Yes
                      <input type="radio" value="No" name="question3" className="ml-1 font-montserrat"/> No
                    </div>
                    {
                      question3Radio == "Yes" && <textarea 
                        className="w-75" 
                        placeholder="What events were you looking for?" 
                        onChange={(event)=>{
                            setQuestion3Description(event.target.value);
                        }}
                      > 
                      </textarea>
                    }
                    {
                      question1Radio == "No" && question2Radio == "No" && question3Radio == "No" &&
                        <textarea 
                          className="mt-3 w-75" 
                          placeholder="What is the reason why you are opting out...?" 
                          onChange={(event)=>{
                              setQuestion4Description(event.target.value);
                          }}
                        > 
                        </textarea>
                    }
                  </div>

                </Modal.Body>
                  
                <Modal.Footer className="border-top-0 justify-content-center">
                    {
                      question1Radio != "" && question2Radio != "" && question3Radio != "" && <a className="green-button pl-4 pr-4 mb-4" onClick={() => submitSurveyQuestions()}>
                        Submit
                      </a> 
                    }
                </Modal.Footer>
            </Modal>
          </div>
      }
      {
        changeTier && 
          <div>
            <Modal className="p-3" show={changeTier} onHide={toggleChangeTier}>
              <Modal.Header closeButton>
              </Modal.Header>
                <Modal.Body>
                  <div className="p-3">
                    <div className="font-size-xl font-italic color-bright-green mt-3 font-weight-bold text-center w-100">Change Tier</div>
                    {
                      [
                        ["user_starter", "Starter", "All Free Engagements", "$59/mo"], 
                        ["user_explorer", "Explorer", "Up to $5,000", "$119/mo"], 
                        ["user_veteran", "Veteran", "Up to $15,000", "$199/mo"], 
                        ["user_pro", "Pro", "Beyond $15,000", "$299/mo"]
                      ].map((role) => 
                          role.indexOf(userRole) == -1 && <div className="mt-4 text-center font-montserrat"> 
                            <a className="green-button-medium pl-4 pr-4 mb-4" onClick={() => changeTrialTier(role[0], role[1])}>{role[1]} Tier</a>
                            <div className="font-size-m color-light-black">{`${role[2]} for ${role[3]} after the trial ends`}</div>
                          </div>
                        )
                    }
                  </div>
                </Modal.Body>
                  
                <Modal.Footer className="border-top-0 justify-content-center">
                   
                </Modal.Footer>
            </Modal>
          </div>
      }
    </>
  )
}

export default Profile;