import React, { useContext, useState } from 'react';
import axios from "axios";
import { EventContext } from '../../contexts/EventContextContainer';
import { Button, Modal } from 'react-bootstrap';

const SaveSearch = () => {
  const [show, setShow] = useState(false);
  const [description, setDescription] = useState("")
  const {eventFilters, timeFilters, query} = useContext(EventContext);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleOnSubmit = () => {
    if (description.length < 1) {
        alert("Please provide a description for your saved search.")
    } else {
      var params = {
        'query' : query, 
        'start_time' : timeFilters["start_date"], 
        'end_time' : timeFilters["end_date"], 
        'budget' : eventFilters["estimated_budget"], 
        'location' : eventFilters["city"], 
        'event_industry_ids' : eventFilters["event_industry_id"], 
        'description' : description
      }
      axios.post(`/saved_search.json`,{
        params: params
      })
      .then((response) => {
        alert("Saved Search: " + description + " was successfully created!");
        setShow(false);
      })
    }
  }

  return (
    <div>
        {
            show && 
            <>
                <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title><i className="fas fa-bookmark"></i> &nbsp; Save this search</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    To save this search, just enter a helpful description for yourself and click "save search".<br/><br/>
                    You can <b>save as many searches as you'd like</b>. 
                    <br/> 
                    <br/>
                    <textarea 
                        className="w-100" 
                        placeholder="Helpful description..." 
                        onChange={(event)=>{
                            setDescription(event.target.value);
                        }}
                    ></textarea>
                </Modal.Body>
                
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                    Close
                    </Button>
                    <Button variant="success" onClick={handleOnSubmit}>
                    Save Search
                    </Button>
                </Modal.Footer>
                </Modal>
            </>
        }
        <a className={`outlineBtn mb5`} onClick={handleShow}>
            <i className="fas fa-bookmark"></i> &nbsp; Save Search
        </a>
    </div>
  )
}

export default SaveSearch;
