import React, { useContext, useState } from 'react';
import axios from "axios";
import { EventContext } from '../../contexts/EventContextContainer';

const SelectAll = () => {
  const [clicked, setClicked] = useState(false);
  const {events, setEvents, setTokensCount} = useContext(EventContext);

  const hasMoreCredits = () =>
    axios.get('/user-interested-events.json');

  const onSuccess = (ids, data, interested) => {
    //$("#js-number-interested-event-text").text("TOKENS: " + data);
    setTokensCount(parseInt(data))

    let newEvents = [...events];

    for (var i = 0; i < newEvents.length; i++) {
      if (!newEvents[i].ordered) {
          newEvents[i].interested = interested;
      }
    }

    setEvents(newEvents);
  }

  const deleteInterestedEvents = (ids) => {
    axios.delete(`/events_interested_events.json?ids=${ids}`)
      .then(({data}) => data != undefined && onSuccess(ids, data, false));
  }

  const createInterestedEvents = (ids) =>{
    axios.post(`/events/interested_events.json?ids=${ids}`)
      .then(({data}) => {
        if ( data != undefined) {
          setTokensCount(parseInt(data));
        }
      })
  }

  const handleOnClick = () => {
    if (clicked) {
      let deleted_ids = []
      events.forEach(event => event.interested && !event.ordered && deleted_ids.push(event.id))

      if (deleted_ids.length > 0 )
        deleteInterestedEvents(deleted_ids);

      setClicked(false)
    }

    else {
      let over = false
      let not_yet_interested_ids = []

      events.forEach((event) => {
        if(!event.interested && !event.ordered)
          not_yet_interested_ids.push(event.id)
      })

      hasMoreCredits().then(function({data}) {
        if ( data != undefined ){
          createInterestedEvents(not_yet_interested_ids)
          onSuccess(not_yet_interested_ids, data, true)
        }
      });

      setClicked(true)
    }
  }

  return (
    <div className="select-all-wrapper">
      <a className={`outlineBtn mb5 ${clicked ? "interested" : ""}`} onClick={handleOnClick}>
        <i className="fas fa-star"></i> &nbsp; Select All
      </a>
    </div>
  )
}

export default SelectAll;
