import React, { useState, setState, useContext } from 'react';
import 'react-select-search/style.css';
import { EventContext } from '../../contexts/EventContextContainer';

const SearchFilter = ({ options }) => {
    const [query, setQueryInputFields] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [menuShowCss, setMenuShowCss] = useState("");
    const { setQuery }  = useContext(EventContext);
    let generalSearch = React.createRef();

    const handleSearch = event => {
      if (event.key === "Enter") {
        setQuery(generalSearch.current.value)
      }
    }

    const handleClickSearch = () => {
      setQuery(generalSearch.current.value)
    }

    return (
      <div>
        <div className="searchForm speaker-search">
        <div className="dropdown w-100">
          <label className="font-weight-bold"><i className="fa fa-search" onClick={handleClickSearch}/>
            <input
              placeholder="General Event Search"
              onKeyDown={handleSearch}
              className="ml-1"
              ref={generalSearch}
              style={{border: 0, outline: 0, background: "transparent", borderBottom: "1px solid rgba(0, 0, 0, 0.15)", width: "240px"}}
            />
            <i style={{position: 'absolute', top: '8px'}} onClick={handleClickSearch} id="test" className="far fa-triangle fa-rotate-90 ml-2"/>
          </label>
        </div>
      </div>
      </div>
    );
};

export default SearchFilter;