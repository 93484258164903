import React, { useContext } from 'react';
import IndustryFilters from "../IndustryFilters";
import BudgetFilters from "../BudgetFiltersV2";
import LocationFilters from "../LocationFilters";
import { EventContext } from '../../contexts/EventContextContainer';

const SideBar = ({eventBudgets, eventCities, eventIndustries}) => {
  const { handleFilterClick, eventFilters } = useContext(EventContext);

  return (
    <div className="card">
      <div className="card-body cb">
        <BudgetFilters eventBudgets={eventBudgets}/>
        <hr />
        <LocationFilters eventCities={eventCities}/>
        <hr />
        <IndustryFilters eventIndustries={eventIndustries} handleFilterClick={handleFilterClick} currentFilters={eventFilters}/>
      </div>
    </div>
  )
}

export default SideBar;
