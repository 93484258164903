import React, { useContext, useState } from "react";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { AdminContext } from "../../../contexts/AdminContextContainer";
import TimeFilter from "../../TimeFilter";
import AdminUserSelect from "../../AdminUserSelect";
import GraphPercentage from "../../GraphPercentage";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";

export default function Graph() {
  const { data, startDate, endDate, allAdminUsersList, graphType, setGraphType, userTotals } = useContext(AdminContext);
  const [graphTypeList, setGraphTypeList] = useState([{value: 'Interested Events', label: 'Interested Events'},{value: 'Orders', label: 'Orders'}]);
  const animatedComponents = makeAnimated();

  const handleGraphTypeSelectChange = (data) => {
    setGraphType(data.value);
  }

  const CustomTooltip = ({ active, payload, label }) => {
    const getIntroOfPage = (label, payload) => {
      for (var i = 0; i < payload.length; i++) {
        if (payload[i].payload.date == label) {
          var correctValues = []
          for (const [key, value] of Object.entries(payload[i].payload)) {
            if (key !== "date" && value !== 0 && key !== "users" && key !== "user_totals" && key !== "Amount") {
              const key_ = key
              var fill;
              for (var h = 0; h < payload.length; h++) {
                if (key_ == payload[h].dataKey) {
                  fill = payload[h].fill
                }
              }
              correctValues.push({'fill' : fill, 'value' : key_ + ": " + value});
            }
            else if (key == "users") {
              const key_ = key;
              var fill;
              for (var g = 0; g < value.length; g++) {
                correctValues.push({'fill' : fill, 'value' : value[g].name + ": " + value[g].amount});
              }
            }
          }
          console.log(JSON.stringify(correctValues));
          return correctValues;
        }
      }
    };
    console.log("Label", label, "Payload", payload)
    console.log(active && payload && getIntroOfPage(label, payload));
    if (active && payload && Object.keys(payload).length > 0) {
      return (
        <div className="custom-tooltip">
          <p className="label">{label}</p>
          {
            getIntroOfPage != undefined && getIntroOfPage(label, payload).map((key) => 
            <p className="intro">
              <svg class="recharts-surface" width="14" height="14" viewBox="0 0 32 32" version="1.1" style={{display: 'inline-block', verticalAlign: 'middle', marginRight: '5px'}}>
                <path stroke="none" fill={key.fill} d="M0,4h32v24h-32z" class="recharts-legend-icon"></path>
              </svg>
              <span style={{paddingBottom: "5px", marginBottom: '5px', color: key.fill}}>{key.value}</span>
            </p>
            )
          }
        </div>
      );
    }
    return null;
  };

  if (graphTypeList.map(function(item) { return item['value'] }).indexOf(graphType) < 0) {
    setGraphType(graphTypeList[0].value)
  }

  return (
    <div class="container">
      <h1>{graphType} Graph</h1>
      <div class="row" style={{display: 'flex'}}>
        <div class="col">
          {
            data && <BarChart
              width={700}
              height={500}
              data={data}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 15
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis dataKey="Amount"/>
              <Tooltip content={<CustomTooltip />}/>
              <Legend wrapperStyle={{ bottom: 0 }}/>
              <Bar dataKey="Amount" stackId="a" fill="#50C878" />
            </BarChart>
          }
          
        </div>
        <div class="col"> 
          <div style={{marginLeft: "40px"}}>
            <span style={{fontSize: "20px"}}>Graph Mode:</span>
            <Select
              closeMenuOnSelect={true}
              components={animatedComponents}
              defaultValue={graphTypeList[0]}
              options={graphTypeList}
              onChange={handleGraphTypeSelectChange}
            />
          </div>
          <TimeFilter startDate={startDate} endDate={endDate}></TimeFilter>
        </div>
      </div>
      <div className="row">
        {
          Object.keys(userTotals).sort(function compareFn(firstEl, secondEl) { return userTotals[secondEl] - userTotals[firstEl]}).map((key, index) =>
            <div style={{marginRight: "10px", fontSize: "18px"}} className="">{key}:{userTotals[key]}</div>
          )
        } 
      </div>
    </div>
  );
}