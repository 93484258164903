import React, { PureComponent, useEffect } from 'react';
import ReactCrop from 'react-image-crop';
import axios from "axios";
import { Modal } from 'react-bootstrap';
import 'react-image-crop/dist/ReactCrop.css';
import ProfileIcon from '../../../../assets/images/profile_icon.svg'

class ImageUploadComponent extends PureComponent {

  state = {
    src: null,
    crop: {
      unit: '%',
      width: 50,
      height: 50,
      x: 25, 
      y: 25,
      aspect: 10 / 10
    },
    croppedImageUrl: null, 
    show: false, 
    isMobile: window.innerWidth < 992
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ show: false })
  }

  toggleShow = () => {
    this.setState({show: !this.state.show});
  }

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
      this.onCropComplete();
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  handleClose = () => {
    this.setState({show: false});
  }

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  handleOnSubmit = async (event) => {
    console.log("Submitting");
    if (this.state.croppedImageUrl == null) {
      alert("Please upload an image.")
    } 
    
    else {
      const formData = new FormData();
      const blob = await fetch(this.state.croppedImageUrl).then(r => r.blob());

      formData.append("file", blob);
      formData.append("user_id", this.props.userId);
      axios.put(`/profiles/update_user.json`, formData)
        .then(response => {
            this.props.set_avatar_url(response.data.avatar_url);
            this.handleClose();
            this.state.croppedImageUrl = null;
        }).catch(error => {
         console.log("*****  "+error)
      });
    } 
  }

  async makeClientCrop(crop) {
    if (this.imageRef && crop && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      this.state.croppedImageUrl = croppedImageUrl;
    }
  }



  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );
    

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error('Canvas is empty');
            return;
          }
          blob.name = fileName;
          window.URL.revokeObjectURL(this.fileUrl);
          this.fileUrl = window.URL.createObjectURL(blob);
          resolve(this.fileUrl);
        },
        'image/jpeg',
        1
      );
    });
  }

  render() {
    const { crop, croppedImageUrl, src, show } = this.state;
    return (
      <>
      <div className="profile-icon" > 
              <div className="profile-halo"/>
              <img src={this.props.avatarUrl ? this.props.avatarUrl : ProfileIcon} alt="profile icon" onClick={this.toggleShow}/>
      {
        show ? 
            <div className="image-upload-component image-upload">
              <Modal show={show} onHide={this.toggleShow}>
                <Modal.Header closeButton>
                  <div className="font-size-xl font-italic color-bright-green mt-3 font-weight-bold text-center w-100">Select Your Profile Picture</div>
                </Modal.Header>
                  <Modal.Body className="image-upload">
                  <div className="image-upload-component">
                    <div>
                      <input type="file" accept="image/*" onChange={this.onSelectFile} />
                    </div>
                    {src && (
                      <ReactCrop
                        src={src}
                        crop={crop}
                        ruleOfThirds
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        onChange={this.onCropChange}
                        circularCrop={true}
                      />
                    )}
                   </div>
                  </Modal.Body>
                    
                  <Modal.Footer className="border-top-0 justify-content-center">
                  {
                    croppedImageUrl && 
                    <a className="green-button pl-4 pr-4" onClick={this.handleOnSubmit}>
                                Save
                    </a> 
                  }
                  </Modal.Footer>
              </Modal>
            </div>
            :
            <div className="edit-picture font-italic" onClick={this.toggleShow} >
              <span>
                <a>
                  <i className="fas fa-edit"/>{!this.state.isMobile && "Click to edit picture"}
                </a>
              </span>
            </div>
      }
      </div>
      </>
    );
  }
}

export default ImageUploadComponent;
