import React, { useState, useEffect } from 'react';
import axios from "axios";
import moment from 'moment';
import { convertFilters } from "../utils/helperFunctions";

const EventContext = React.createContext({ });
export { EventContext };

const EventContextContainer = (props) => {
  const [events, setEvents] = useState([]);
  const [claimedAll, setClaimedAll] = useState(false);
  const [eventCount, setEventCount] = useState(0);
  const [tokensCount, setTokensCount] = useState(-1);
  const [page, setPage] = useState(1);
  const [eventFilters, setEventFilters] = useState({});
  const [timeFilters, setTimeFilters] = useState({});
  const [speakerFilters, setSpeakerFilters] = useState({});
  const [speakerTypeFilters, setSpeakerTypeFilters] = useState({});
  const [savedSearches, setSavedSearches] = useState([]);
  const [savedSearchesOpen, setSavedSearchesOpen] = useState(false);
  const [query, setQuery] = useState();
  const [loading, setLoading] = useState(false)
  const [savedSearchLoaded, setSavedSearchLoaded] = useState(false)
  const [sideBarOpen, setSideBarOpen] = useState(true)
  const [width, setWidth] = useState(window.innerWidth);
  const [includeOrderedEvents, setIncludeOrderedEvents] = useState(true);
  const [newsletterEvents, setNewsletterEvents] = useState(false);
  const [initialSearch, setInitialSearch] = useState(true);
  const [searchCount, setSearchCount] = useState(0);
  const [showEventDetail, setShowEventDetail] = useState(false);
  const [showEventDetailId, setShowEventDetailId] = useState(0);
  const [onboardingFlow, setOnboardingFlow] = useState(0);
  const [showZeroTokensModal, setShowZeroTokensModal] = useState(false);

  useEffect(() => {
    let eventFiltersData = localStorage.getItem('eventFilters');
    let timeFiltersData = localStorage.getItem('timeFilters');
    let speakerFiltersData = localStorage.getItem('speakerFilters');
    let speakerTypeFiltersData = localStorage.getItem('speakerTypeFilters');
    let queryData = localStorage.getItem('query');
    let saved_search = location.href.indexOf('saved_search') > -1

    if (!saved_search) {
      if (eventFiltersData != null) {
        setEventFilters(JSON.parse(eventFiltersData));
      }
      if (timeFiltersData != null) {
        setTimeFilters(JSON.parse(timeFiltersData));
      }
      if (speakerFiltersData != null) {
        setSpeakerFilters(JSON.parse(speakerFiltersData));
      }
      if (queryData != null) {
        setQuery(queryData);
      }
      if (speakerTypeFiltersData != null) {
        setSpeakerTypeFilters(JSON.parse(speakerTypeFiltersData));
      }
    } else {
      let url = new URL(window.location.href);
      let searchParams = new URLSearchParams(url.search);
      var eventFD = {};
      var timeFD = {};

      if (searchParams.get("budgets") != null && searchParams.get("budgets") != []) {
        eventFD["estimated_budget"] = searchParams.get("budgets").split(",").map((i) => String(i));
      }
      if (searchParams.get("event_industry_id") != null && searchParams.get("event_industry_id") != [0]) {
        eventFD["event_industry_id"] = searchParams.get("event_industry_id").split(",").map((i) => Number(i));
      }
      if (searchParams.get("speaker_type_id") != null && searchParams.get("speaker_type_id") != [0]) {
        eventFD["speaker_type_id"] = searchParams.get("speaker_type_id").split(",").map((i) => Number(i));
      }
      if (searchParams.get("cities") != null && searchParams.get("cities") !=  [""]) {
        eventFD["city"] = searchParams.get("cities").split(",")
      }
      if (Object.keys(eventFD).length > 0) {
        setEventFilters(eventFD);
      }

      if (searchParams.get("s_start_date") != null) {
        timeFD["start_date"] = new Date(searchParams.get("s_start_date"));
      }
      if (searchParams.get("s_end_date") != null) {
        timeFD["end_date"] = new Date(searchParams.get("s_end_date"));
      }
      if (Object.keys(timeFD).length > 0) {
        setTimeFilters(timeFD);
      }
    }
  }, [])

  const fetchMoreEvents = () => {
    setPage(page + 1)
  }

  useEffect(() => {
    fetchEvents(true)
  },[page])

  useEffect(() => {
    if (onboardingFlow == 0) {
        axios.get('users/onboarding_flow.json',{})
          .then((response) => {
            setOnboardingFlow(response.data)
          })
    }
  }, [onboardingFlow]);

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });

  useEffect(() => {
    if (width < 992) {
      setSideBarOpen(false)
    } 
  },[width])

  useEffect(() => {
    setLoading(true)
    fetchEvents(false)
    setPage(1)
    setEvents([])
    setSearchCount(searchCount + 1)
    if (searchCount > 1) {
      setInitialSearch(false)
    }
  }, [eventFilters, timeFilters, speakerFilters, speakerTypeFilters, query, includeOrderedEvents, claimedAll]);

  const fetchEvents = (appending) => {
    let params = {...convertFilters(eventFilters), ...timeFilters, ...speakerFilters, ...speakerTypeFilters};
    
    setSavedSearchesOpen(false)
    localStorage.setItem('query', query);
    localStorage.setItem('timeFilters', JSON.stringify(timeFilters));
    localStorage.setItem('eventFilters', JSON.stringify(eventFilters));
    localStorage.setItem('speakerFilters', JSON.stringify(speakerFilters));
    localStorage.setItem('speakerTypeFilters', JSON.stringify(speakerTypeFilters));
    console.log("Page", page, "Event Filters", eventFilters, "TimeFilters", timeFilters, "SpeakerFilters", speakerFilters, "SpeakerTypeFilters", speakerTypeFilters, "Query", query, "Include Events", includeOrderedEvents);
    if (query && query != undefined && query != "undefined") {
      params.query = query;
    }
      
    let newsletter = location.href.indexOf('newsletter_events') > -1
    let url = new URL(window.location.href);
    let searchParams = new URLSearchParams(url.search);
    var saved_search = -1;
    var event_list_url = "";

    if (searchParams.get("saved_search") != null && !savedSearchLoaded) {
      saved_search = searchParams.get("saved_search")
      event_list_url = `/events/events_list?page=${page}&newsletter_events=${newsletter}&saved_search=${saved_search}&include_ordered_events=${includeOrderedEvents}`
      setSavedSearchLoaded(true)
    } else {
      event_list_url = `/events/events_list?page=${page}&newsletter_events=${newsletter}&include_ordered_events=${includeOrderedEvents}`
    }

    axios.get(event_list_url,{
      params: params
    })
    .then((response) => { 
      if (appending) {
        setEvents(events.concat(response.data.events))
      } else {
        setEvents(response.data.events)
      }
      setEventCount(response.data.total_count)
      setLoading(false)
    })
  }

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  const toggleOrderedFilters = () => {
    setIncludeOrderedEvents(!includeOrderedEvents);
  };

  const showEventDetailModal = (e_id) => {
    setShowEventDetailId(e_id)
    setShowEventDetail(true)
  }

  const toggleShowEventDetailsModal = () => {
    setShowEventDetail(!showEventDetail)
  }

  const changeOnboardingFlow = (newFlow) => {
    axios.put('users/change_onboarding_flow_js.json',{
      newValue: newFlow
    })
    .then((response) => {
      if (response.data)
        setOnboardingFlow(response.data)
    })
  }

  const toggleEventNewsletter = () => {
    if (newsletterEvents) {
      if (location.href.split("?").length > 1) {
        location.href = location.href.split("?")[0]
      }
    }
    setNewsletterEvents(!newsletterEvents)
  }

  const handleFilterClick = (value, filter, event) => {
    let filters = {...eventFilters}
    filters[filter] = filters[filter] || []
    if (filter == "event_industry_id" && filters[filter].indexOf(value) == -1) {
      filters[filter].push(value);
    } else if (filter == "speaker_type_id" && filters[filter].indexOf(value) == -1) {
      filters[filter].push(value);
    } else if(event.target.checked)
      filters[filter].push(value);
    else{
      let index = filters[filter].indexOf(value);
      filters[filter].splice(index, 1);
    }
    setPage(1)
    setEventFilters(filters)
  }

  const handleTopEventsFilterClick = (value, filter, active) => {
    let filters = {...eventFilters}
    filters[filter] = filters[filter] || []

    if(active)
      filters[filter].push(value);
    else{
      let index = filters[filter].indexOf(value);
      filters[filter].splice(index, 1);
    }
    setPage(1)
    setEventFilters(filters)
  }

  const getTimeFilters = (time, newFilter) => {
    switch (time) {
      case "today":
        return {
          start_date: new Date(moment().startOf('day')),
          end_date: new Date(moment().endOf('day'))
        }

      case "week":
        return {
          start_date: new Date(moment().startOf('week')),
          end_date: new Date(moment().endOf('week'))
        }

      case "custom":
        return newFilter

      default:
        return {}
    }
  }

  const handleTimeFilter = (time, newFilter={}) => {
    setTimeFilters(getTimeFilters(time, newFilter))
    setPage(1)
  }

  const handleSpeakerRequest = (speaker_name) => {
    setLoading(true)
    axios.get(`/speakers/events.json`, {
      params: {"speaker_name" : speaker_name}
    }).then((response) => {
      setSpeakerFilters({"speaker_name" : speaker_name});
      setEvents(response.data.events);
      setEventCount(response.data.total_count);
      setLoading(false)
    })
  }

  const handleQueryChange = (newQuery) => {
    setQuery(newQuery)
  }

  const handleSavedSearchesRequest = () => {
    setLoading(true)
    axios.get(`/saved_search_list.json`, {
      params: {}
    }).then((response) => {
      setSavedSearches(response.data.saved_searches);
      setSavedSearchesOpen(true);
      setLoading(false)
      if (width < 992) {
        setSideBarOpen(!sideBarOpen)
      }
    })
  }

  const handleClaimChange = () => {
    setClaimedAll(true)
  }

  const clearFilters = () => {
    setEventFilters({});
    setTimeFilters({});
    setSpeakerFilters({});
    setSpeakerTypeFilters({});
    setQuery();
    location.href = "/events";
  }

  const hideZeroTokensModal = () => {
    setShowZeroTokensModal(false)
  }

  const handleShowZeroToTokensModal = () => {
    setShowZeroTokensModal(true)
  }

  const handleInterestedClick = (event_id, interested, ordered, free=false) => {
    if ( ordered ) {
        alert("You previously ordered this event")
        return
    }
    
    let promise;
    let notificationPromise;

    if ( interested )
      promise = axios.delete(`/events/not-interested_events/${event_id}.json`);
    else {
      promise = axios.post(`/events/interested_events/${event_id}.json?free=${free}`);
      if (tokensCount > 0 || free) {
        notificationPromise = axios.post(`/notifications/create_ordered_event_notification_js.json?event_id=${event_id}`);
      }
    }
      
    promise.then(({data}) => {
      if (data != undefined && data > -1 && ((data + "").length > 0)) {
        setTokensCount(parseInt(data));
        let event = events.find((e) => e.id === event_id)
        let newEvents = [...events]
        newEvents[events.indexOf(event)].interested = !interested
        newEvents[events.indexOf(event)].ordered = !ordered
        setEvents(newEvents)
        notificationPromise.then(({d}) => {
          console.log("Notification successfully created", d);
        });
      }
      else {
        setShowZeroTokensModal(true)
      }
    }) 
  }

  if (tokensCount == -1) {
     axios.get(`/interested_events_left.json`, {
       params: {}
     }).then((response) => {
       setTokensCount(parseInt(response.data));
    })
  }

  if (location.href.indexOf("newsletter_events") > -1 && !newsletterEvents)
    setNewsletterEvents(true)

  return (
    <EventContext.Provider
      value={{
        events,
        page,
        eventCount,
        setPage,
        includeOrderedEvents,
        handleFilterClick,
        handleTimeFilter,
        handleTopEventsFilterClick,
        handleQueryChange,
        handleSavedSearchesRequest,
        setQuery,
        setEventFilters,
        eventFilters,
        timeFilters,
        setTimeFilters,
        speakerFilters,
        setSpeakerFilters,
        setSavedSearches,
        setSavedSearchesOpen,
        tokensCount,
        setTokensCount, 
        query,
        loading,
        setEvents,
        handleInterestedClick, 
        sideBarOpen, 
        setSideBarOpen, 
        setIncludeOrderedEvents,
        toggleOrderedFilters,
        handleSpeakerRequest, 
        fetchMoreEvents, 
        savedSearchesOpen, 
        savedSearches, 
        width, 
        newsletterEvents, 
        toggleEventNewsletter, 
        claimedAll,
        handleClaimChange, 
        initialSearch, 
        clearFilters, 
        toggleShowEventDetailsModal, 
        showEventDetail, 
        setShowEventDetailId,
        showEventDetailId, 
        showEventDetailModal, 
        onboardingFlow, 
        changeOnboardingFlow, 
        showZeroTokensModal, 
        hideZeroTokensModal, 
        handleShowZeroToTokensModal
      }}
    >
      { props.children }
    </EventContext.Provider>
  )
}

export default EventContextContainer;
