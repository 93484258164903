import React, { useContext } from 'react';
import { EventContext } from '../../contexts/EventContextContainer';
import axios from "axios";

const SavedSearchCard = ({savedSearch, index}) => {
  const { savedSearches, setSavedSearches, handleSavedSearchesRequest } = useContext(EventContext);

  const loadSavedSearch = (id) => {
    location.href = "/saved_searches/" + id
  }

  const deleteSavedSearch = (id) => {
    var temp = savedSearches;
    axios.delete('/saved_searches/' + id +'.json', {
    }).then((response) => {
      handleSavedSearchesRequest()
    })
  }

  return (
    <>
    <div className="cardV2-wrapper mt-3 mr-3 ml-3">
      <div className="card-number-saved-search-wrapper"><span className="number">{index}</span></div>
      <div className="cardV2">
        <div className="card-body row justify-content-start">
          <div className="col-md-1"></div>
          <div className="col-md-7">
              <h2 className="title_date">
                Saved On {savedSearch.created_at}
              </h2>
              <a href={`/saved_search/${savedSearch.id}`} target="_blank" className="title_">{savedSearch.description}</a>
              <div className="d-flex">
                  <p className="loc_ loc_saved-search">{savedSearch.cities} {savedSearch.query}</p>
                <span className="ml-2 estimated-budget">{savedSearch.budgets}</span>
              </div>
              <p className="desc_">
              </p>

              <div className="row">
                <div className="col-md-8 ml-2" >
                <p className="tags_p">
                {
                  savedSearch.industry_names.map((event_industry, index) => (
                    <span key={index} className="tag_ mb-1">
                      <a className="">
                        {event_industry}
                      </a>
                    </span>
                  ))
                }
                {
                  savedSearch.speaker_name && 
                    <span className="tag_speaker mb-1">
                        <a className="">
                        {savedSearch.speaker_name}
                        </a>
                     </span>
                }
              </p>
                </div>
              </div>
            </div>
            <div className="col-md-3 d-flex justify-content-center align-items-center"> 
                <a
                    onClick={() => loadSavedSearch(savedSearch.id)}
                    id={savedSearch.id}
                    className="green-button-small"
                >
                LOAD SEARCH
                </a>
                <a
                    onClick={() => deleteSavedSearch(savedSearch.id)}
                    id={savedSearch.id}
                    className="ml-1 beige-button-small"
                >
                DELETE
                </a>
            </div>
          </div>
        </div>
    </div>
    </>
  )
}

export default SavedSearchCard;
