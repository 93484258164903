import React, { useState, setState } from 'react';
import 'react-select-search/style.css';

const SpeakerSearch = ({ options }) => {
    const [query, setQuery] = useState("");
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [menuShowCss, setMenuShowCss] = useState("");

    let getData = (newQuery) => {
      let url = '/speaker_search_wildcard.json?potential_speaker=' + newQuery
      let newFilteredData = []
      fetch(url)
        .then(response => response.json())
        .then(data => {
          data.map((speaker,i) => {
            newFilteredData.push({name: speaker.name, id: speaker.id})
          })
          if (newFilteredData.length == 0) {
            // setFilteredData({name: "No Speaker Found", id: location.href.split("/").at(-1)})
          } else {
            setFilteredData(newFilteredData)
          }
          // alert(JSON.stringify(newFilteredData))
        });
    };

      let handleInputChange = event => {
        const newQuery = event.target.value;
        setQuery(query)
        setData(query)
        if (newQuery.length > 1) {
          getData(newQuery)
          setMenuShowCss("show")
        } else {
          setMenuShowCss("")
        } 
      };

      let updateSearchForm = (id) => {
        window.location.href = "/speakers/" + id
      }

    return (
      <div>
        <div className="searchForm">
        <div className="dropdown float-right">
          <label className="font-weight-bold">Speaker Search:
            <input
              placeholder=" 3 character minimum"
              onChange={handleInputChange}
              className="ml-1"
              style={{border: "1px solid rgba(0, 0, 0, 0.15)", borderRadius: "0.25rem"}}
            />
            <div className={"dropdown-menu " + menuShowCss}style={{marginLeft: '128px', marginTop: '-8px'}}>{filteredData.map(i => <span onClick={function(){updateSearchForm(i.id)}}className="dropdown-item">{i.name}</span>)}</div>
          </label>
        </div>
      </div>
      </div>
    );
};

export default SpeakerSearch;