import React, { useState, useContext } from 'react';
import { AdminContext } from '../../contexts/AdminContextContainer';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const GraphTypeSelector = () => {
  
  const { setGraphType } = useContext(AdminContext);
  const [graphTypeList, setGraphTypeList] = useState(
    [
      {value: 'Updated Events', label: 'Updated Events'}, 
      {value: 'Published Partial Leads', label: 'Published Partial Leads'}, 
      {value: 'Industry', label: 'Industry Published Partial Leads'},
      {value: 'Speakers', label: 'Speakers'} 
    ]
  );

  const handleGraphTypeSelectChange = (data) => {
    setGraphType(data.value);
  }

  return (
    <div style={{marginLeft: "40px"}}>
        <span style={{fontSize: "20px"}}>Graph Mode:</span>
        <Select
          closeMenuOnSelect={true}
          components={animatedComponents}
          defaultValue={graphTypeList[0]}
          options={graphTypeList}
          onChange={handleGraphTypeSelectChange}
        />
    </div>
  )
}

export default GraphTypeSelector;
