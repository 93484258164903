import React, { useState, useContext } from 'react';
import axios from "axios";
import InfiniteCircle from '../../../../assets/images/infinite_circle.svg'
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { EventContext } from '../../contexts/EventContextContainer';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9"
  }
}))(Tooltip);

const TokensNonEvents = ({stripeCheckoutLink, userTrial, paidTrial, eventsPage}) => {
  const [tokensCount, setTokensCount] = useState(-1);
  const [daysLeft, setDaysLeft] = useState("");

  const { sideBarOpen, handleShowZeroToTokensModal } = useContext(EventContext);

  const getStripeCheckout = () => {
    axios.get(`/checkout.json`, {
      params: {}
    }).then((response) => {
      location.href = response.data
    })
  }

  axios.get(`/interested_events_left.json`, {
    params: {}
  }).then((response) => {
    setTokensCount(parseInt(response.data));
  })

  axios.get(`/user_days_left.json`, {
    params: {}
  }).then((response) => {
    setDaysLeft(response.data);
  });

  const isMobile = (window.innerWidth < 992);
  let tokensLeft = (isMobile ? {right: "10px"} : ((sideBarOpen) || userTrial) ? {left : "450px"} : {left: "50px"})
  if (!eventsPage && !isMobile)
    tokensLeft = {left : "450px"}

  return (
    <>
      <a href="/interested_events">
        <div className={`align-items-center navbar-item ${paidTrial ? "pt-1" : "pt-3"} token-top-nav d-flex mr-4 font-size-m`} style={tokensLeft} id="js-token-types">
          <img className="mr-1" width="45px" src={InfiniteCircle} alt={"infinite circle cost"}/>
          <span className="d-flex" id="js-number-interested-event-text mr-1"><span className="d-lg-block d-none pr-2">TOKENS: </span>{tokensCount}</span>
          {
            paidTrial && daysLeft && 
            <HtmlTooltip title={
              <React.Fragment>
                {"After your trial period is over, you will be opted into the Explorer subscrption. To modify go to your profile page and you will see the opt-out settings."}
              </React.Fragment>
                }>
              <span className="beige-button-xsmall font-italic days-left position-absolute text-center">{daysLeft}</span>
            </HtmlTooltip>
          }
        </div>
      </a>
      
      {
        (userTrial || (tokensCount == 0 && !paidTrial)) && 
        <a onClick={getStripeCheckout} style={{left: "500px"}} className="navbar-item align-items-center buy-token-top-nav pr-3 pl-3 d-none">
          <img src={InfiniteCircle} alt={"infinite circle cost"} style={{width:"45px"}}/> BUY TOKENS
        </a>
      } 
      {
        (paidTrial && tokensCount == 0) && 
          <a onClick={handleShowZeroToTokensModal} style={{left: "500px"}} className="navbar-item align-items-center buy-token-top-nav pr-3 pl-3 d-none">
            <img src={InfiniteCircle} alt={"infinite circle cost"} style={{width:"45px"}}/> UPGRADE SUBSCRIPTION
          </a>
      }
    </>
  )
}

export default TokensNonEvents;
