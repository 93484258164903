import React, { useState } from 'react';
import axios from "axios";
import { Modal } from 'react-bootstrap';
import { Slider } from '@mui/material';
import Cropper from 'react-easy-crop'
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';


const ImageUploadComponent = ({userId, set_avatar_url}) => {
  const [show, setShow] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null);
  const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 16 / 9});
  const [imageRef, setImageRef] = useState(null);
  const [zoom, setZoom] = useState(50);
  const [aspect, setAspect] = useState(1)
  const [croppedImageUrl, setCroppedImageUrl] = useState("");

  const isMobile = window.innerWidth < 922;
  
  const toggleShow = () => {
    setShow(!show);
  }

  const onCropChange = (crop) => {
    setCrop(crop)
  }

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    console.log(croppedAreaPixels.width / croppedAreaPixels.height)
  }

  const onZoomChange = (zoom) => {
    setZoom(zoom)
  }

  const onImageLoaded = (image) => {
    setImageRef(image);
  };  

  // const onCropComplete = (crop) => {
  //   makeClientCrop(crop);
  // };

  // async
  const makeClientCrop = (crop) => {
    if (imageRef && crop.width && crop.height) {
      const croppedImageUrl = getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      setCroppedImageUrl(croppedImageUrl);
    }
  }

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement('canvas');
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error('Canvas is empty');
            return;
          }
          blob.name = fileName;
          window.URL.revokeObjectURL(this.fileUrl);
          this.fileUrl = window.URL.createObjectURL(blob);
          resolve(this.fileUrl);
        },
        'image/jpeg',
        1
      );
    });
  }

  const handleOnSubmit = (event) => {
    if (selectedImage == null) {
      alert("Please upload an image.")
    } 
    
    else {
      const formData = new FormData();
      formData.append("file", selectedImage);
      formData.append("user_id", userId);
      axios.put(`/profiles/update_user.json`, formData)
        .then(response => {
            set_avatar_url(response.data.avatar_url);
            toggleShow();
        }).catch(error => {
         console.log("*****  "+error)
      });
    }
  }

  const handleClose = () => {
    toggleShow();
  }

  return (
    <>
      {
        show ? 
          <div className="image-upload">
            <Modal className="image-upload" show={show} onHide={handleClose}>
              <Modal.Header closeButton>
              </Modal.Header>
                <Modal.Body>
                  <div>
                    <div className="text-center font-weight-bold color-medium-green font-italic font-size-xl mb-3">Select Your Profile Picture</div>
                    <div className="p-3">
                      {selectedImage && (
                        <div className="App">
                          
                          <img alt="not found" width={"250px"} src={URL.createObjectURL(selectedImage)} />
                          <br/>
                          <div className="mt-4 mb-2">
                            <a className="beige-button-small pl-4 pr-4" onClick={()=>setSelectedImage(null)}>Remove</a>
                          </div>
                        </div>
                      )}
                      <input
                        type="file"
                        name="myImage"
                        label="power"
                        onChange={(event) => {
                          setSelectedImage(event.target.files[0]);
                        }}
                      />
                    </div>
                  </div>
                </Modal.Body>
                  
                <Modal.Footer className="border-top-0 justify-content-center">
                {selectedImage && <a className="green-button pl-4 pr-4" onClick={handleOnSubmit}>
                    Save
                  </a> }
                </Modal.Footer>
            </Modal>
          </div>
          :
          <div className="edit-picture font-italic" onClick={toggleShow} >
            <span>
              <a>
                <i className="fas fa-edit"/>{!isMobile && "Click to edit picture"}
              </a>
            </span>
          </div>
      }
    </>
  )
}

export default ImageUploadComponent;
