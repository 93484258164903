import React, { useContext, useEffect, useState } from 'react';
import EventCardV2 from "../EventCardV2";
import FreeEventsCard from '../FreeEventsCard';
import IntroCard from "../IntroCard";
import Results from "../Results";
import OrderedFilters from '../../Filters/OrderedFilters';
import axios from "axios";
import { EventContext } from '../../contexts/EventContextContainer';
import EmailContextContainer from '../../contexts/EmailContextContainer';
import InfiniteScroll from "react-infinite-scroll-component";
import infiniteStar from '../../../../assets/images/infinite_star.svg'

const EventListV2 = ({userName, userQuiz, userQuizId, userTrial, num_newsletter_events, emailFeatureFlag, googleClientId, googleSecretId, googleRedirectUri}) => {
  const {
    events,
    eventCount,
    page,
    setPage, 
    query,
    loading,
    handleInterestedClick, 
    timeFilters, 
    eventFilters, 
    speakerFilters, 
    fetchMoreEvents, 
    handleClaimChange,
    claimedAll
  } = useContext(EventContext);

  const filtersPresent = () => {
    var fp = false;
    for (var i = 0; i < Object.values(eventFilters).length; i++) {
      if (Object.values(eventFilters)[i].length > 0) {
        fp = true;
      }
    }
    if (timeFilters != null && Object.keys(timeFilters).length > 0) {
      for (var i = 0; i < Object.values(timeFilters).length; i++) {
        if (Object.values(timeFilters)[i] != null) {
          fp = true;
        }
      }
    }
    if (speakerFilters != null && Object.keys(speakerFilters).length > 0) {
      fp = true
    }
    if (query != undefined && query != null && query != "undefined") {
      fp = true
    } 
    return fp
  }

  const newsletterEvents = location.href.indexOf("newsletter_events") != -1
  const isMobile = window.innerWidth < 992;
  const createInterestedEvents = (ids) =>{
    axios.post(`/interested_events/interested_newsletter_events_js.json?ids=${ids}`)
      .then(({data}) => {
        handleClaimChange()
      })
  }

  const reducedClaimedArray = () => {
    var endReducedClaimedArray = true;
    for (var i = 0; i < events.length; i++) {
      endReducedClaimedArray = endReducedClaimedArray && events[i].ordered;
    }
    return endReducedClaimedArray
  }

  return (
    <>
      <div>
        {
          loading &&
          <div className="loader-wrap">
              <div className="w-100 text-center">
                  <svg width="100px" height="100px" viewBox="-4 -1 38 28">
                      <polygon fill="transparent" stroke="#FFFF" strokeWidth="2" points="15,0 30,30 0,30"></polygon>
                  </svg>
              </div>
          </div>
        }
        {
          ((filtersPresent() || newsletterEvents) && events.length > 0) && 
            <div className={isMobile ? "top-events-wrapper" : "top-events-wrapper d-flex"}>
              <Results key={23243451} className="mb-3"/>
              <OrderedFilters/>
            </div>
        }
        {
          newsletterEvents && 
            <div className={newsletterEvents ? "" : "hidden"}>
              <div className="w-100 text-center d-flex justify-content-center font-weight-bold font-size-l font-montserrat color-dark-blue">
                <span><img src={infiniteStar} width="130px"/></span>
              </div>
              <div className="w-100 text-center d-flex justify-content-center font-weight-bold font-size-l font-montserrat color-dark-blue">
                <span>{userName}, this week we have</span>
              </div>
              <div className="w-100 text-center d-flex justify-content-center mb-5 font-weight-bold font-size-l font-montserrat">
                <span className="color-bright-green free-events-underline">{` ${num_newsletter_events} free events `}</span><span className="ml-2 color-dark-blue">for you!</span> 
              </div>
              <div className="w-100 text-center d-flex justify-content-center mb-5 font-weight-bold font-size-l font-montserrat">
                <a className="green-button-medium pt-2 pl-4 pr-4" style={{letterSpacing: "0.35px", fontSize: "14px", width: "150px"}} onClick={() => createInterestedEvents(events.map(x => x.id))}>{ (claimedAll || reducedClaimedArray()) ? "CLAIMED ALL" : "CLAIM ALL"}</a>
                <a className="green-button-medium-bright-inverse pr-4 pl-4 ml-4" style={{letterSpacing: "0.35px", fontSize: "14px", width: "150px"}} href="/events">GO BACK</a> 
              </div>
            </div>
        }
        {
          ((filtersPresent() || newsletterEvents) && events.length > 0) &&
          <InfiniteScroll
            key={232433451}
            dataLength={events.length}
            next={fetchMoreEvents}
            hasMore={(page * 10) < eventCount}
            loader={<h4>Loading...</h4>}
            style={{overflow:"unset"}}
          >
            {
              events.map((event, index) => (
                <React.Fragment key={event.id}>
                  {
                    newsletterEvents ?
                      <EmailContextContainer> 
                        <FreeEventsCard key={event.id} event={event}  handleInterestedClick={handleInterestedClick} index={(index + 1)} claimedAll={claimedAll} />
                      </EmailContextContainer> 
                    :
                    <>
                      <EmailContextContainer>  
                        <EventCardV2 key={event.id} event={event} handleInterestedClick={handleInterestedClick} index={(index + 1)} emailFeatureFlag={emailFeatureFlag} googleSecretId={googleSecretId} googleClientId={googleClientId} googleRedirectUri={googleRedirectUri} />
                      </EmailContextContainer>
                    </>
                  }
                  <br/>
                </React.Fragment>
              ))
            }
        </InfiniteScroll>
        }
        {
          
            !loading && !(filtersPresent()) && <IntroCard userName={userName} userQuiz={userQuiz} userQuizId={userQuizId}/>
        }
        {
            !loading && filtersPresent() && (events.length == 0) && <h3 className={`m-3 text-center font-weight-bold font-montserrat color-dark-blue ${(!loading && filtersPresent() && (events.length == 0)) ? "" : "d-none"}`}>No Events Found. Please try using other filters to see different events.</h3>
        }
      </div>
    </>
  )
}

export default EventListV2;
