import React, { useState, setState, useContext } from 'react';
import 'react-select-search/style.css';
import { EventContext } from '../../contexts/EventContextContainer';

const SpeakerSearchV2 = ({ options }) => {
    const [query, setQuery] = useState("");
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [menuShowCss, setMenuShowCss] = useState("");
    const { handleSpeakerRequest }  = useContext(EventContext);
    let speakerSearch = React.createRef();

    const handleSpeakerSearch = event => {
      handleSpeakerRequest(speakerSearch.current.value)
    }

    let getData = (newQuery) => {
      let url = '/speaker_search_wildcard.json?potential_speaker=' + newQuery
      let newFilteredData = []
      fetch(url)
        .then(response => response.json())
        .then(data => {
          data.map((speaker,i) => {
            newFilteredData.push({name: speaker.name, id: speaker.id})
          })
          if (newFilteredData.length == 0) {
            // setFilteredData({name: "No Speaker Found", id: location.href.split("/").at(-1)})
          } else {
            setFilteredData(newFilteredData)
          }
          // alert(JSON.stringify(newFilteredData))
        });
    };

      let handelInputChangeEnter = event => {
        console.log("Starting", event.key);
        if (event.key === 'Enter') {
          if (Object.keys(filteredData).length > 0) {
            handleSpeakerRequest(filteredData[0].name)
          }
        }
      }

      let handleInputChange = event => {
        const newQuery = event.target.value;
        setQuery(query)
        setData(query)
        if (event.key === 'Enter') {
          if (Object.keys(filteredData).length > 0) {
            handleSpeakerRequest(filteredData[0].name)
          }
        }
        if (newQuery.length > 2) {
          getData(newQuery)
          setMenuShowCss("show")
        } else {
          setMenuShowCss("")
        } 
      };

      let updateSearchForm = (name) => {
        speakerSearch.current.value = name;
        setMenuShowCss("");
        handleSpeakerSearch();
      }

    return (
      <div>
        <div className="searchForm speaker-search">
        <div className="dropdown ml-4">
          <label className="font-weight-bold"><i className="fa fa-search" onClick={handleSpeakerSearch}/>
            <input
              placeholder="3 character minimum"
              onChange={handleInputChange}
              onKeyDown={handelInputChangeEnter}
              className="ml-1"
              ref={speakerSearch}
              style={{border: 0, outline: 0, background: "transparent", borderBottom: "1px solid rgba(0, 0, 0, 0.15)", width: "240px"}}
            />
            <i style={{top: "8px"}} className="far fa-triangle fa-rotate-90 ml-2 position-absolute"/>
            <div style={{zIndex: 1040}} className={"dropdown-menu " + menuShowCss} style={{marginLeft: '24px', marginTop: '-8px'}}>{filteredData.map(i => <span onClick={function(){updateSearchForm(i.name)}}className="dropdown-item background-color-dark-green color-white font-size-xs font-montserrat font-italic">{i.name}</span>)}</div>
          </label>
        </div>
      </div>
      </div>
    );
};

export default SpeakerSearchV2;