import React, { useContext, useEffect, useState } from 'react';
import EventCardV2 from "../EventCardV2";
import TrialEventPrompt from '../TrialEventPrompt';
import { EventContext } from '../../contexts/EventContextContainer';
import FeaturedSpeaker from '../../Shared/FeaturedSpeaker';

const EventListTrial = ({userName, userQuiz, userTrial, stripeCheckoutLink}) => {
  const {
    events,
    loading,
    handleInterestedClick, 
    timeFilters, 
    eventFilters, 
    speakerFilters
  } = useContext(EventContext);

  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(true)
  }

  const filtersPresent = () => {
    var fp = false;
    for (var i = 0; i < Object.values(eventFilters).length; i++) {
      if (Object.values(eventFilters)[i].length > 0) {
        fp = true;
      }
    }
    if ((timeFilters != null && Object.keys(timeFilters).length > 0) || (speakerFilters != null && Object.keys(speakerFilters) > 0)) {
      fp = true
    } 
    return fp
  }
  
  return (
    <>
      <div>
        {
          loading &&
            <div className="loader-wrap">
              <div className="w-100 text-center">
                  <svg width="100px" height="100px" viewBox="-4 -1 38 28">
                      <polygon fill="transparent" stroke="#FFFF" stroke-width="2" points="15,0 30,30 0,30"></polygon>
                  </svg>
              </div>
            </div>
        }
        {
          (events.length > 0) &&
          <TrialEventPrompt trial={userTrial} userName={userName} savedSearch={userQuiz} top={true} stripeCheckoutLink={stripeCheckoutLink}/> 
        }
        {
          (events.length > 0) &&
            events.slice(0,3).map((event, index) => (
              <React.Fragment key={event.id}>
                <EventCardV2 event={event} handleInterestedClick={handleInterestedClick} index={index + 1}/>
                <br/>
              </React.Fragment>
            ))
        }
        {
          !loading && !showMore && events.length > 3 && <div class="row justify-content-center font-montserrat show-more-trial font-weight-bold mb-5" onClick={toggleShowMore}><i class="fas fa-triangle fa-rotate-180 mr-2 mb-1"></i>SHOW MORE</div>
        }
                {
          !loading && 
            <div className="mr-4 ml-4 mb-4"> 
              <FeaturedSpeaker size="row"/> 
            </div>
        }
        {
          showMore && (events.length > 0) &&
          events.slice(4,11).map((event, index) => (
            <React.Fragment key={event.id}>
              <EventCardV2 event={event} handleInterestedClick={handleInterestedClick} index={index + 4}/>
              <br/>
            </React.Fragment>
          ))
        }
        {
          (events.length > 0) &&
          <TrialEventPrompt trial={userTrial} userName={userName} savedSearch={userQuiz} top={false} stripeCheckoutLink={stripeCheckoutLink}/> 
        }
        {
          !loading && (events.length == 0) && <h3 className="m-3 text-center font-weight-bold font-montserrat color-dark-blue ">No Events Found. Please try using other filters to see different events.</h3>
        }
      </div>
    </>
  )
}

export default EventListTrial;
