import React, { useContext, useEffect } from 'react';
import EventCard from "../EventCard";
import Pagination from "react-js-pagination";
import { EventContext } from '../../contexts/EventContextContainer';
import axios from "axios";

const EventList = ({query}) => {
  const {
    events,
    eventCount,
    page,
    setPage,
    loading,
    setQuery,
    handleInterestedClick
  } = useContext(EventContext);

  useEffect(() => {
    console.log(query)
    if (query)
      setQuery(query)
  }, [query])

  return (
    <>
      <div>
        {
          loading &&
            <div className="loader-wrap">
              <div className="spinner-border text-light" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
        }
        {
          events.length > 0 ?
            events.map((event, index) => (
              <React.Fragment key={event.id}>
                <EventCard event={event} handleInterestedClick={handleInterestedClick}/>
                <br/>
              </React.Fragment>
            ))
          :
            !loading && <h3>No Events Found. Your Version May Also Be Limiting What Events You Can View.</h3>
        }
      </div>

      {
        events.length > 0 &&
        <div className="d-flex align-items-center justify-content-center">
          <Pagination
            activePage={page}
            itemsCountPerPage={10}
            totalItemsCount={eventCount}
            pageRangeDisplayed={5}
            onChange={setPage}
          />
        </div>
      }
    </>
  )
}

export default EventList;
