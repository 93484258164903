import React, { useContext, useState } from 'react';
import { EventContext } from '../../contexts/EventContextContainer';
import mobileFilters from "../../../../assets/images/mobile_filters";
import cancelFilters from "../../../../assets/images/cancel_filter.svg";
import backButton from "../../../../assets/images/back_button.png";

const MobileNavBar = ({}) => {

    const { setSideBarOpen, sideBarOpen } = useContext(EventContext);
    const [mobileClose, setMobileClose] = useState(false);

    const toggleMobileFilters = () => {
        setSideBarOpen(!sideBarOpen);
    }

    const goBack = () => {
        window.history.back()
    }

    const isMobile = window.innerWidth < 992;

    const closeMobile = () => {
        setMobileClose(true)
    }

    return (
        <>
        {
            (setSideBarOpen != undefined) &&
                <> 
                    {
                        (isMobile && !mobileClose) &&
                        <div class="w-100 d-flex font-weight-bold justify-content-center p-1 text-center" style={{backgroundColor: "#5cb385", color: "#FFFFFF"}}>
                            <i className="fa fa-close" onClick={closeMobile} style={{width: "20px", color: "#FFF"}}/>
                            <span>This app is new and we love feedback. Please email us at support@trinityfix.com if you experience issues.</span>  
                        </div>
                    }
                    <div className="w-100 d-md-block d-lg-none navbar fixed-top navbar-menu">
                        <ul class="navbar-nav pull-left mobile-nav-bar-filter" onClick={toggleMobileFilters}>
                            {
                                !sideBarOpen ? 
                                <div><img style={{height: "50px"}} className="open-mobile-filters" src={mobileFilters} alt="filters" /></div>
                                : 
                                <div><img style={{height: "50px"}} className="close-mobile-filters" src={cancelFilters} alt="filters" /></div>
                            }
                        </ul>
                    </div>
                </>
        }
        {/* Profile view */}
        {
            (setSideBarOpen == undefined) && 
                <>
                    <div className="w-100 d-md-block d-lg-none navbar fixed-top navbar-menu">
                        <ul class="navbar-nav pull-left mobile-nav-bar-filter" onClick={goBack}>
                            <div><img style={{height: "50px"}} className="close-mobile-filters" src={backButton} alt="filters" /></div>
                        </ul>
                    </div>
                </>
        }
        </>
    )
}

export default MobileNavBar;