import React, { useContext } from 'react';
import { EventContext } from '../../contexts/EventContextContainer';


const Results = ({}) => {
  const { page, eventCount } = useContext(EventContext);
  let page_amount = page * 10;
  if (parseInt(eventCount) < 10) {
    page_amount = eventCount
  }
  if (eventCount < page_amount) {
    page_amount = eventCount
  }
  return (
    <>
    
    <div class="event-results-count text-left mb-3 ml-3">
      1 - {page_amount} out of {eventCount} Events
    </div>
    </>
  )
}

export default Results;
