import React, { useContext, useState, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import axios from "axios";
import { EventContext } from '../../contexts/EventContextContainer';
import Bell from '../../../../assets/images/bell.svg';
import BrightYellowStar from '../../../../assets/images/bright_yellow_star.svg';

const NotificationBell = ({}) => {
  const [ active, setActive ] = useState(false)
  const [ notificationCheck, setNotificiationCheck ] = useState(false)
  const [ notifications, setNotifications ] = useState([]);
  const [ unreadNotificationNum, setUnreadNotificationNum ] = useState(0);

  const toggleActive = () => {
    setActive(!active)
  }

  const handleNotificationClick = (i) => {
    axios.get(`/notifications/read_notification_js.json`, {
      params: { id: i }
    }).then((response) => {
      console.log(response.data);
    })
  }

  useEffect(() => {
    if (!notificationCheck) {
      axios.get(`/notifications/user_notifications_js.json`, {
        params: {}
      }).then((response) => {
        setNotificiationCheck(true)
        if (response.data.num != 0 || response.data.notifications.length > 0) {
          setUnreadNotificationNum(response.data.num)
          setNotifications(response.data.notifications)
        }
      })
    }
  }, [notifications])

  return (
    <>
      <Dropdown className="position-relative">
        <Dropdown.Toggle 
          className="p-0" 
          style={{fontWeight: 600, backgroundColor: "#f9fcf5", color: "#194f57", border: "none"}} 
          variant="secondary" 
          id="notification-bell">
          <img src={Bell} width="40px" onClick={toggleActive}/>
            {
              unreadNotificationNum > 0 && 
              <div style={{top:"0", right:"0", width:"20px", height:"20px", borderRadius:"15px", background: "#d9b202"}} className="position-absolute">
                <div style={{color: "#f9fcf5"}} className="font-montserrat font-size-xs font-weight-bold">
                  {unreadNotificationNum}
                </div>
              </div>
            }
        </Dropdown.Toggle>
        <Dropdown.Menu variant="dark" style={{backgroundColor: '#124e56', borderRadius: "25px"}} className="font-weight-bold notification-menu">
          <h1 style={{fontSize:"33px", paddingLeft: "25px", paddingRight: "25px", paddingTop: "10px", paddingBottom: "10px"}} className="font-montserrat color-white font-weight-bold">Notifications</h1>
          {
            (notifications.length > 0) &&
              <Dropdown.Divider style={{borderTop: "1px solid #61a88f", marginBottom: 0, marginTop: 0}}/>
          }
          {
            notifications.map((key, index) => 
              <>
                <Dropdown.Item onClick={() => handleNotificationClick(key.id)} href={key.url} data-method="get" key={key.id} style={{color: key.url.indexOf("newsletter") > -1 ? "#d8b009" : "#d3cc9f"}} className={`pt-2 pb-2 font-size-xs font-weight-bold font-montserrat`}>
                  <span><img src={BrightYellowStar} className="mr-1" width="25px"/>{key.description}</span>
                </Dropdown.Item>
                <Dropdown.Divider style={{borderTop: "1px solid #61a88f", marginBottom: 0, marginTop: 0}}/>
              </>
            )
          }
          <div style={{paddingLeft: "25px", paddingRight: "25px", paddingBottom: "35px"}}/>
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
}

export default NotificationBell;