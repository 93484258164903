import React from 'react';
import IndustryFilters from "../Filters/IndustryFilters";
import OrganizationContextContainer from "../contexts/OrganizationContextContainer"
import OrganizationList from "./OrganizationList"
import Sidebar from "./Sidebar"

const Organizations = ({
  level_orgs,
  eventIndustries,
  chapterChapterTypes
}) => {
  return (
    <OrganizationContextContainer>
      <section className="page-wrapper-header organization-index-background">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <h1>Organizations</h1>
            </div>
            <div className="col-md-4">

              <span className="header_buttons">
                <button className="whiteBtn">
                  Follow
                </button>
                <button className="whiteBtn">
                  <i className="fas fa-share"></i> &nbsp;Share
                </button>
              </span>
            </div>
          </div>
        </div>
      </section>

      <div className="container organization-index-page">
        <div className="col-12 filter-tag-row-wrapper">
          <span className="filter_tag_row">
          </span>
        </div>
        <br />

        <div className="row">
          <div className="col-md-3">
            <Sidebar
              level_orgs={level_orgs}
              chapterChapterTypes={chapterChapterTypes}
              eventIndustries={eventIndustries}
            />
          </div>

          <div className="col-md-9">
            <OrganizationList chapterChapterTypes={chapterChapterTypes}/>
          </div>
        </div>
      </div>
    </OrganizationContextContainer>
  );
}

export default Organizations;
