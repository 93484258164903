import React, { useContext, useState } from "react";
import tficon from '../../../../assets/images/speaker_events_database.svg'
import sideFilterButton from "../../../../assets/images/side_filter.png";
import sideBarOpenSVG from "../../../../assets/images/side_bar_open.svg"
import trinityFixLogoSVG from "../../../../assets/images/trinityfix_logo.svg";
import square from "../../../../assets/images/square.svg";
import BudgetFiltersV2 from "../BudgetFiltersV2";
import IndustryFiltersV2 from "../IndustryFiltersV2";
import SpeakerTypeFilter from "../SpeakerTypeFilters";
import TimeFiltersV2 from "../TimeFiltersV2";
import SpeakerSearch from "../../Shared/SpeakerSearchV2"
import LocationFiltersV2 from "../LocationFiltersV2";
import SaveSearch from "../../EventsV2/SaveSearch";
import { EventContext } from '../../contexts/EventContextContainer';
import FilterTagsV2 from "../FilterTagsV2";
import SearchFilter from "../SearchFilter";
import { Button, Modal } from 'react-bootstrap';
import OrderedFilters from "../OrderedFilters";

function SideBarV2({eventBudgets, eventCities, eventIndustries, speakerTypes, speakers, show, userRole, userTrial}) {
  const [industryFilterActive, setIndustryFilterActive] = useState(false);
  const [speakerTypeActive, setSpeakerTypeActive] = useState(false);
  const [initialShow, setInitialShow] = useState(show);
  const { handleFilterClick, eventFilters, sideBarOpen, setSideBarOpen, handleSavedSearchesRequest, width, onboardingFlow, changeOnboardingFlow } = useContext(EventContext);

  const toggleActive = (ids) => {
    if (onboardingFlow == 1 && userTrial) {
        changeOnboardingFlow(3)
    }
    if (!initialShow) {
        setInitialShow(true);
        setSideBarOpen(true);
    } else {
        setSideBarOpen(!sideBarOpen);
    }
  }

  let industryDetails = React.createRef();
  let speakerTypeDetails = React.createRef();

  const toggleIndustryFilter = (event) => {
    if (industryFilterActive || industryDetails.current.hasAttribute("open")) {
      industryDetails.current.removeAttribute("open")
    }
    setIndustryFilterActive(!industryFilterActive)
  }

  const toggleSpeakerTypeFilter = (event) => {
    if (speakerTypeActive || speakerTypeDetails.current.hasAttribute("open")) {
        speakerTypeDetails.current.removeAttribute("open")
    }
    setSpeakerTypeActive(!speakerTypeActive)
  }

  const toggleIndustryFilterVar = () => {
    setIndustryFilterActive(!industryFilterActive)
  }

  const toggleSpeakerTypeActive = () => {
    setSpeakerTypeActive(!speakerTypeActive);
  }

  const isMobile = window.innerWidth < 992;

  return (
      <>
      <div className="d-lg-block d-none">
      {
          (sideBarOpen && initialShow) ? 
          <div className="event-filter-footer-bar-active" > 
            <div className="event-filter-footer-bar-active-scroll">
            <div className="container pb-5"> 
                <div className="row text-center d-flex">
                    <div className="col">
                        <a href="/events">
                            <img src={trinityFixLogoSVG} alt={"profile icon"} width={"250px"}/>
                        </a>
                    </div>
                </div>
                <FilterTagsV2
                  eventBudgets={eventBudgets}
                  eventCities={eventCities}
                  eventIndustries={eventIndustries}
                  speakerTypes={speakerTypes} 
                  speakers={speakers}
                  className="mt-3"
                />
                <div className="row footer-bar-filters">
                    <div className="col">  
                        <details className="text-left">
                            <summary>What Is Your Speaking Fee?</summary>
                            <BudgetFiltersV2 eventBudgets={eventBudgets} userRole={userRole} class="ml-4"/>
                        </details>
                        <details className="text-left" ref={industryDetails} onClick={toggleIndustryFilterVar}>
                            <summary>In Which Industry?</summary>
                        </details>
                        <details className="text-left">
                            <summary>Where?</summary>
                            <LocationFiltersV2 eventCities={eventCities}/>
                        </details>
                        <details className="text-left">
                            <summary>When?</summary>
                            <TimeFiltersV2/>
                        </details>
                        <details className="text-left" ref={speakerTypeDetails} onClick={toggleSpeakerTypeActive}>
                            <summary>See Where Events Are Hiring Speakers Like You?</summary>
                        </details> 
                        <details className="text-left">
                            <summary>Search By Speaker</summary>
                            <SpeakerSearch/>
                        </details>
                    </div>
                </div>
                <div className="mt-5 mb-5"></div>
                <SearchFilter/>
                <div className="row footbar-bar-stars">
                      <div className="mr-1 mb-2 w-100 text-left"><SaveSearch><i className="fas fa-save"/></SaveSearch></div>
                      <div className="mr-1 mb-2 w-100 text-left color-medium-green color-medium-green-hover" onClick={handleSavedSearchesRequest}><i className="fas fa-save mr-1"></i>See Saved Searches</div>
                </div>
                <div className="close-footer-bar" onClick={toggleActive}>
                    <span className="show-filters">hide filters</span>
                    <img src={sideBarOpenSVG} alt="Side filter"></img>
                    <i className="far fa-triangle fa-rotate-270"></i>
                </div>
            </div>
            <IndustryFiltersV2 
                active={industryFilterActive} 
                setActive={setIndustryFilterActive} 
                eventIndustries={eventIndustries}
                handleFilterClick={handleFilterClick}
                currentFilters={eventFilters}
                toggleIndustryFilter={toggleIndustryFilter}
                />
            <SpeakerTypeFilter
                active={speakerTypeActive}
                setActive={setSpeakerTypeActive}
                speakerTypes={speakerTypes}
                handleFilterClick={handleFilterClick}
                currentFilters={eventFilters}
                toggleSpeakerFilter={toggleSpeakerTypeFilter}
            />
            </div>
          </div>
          :
          <div className={`event-filter-footer-bar ${(userTrial && onboardingFlow == 1) ? "onboarding-glow" : ""} d-lg-block d-none`} onClick={toggleActive}>
            <div className="show-filters pr-3">show filters</div>
            <img src={sideFilterButton} alt="Side filter"/>
            <i className="far fa-triangle fa-rotate-90"></i>
          </div>
      }
      </div>
      <div className="d-lg-none .d-xl-block">
      {
          (sideBarOpen) && 
            <>
                <Modal dialogClassName="mobile-filter-modal" show={sideBarOpen && (width < 992)} onHide={toggleActive} backdrop="static">
                <Modal.Header className="text-center d-block border-bottom-0" closeButton/>
                <Modal.Body className="d-flex mr-3 ml-3" style={{flexWrap: 'wrap'}}>
                    <div className="event-filter-footer-bar-active" >
                        <div className="event-filter-footer-bar-active-scroll">
                            <div className="container pb-5 pt-0 pl-0"> 
                                <FilterTagsV2
                                eventBudgets={eventBudgets}
                                eventCities={eventCities}
                                eventIndustries={eventIndustries} 
                                speakers={speakers}
                                speakerTypes={speakerTypes} 
                                className="mt-3"
                                />
                        <div className="row footer-bar-filters">
                            <div className={`${isMobile ? "" : "col"}`}>
                                <details className="text-left">
                                    <summary>What Is Your Speaking Fee?</summary>
                                    <BudgetFiltersV2 eventBudgets={eventBudgets} class="ml-4"/>
                                </details>
                                <details className="text-left" ref={industryDetails} onClick={toggleIndustryFilterVar}>
                                    <summary>In Which Industry?</summary>
                                </details>
                                <details className="text-left">
                                    <summary>Where?</summary>
                                    <LocationFiltersV2 eventCities={eventCities}/>
                                </details>
                                <details className="text-left">
                                    <summary>When?</summary>
                                    <TimeFiltersV2/>
                                </details>
                                <details className="text-left" ref={speakerTypeDetails} onClick={toggleSpeakerTypeActive}>
                                    <summary>See Where Events Are Hiring Speakers Like You?</summary>
                                </details> 
                                <details className="text-left">
                                    <summary>Search By Speaker</summary>
                                    <SpeakerSearch/>
                                </details>
                            </div>
                        </div>
                        <div className="w-100 d-flex justify-content-center"><a className="checkout-button pl-4 pr-4 mt-4" onClick={toggleActive}>Filter</a></div>
                        {/* <!-- <div className="d-flex justify-content-center"><a className="outlineBtn interested-button saved-search-interest-button">Clear All</a></div> */}
                        <div className="mt-5 mb-5"></div>
                        <div className="row footbar-bar-stars">
                            <div className="mr-1 mb-2 save-search-wrapper">
                                <SaveSearch mobile={true}><i className="fas fa-save"/></SaveSearch>
                            </div>
                            <div className="position-absolute see-saved-searches-wrapper">
                                <div className="mr-5 mt-2 mb-2 see-saved-searches text-center" onClick={handleSavedSearchesRequest}><i className="far fa-save mr-1"></i><br/>Saved</div> 
                            </div>
                        </div>
                    </div>
                        <IndustryFiltersV2 
                            active={industryFilterActive} 
                            setActive={setIndustryFilterActive} 
                            eventIndustries={eventIndustries}
                            handleFilterClick={handleFilterClick}
                            currentFilters={eventFilters}
                            toggleIndustryFilter={toggleIndustryFilter}
                            />
                         <SpeakerTypeFilter
                            active={speakerTypeActive}
                            setActive={setSpeakerTypeActive}
                            speakerTypes={speakerTypes}
                            handleFilterClick={handleFilterClick}
                            currentFilters={eventFilters}
                            toggleSpeakerFilter={toggleSpeakerTypeFilter}
                            />
                        </div>
                    </div>                 
                </Modal.Body>
                </Modal>
          </>
      }
      </div>
      </>
  );
}

export default SideBarV2;
