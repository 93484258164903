import React, { useContext, useEffect } from 'react';
import { EventContext } from '../../contexts/EventContextContainer';
import LocationSearch from "../../Shared/LocationSearch"

const LocationFiltersV2 = ({eventCities}) => {
  const { handleFilterClick, setQuery, eventFilters } = useContext(EventContext);

  let inputRef = React.createRef();

  const isChecked = value =>
    eventFilters.city && eventFilters.city.includes(value)

  const isMobile = window.innerWidth < 992;

  return (
    <div className="ml-4">
      <div className="relative">
        <LocationSearch/>
      </div>
      <div id="cities_filter">
        {
          !isMobile && eventCities.map((eventCity, index) => (
            <label className="lbl_" key={index}>
              <span>
                <input
                  value={eventCity[0][0]}
                  type="checkbox"
                  checked={isChecked(eventCity[0][0])}
                  onClick={(event) => handleFilterClick(eventCity[0][0], 'city', event)}
                />
                <span className="spp">
                  {eventCity[0][0]}, {eventCity[0][1]}
                </span>
              </span>
              {eventCity[1]}
            </label>
          ))
        }
      </div>
    </div>
  )
}

export default LocationFiltersV2;
