import React, { useEffect, useState } from 'react';
import Graph from "./Graph";
import AdminContextContainer from '../../contexts/AdminContextContainer';

const EventUpdateGraph = ({
}) => {
  return (
    <div class="event-update-graph">
      <AdminContextContainer>
        <Graph/>  
      </AdminContextContainer>
    </div>
  );
}

export default EventUpdateGraph;