import React, { useContext } from 'react';
import Pagination from "react-js-pagination";
import {OrganizationContext} from "../../contexts/OrganizationContextContainer"

const OrganizationList = ({chapterChapterTypes}) => {
  const {organizations, page} = useContext(OrganizationContext);

  const renderChapter = (organization) => {
    let chapter = chapterChapterTypes[organization.chapter[0].c_type][0]
    return chapter[0].toUpperCase() + chapter.slice(1);
  }

  return (
    <div id="results">
      {
        organizations.length > 0 &&
          organizations.map((organization) => (
            <div key={organization.id}>
              <div className="card">
                <div className="card-body">
                  { organization.chapter.length > 0 &&
                    <h2 className="title_date">
                      {renderChapter(organization)}
                    </h2>
                  }
                  <h2 className="title_date">
                    <a href={`/organizations/${organization.id}`}>
                      {organization.name}
                    </a>
                  </h2>
                  <p className="desc_">
                     Hosting Events: { organization.events_count  }
                  </p>
                  { !organization.event_count > 0 &&
                    <p className="no-margin tags_p">
                      <span className="tag_">
                        <a href={`/organizations/${organization.id}`}>
                          {organization.event_industry}
                        </a>
                      </span>
                    </p>
                  }
                </div>
                <div className="card-footer cf_ ">
                <span>
                <span className="nowrap"><i className="fas fa-bolt _bolt"></i> Average Budget: { organization.average_budget } &nbsp; &nbsp;</span>
                <button className="greenBtn">
                  <i className="fas fa-star"></i>
                  { `Chapters: ${organization.chapter_count}` }
                </button>
              </span>
              </div>
              </div>
              <br />
            </div>
          ))
      }
    </div>
  )
}

export default OrganizationList;
